import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Progress } from "reactstrap";
import { Rating } from "react-simple-star-rating";
import ReviewItem from "./components/ReviewItem";
import ReviewForm from "./components/ReviewForm";
// import { useState } from "react";
import { Info } from "react-feather";
const Reviews = (props) => {
  const product_id = props.productInfo.id;
  const commentData = props.productInfo.comments;
  const [reviewDetails, setReviewDetails] = useState({ id: 0, name: null, product_id });
  // console.log('jjhj',props);
  const [commentList, setCommentList] = useState([]);
  const handleReviewReply = (comment) => {
    // console.log("hi", id, name);
    setReviewDetails({
      id: comment._id,
      name: comment.user[0].name,
      product_id: product_id,
    });
  };

  useEffect(() => {
  }, [commentData]);
  let listingData;
  const getCommentList = () => {
    listingData = [];
    commentData
      .filter((c) => c.comment_parent_id)
      .forEach((c) => {
        const parent = commentData.find((p) => p._id === c.comment_parent_id);
        parent.replys = parent.replys || [];
        if (parent.replys.filter((e) => e._id === c._id).length == 0) {
          parent.replys.push(c);
        }
      });

    listingData = commentData.filter((c) => !c.comment_parent_id);
  };

  const getRating = () => {
    // console.log('commentData',commentData);
    let overallRating = [];
    var ratingList = [];
    let outof5 = 0;
    if (commentData.length) {
      ratingList = commentData.filter((c) => !c.comment_parent_id);
      overallRating = ratingList.reduce(function (memo, x) {
        if (!memo[x["rating"]]) {
          memo[x["rating"]] = [];
        }
        memo[x["rating"]].push(x);
        return memo;
      }, {});

      let givenStar = 0;
      let totalStar = ratingList.length * 5;
      for (let index = 1; index < 6; index++) {
        if (overallRating[index] != undefined) {
          givenStar = givenStar + overallRating[index].length * index;
        }
      }
      outof5 = Math.round((givenStar / totalStar) * 5);
    }

    return (
      <ReviewBox className="d-flex align-items-start flex-wrap">
        {" "}
        <div className="text-center flex-shrink-0">
          <div className="rating-wrapper p-4 rounded">
            <h1 className="title text-center text-white mb-0">{outof5}</h1>
            <small className="desc text-center text-white">Out of 5</small>
          </div>
          <Rating initialValue={outof5} size="20" className="mr-2" readonly />
        </div>
        <div className="meta">
          <div className="item d-flex align-items-center">
            <span className="title weight-600">Excellent</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning "
              value={
                overallRating["5"] != undefined
                  ? (overallRating["5"].length / ratingList.length) * 100
                  : 0
              }
              style={{ height: "12px" }}
            />
            <span>{overallRating["5"] != undefined ? overallRating["5"].length : 0}</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Good</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={
                overallRating["4"] != undefined
                  ? (overallRating["4"].length / ratingList.length) * 100
                  : 0
              }
              style={{ height: "12px" }}
            />
            <span>{overallRating["4"] != undefined ? overallRating["4"].length : 0}</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Average</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={
                overallRating["3"] != undefined
                  ? (overallRating["3"].length / ratingList.length) * 100
                  : 0
              }
              style={{ height: "12px" }}
            />
            <span>{overallRating["3"] != undefined ? overallRating["3"].length : 0}</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Poor</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={
                overallRating["2"] != undefined
                  ? (overallRating["2"].length / ratingList.length) * 100
                  : 0
              }
              style={{ height: "12px" }}
            />
            <span>{overallRating["2"] != undefined ? overallRating["2"].length : 0}</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Terrible</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={
                overallRating["1"] != undefined
                  ? (overallRating["1"].length / ratingList.length) * 100
                  : 0
              }
              style={{ height: "12px" }}
            />
            <span>{overallRating["1"] != undefined ? overallRating["1"].length : 0}</span>
          </div>
        </div>
      </ReviewBox>
    );

    // ratingList.map((star,i) => {

    // })
  };

  const getCommentMarkup = () => {
    getCommentList();

    if (listingData.length) {
      return listingData.map((commentinfo, i) => {
        let replyList = listReply(commentinfo.replys != undefined ? commentinfo.replys : []);
        return (
          commentinfo._id != undefined && (
            <li>
              <ReviewItem
                rating={commentinfo.rating}
                img="https://i.pravatar.cc/100"
                name={commentinfo.user[0].name}
                date="Tue, 20 Mar 2020"
                id={commentinfo._id}
                comment={commentinfo.comment}
                handleReviewReply={() => handleReviewReply(commentinfo)}
              />
              {replyList}
            </li>
          )
        );
      });
    }
  };
  function listReply(child) {
    if (child != []) {
      return child.map((commentinfo, i) => {
        // console.log('commentinfo',commentinfo);
        let commentList = listReply(commentinfo.replys != undefined ? commentinfo.replys : []);

        return (
          <ul>
            <li>
              <ReviewItem
                rating={commentinfo.rating}
                img="https://i.pravatar.cc/100"
                name={commentinfo.user[0].name}
                date="Tue, 20 Mar 2020"
                id={commentinfo._id}
                comment={commentinfo.comment}
                handleReviewReply={() => handleReviewReply(commentinfo)}
              />
              {commentList}
            </li>
          </ul>
        );
      });
    }
  }

  const cancelReply = () => {
    // console.log("hii");
    setReviewDetails({ id: 0, name: null, product_id: product_id });
  };
  return (
    <ReviewWrapper id="reviews">
      {getRating()}
      {/* <ReviewBox className="d-flex align-items-start flex-wrap">
        <div className="text-center flex-shrink-0">
          <div className="rating-wrapper p-4 rounded">
            <h1 className="title text-center text-white mb-0">4.0</h1>
            <small className="desc text-center text-white">Out of 5</small>
          </div>
          <Rating initialValue={4} size="20" className="mr-2" readonly />
        </div>
        <div className="meta">
          <div className="item d-flex align-items-center">
            <span className="title weight-600">Excellent</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning "
              value={90}
              style={{ height: "12px" }}
            />
            <span>123</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Good</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={75}
              style={{ height: "12px" }}
            />
            <span>90</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Average</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={50}
              style={{ height: "12px" }}
            />
            <span>50</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Poor</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={20}
              style={{ height: "12px" }}
            />
            <span>30</span>
          </div>

          <div className="item d-flex align-items-center">
            <span className="title weight-600">Terrible</span>
            <Progress
              className="mb-50 flex-grow-1"
              color="warning"
              value={1}
              style={{ height: "12px" }}
            />
            <span>10</span>
          </div>
        </div>
      </ReviewBox> */}
      <hr className="my-4" />
      <ReviewDetailWrapper>
        <ul className="unstyled">
          {getCommentMarkup()}
          {/* <li>
            <ReviewItem
              rating="5"
              img="https://i.pravatar.cc/100"
              name="Barry Allen"
              date="Tue, 20 Mar 2020"
              id="1"
              handleReviewReply={handleReviewReply}
            />
            <ul>
              <li>
                <ReviewItem
                  rating="5"
                  img="https://i.pravatar.cc/100"
                  name="Oliver Queen"
                  date="Tue, 20 Mar 2020"
                  id="2"
                  handleReviewReply={handleReviewReply}
                />
                <ul>
                  <li>
                    <ReviewItem
                      rating="5"
                      img="https://i.pravatar.cc/100"
                      name="John Wick"
                      date="Tue, 20 Mar 2020"
                      id="3"
                      handleReviewReply={handleReviewReply}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li> */}

          {/* <li>
            <ReviewItem
              rating="5"
              img="https://i.pravatar.cc/100"
              name="Barry Allen"
              date="Tue, 20 Mar 2020"
            />
          </li>

          <li>
            <ReviewItem
              rating="5"
              img="https://i.pravatar.cc/100"
              name="Barry Allen"
              date="Tue, 20 Mar 2020"
            />
          </li> */}
        </ul>
      </ReviewDetailWrapper>
      <hr className="mt-4" />
      <ReviewForm reviewDetails={reviewDetails} cancelReply={cancelReply} />
    </ReviewWrapper>
  );
};

export default Reviews;

export const ReviewWrapper = styled.section`
  margin-top: 0.4rem;
`;

export const ReviewBox = styled.section`
  gap: 15px;

  .style-module_simpleStarRating__nWUxf {
    margin-right: 0 !important;
  }
  .rating-wrapper {
    background: var(--warning);
  }

  .meta {
    flex-grow: 1;

    span:not(.title) {
      min-width: 20px;
      text-align: center;
    }

    .item {
      gap: 15px;

      &:not(:last-child) {
        margin-bottom: 0.353rem;
      }
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    .meta {
      flex: 1 1 100%;
      width: 100%;
    }
  }
`;

export const ReviewDetailWrapper = styled.div`
  & > ul:first-child {
    padding-left: 0;
  }
  ul {
    list-style: none;

    li:not(:last-child) {
      margin-bottom: 15px;
    }

    li {
      ul {
        margin-top: 15px;
        padding-left: 90px;
      }
    }
  }

  @media (max-width: 576px) {
    ul {
      li {
        ul {
          padding-left: 30px;
        }
      }
    }
  }
`;
