import React, { useState, useEffect } from "react";
// ** React Imports
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  //Pagination,
  //PaginationItem,
  //PaginationLink,
  Row,
} from "reactstrap";
import styled from "styled-components";
import Sidebar from "./FilterSidebar";
import { Input } from "reactstrap";
import { Search, Star } from "react-feather";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import { useDispatch, useSelector } from "react-redux";
import { listJobTemplate } from "../../Store/Action/JobAction.js";
import Pagination from "./components/Pagination";
import { PaginatedList } from "react-paginated-list";
import { forEach } from "lodash";

import Select from "react-select";

const JobListPage = () => {
  const dispatch = useDispatch();
  // const [rows, setRows] = useState([])
  const [recordsPerPage] = useState(5);

  const [searchQuery, setSearchQuery] = useState(null);
  const [selectOption, setSearchOption] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [sort, setSort] = useState(0);
  const [jobsCount, setJobsCount] = useState(0);
  const store = useSelector((state) => state.jobs.list);
  const history = useHistory();

  useEffect(() => {
    dispatch(listJobTemplate());
  }, [dispatch]);

  useEffect(() => {
    getJobsList();
  }, [searchQuery, searchFilter , selectOption]);

  const [jobsList, setJobsList] = useState(store);

  useEffect(() => {
    setJobsList(store);
    if (store != null) {
      setJobsCount(store.length);
    }
  }, [store]);

  const getJobsList = () => {
    if (store != null) {
      let allJobs = store;
      //console.log(searchFilter, "searchFilter");
      if (searchFilter.length) {
        allJobs = allJobs.filter(function (obj) {


          if ( (obj["employment_type"] != undefined && obj["employment_type"].length) || (obj["state"] != undefined && obj["state"].length) ) {
            
            let a = searchFilter.filter( (value) => (obj["employment_type"] == value) || (obj["state"] == value) );
            if(a.length>0){
              return a.length > 0;
            }
            
          }
          else {
            return false;
          }
        });
      }
      if (searchQuery) {
        allJobs = allJobs.filter(function (obj) {
          return (
            (obj["title"] ? obj["title"] : "").toLowerCase().includes(searchQuery.toLowerCase()) ||
            (obj["description"] ? obj["description"] : "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        });
      }
      setJobsCount(allJobs.length);
      setJobsList(allJobs);
    }
  };

  const getSpecializationName = (row) => {
    if (row?.job_specializations.length > 0) {
      return row?.job_specializations
        .map(function (e) {
          return e.specialization_name;
        })
        .join(", ");
    }
  };

  //Search list of objects
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterOption = (option) => {
    setSearchFilter(option);
  };

  const routeChange = (id) => {
    return history.push({
      pathname: "/job-detail",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };

  if (selectOption) {

      let allJobs = store;
      
      var sortType='';
      var sortByField='';

      if(selectOption=='new-to-old'){
        sortByField = 'created_date';
        sortType = 'ascending';
      }
      else if(selectOption == 'old-to-new'){
        sortByField = 'created_date';
        sortType = 'descending';
      }
      else if(selectOption == 'company'){
        sortByField = 'company_name';
        sortType = 'ascending';
      }
      else if(selectOption == 'state'){
        sortByField = 'state';
        sortType = 'ascending';
      }

      allJobs = sortFunc(allJobs, sortType, sortByField)

  }

  function sortFunc(results, sortType, sortByField) {
  if (sortType === "ascending") {
  results.sort((a, b) => a[sortByField] < b[sortByField] ? -1 : 1)  
  }
  else if (sortType === "descending") {
  results.sort((a, b) => b[sortByField] > a[sortByField] ? 1 : -1)
  }
  return results;
  }
  const handleSortBy = (event) => {
  setSearchOption(event.value);
  };

  const options = [
    { value: "new-to-old", label: "Newest to Oldest" },
    { value: "old-to-new", label: "Oldest to Newest" },
    { value: "company", label: "Company" },
    { value: "state", label: "State" },
  ];

  return (
    store != [] && (
      <PageWrapper id="page-wrapper" className="pb-6">
        <TitleWrapper className="pt-6 mb-6">
          <Container>
            <h3 className="text-white">All Jobs</h3>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>All Jobs</BreadcrumbItem>
            </Breadcrumb>
          </Container>
        </TitleWrapper>

        <ProductListWrapper>
          <Container>
            <Row>
              <Col md="4" lg="3" className="mt-0 mt-md-0">
                <Sidebar filterOption={filterOption} />
              </Col>
              <Col md="8" lg="9" className="mt-5 mt-md-0">
                <div className="d-flex flex-column">
                  <h6>{jobsCount} results found</h6>
                  <div
                    className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
                    style={{ gap: "15px", marginBottom: "30px" }}
                  >
                    <SearchWrapper className="flex-grow-1">
                      <Input
                        type="text"
                        className="search"
                        id="search"
                        placeholder="Search"
                        size={"lg"}
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                      <Search size={"18px"} />
                    </SearchWrapper>
                    <Select
                      options={options}
                      className="react-select flex-grow-1 flex-grow-sm-0"
                      placeholder="Sort By"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: "48.2px",
                          maxHeight: "48.2px",
                          borderColor: state.isFocused || state.isHover ? "#173372" : "#d8d6de",

                          borderWidth: "1px",
                          boxShadow: "unset",
                          width: "100%",
                          borderRadius: "3px",
                          fontWeight: "400",
                          minWidth: "196px",
                        }),
                      }}
                      onChange={handleSortBy}
                    />
                  </div>
                </div>

                {jobsList != null && (
                  <PaginatedList
                    list={jobsList}
                    nextText="Next"
                    prevText="Previous"
                    itemsPerPage={5}
                    renderList={(list) => (
                      <>
                        {list.map((row, id) => {
                          return (
                            <ProductListCard key={id}>
                              <Card>
                                <CardBody>
                                  <div className="product-image">
                                    <img src={row.company_logo} alt="" width={"100%"} />
                                  </div>
                                  <div className="description">
                                    <h3>{row.title}</h3>

                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: row.description,
                                      }}
                                    ></p>
                                  </div>
                                </CardBody>
                                <CardFooter className="bg-white">
                                  <ProductMeta className="unstyled">
                                    {/*<li className="category">
                                      <span className="title">Specializations: </span>
                                      {getSpecializationName(row)}
                                    </li>*/}
                                    <li className="category">
                                      <span className="title">Job Type : </span>
                                      {row.employment_type}
                                    </li>
                                    <li className="category">
                                      <span className="title">State : </span>
                                      {row.state}
                                    </li>

                                    <li className="category">
                                      <span className="title">Practice : </span>
                                      {row.company_name}
                                    </li>
                                    <li className="ml-auto ">
                                      <Button
                                        // tag={Link}
                                        onClick={() => routeChange(row._id)}
                                        color="primary"
                                        size="sm"
                                        className="view-product"
                                      >
                                        View Job
                                      </Button>
                                    </li>
                                  </ProductMeta>
                                </CardFooter>
                              </Card>
                            </ProductListCard>
                          );
                        })}
                      </>
                    )}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </ProductListWrapper>
      </PageWrapper>
    )
  );
};

export default JobListPage;

export const ProductListWrapper = styled.main`
  .pagination {
    li {
      margin-bottom: 0;
      line-height: 1.2;
      transition: background 300ms ease;

      &:not(.active):hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    }
  }

  @media (min-width: 576px) {
    .flex-grow-sm-0 {
      flex-grow: unset !important;
    }
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  /* margin-bottom: 30px; */

  input {
    padding-right: 40px;
  }

  svg {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ProductListCard = styled.section`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  .card-body {
    display: grid;
    grid-template-columns: 200px 2fr;
    gap: 20px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;

      .product-image {
        img {
          height: unset;
        }
      }
    }
  }

  .product-image {
    position: relative;

    img {
      max-height: 150px;
      width: 200px;
      aspect-ratio: 16/9;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
  }

  .description {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }
  }
`;

export const ProductMeta = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;

  li {
    margin-bottom: 0 !important;

    &:not(:last-child) {
      background-color: #f1f1f1;
      padding: 5px 13px;
      border-radius: 100px;
      font-size: 14px;
    }

    &.rating {
      span {
        font-size: 14px;
      }

      .alert {
        padding: 7px 13px;
        border-radius: 100px;
        line-height: 1.2;
        color: #ae840c;
        font-weight: 600;

        svg {
          stroke: var(--warning);
          fill: var(--warning);
        }
      }
    }
  }

  .view-product {
    padding: 7px 20px;
  }

  .title {
    font-weight: 600;
  }
`;
