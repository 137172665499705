import React, { useState, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import { Col, Form, FormGroup, Label, Row, Input, Button } from "reactstrap";
import styled from "styled-components";
import { valSwalSuccess,valSwalConfirm,valSwalFailure,swalalertWithMsg } from '../../../dev-config/global'
import { useDispatch, useSelector } from "react-redux";
import { createComment } from "../../../Store/Action/ProductAction.js"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";



const ReviewForm = ({ reviewDetails, cancelReply }) => {
  const { id, name, product_id } = reviewDetails;
 
  const { user, getAccessTokenSilently } = useAuth0();
  const [form, setForm] = useState([]);

 
  const basicForm = {
    comment:'',
    rating:0,
    parent: id,
    product_id:product_id,
    user_id:user.sub.replace("auth0|", "")
  };


  // basicForm.parent =;
  // console.log('reviewreply',basicForm.parent);
  

  useEffect(() => {
    setForm(basicForm);
    // console.log('comments',Comments);
    // getRating();
     
  },[reviewDetails]);

 

// console.log('form',form);
  const dispatch = useDispatch()

  const formChange = (field,event) => {
    var formData = {...form}
    if(field == 'rating'){
      formData.rating = event;
    }
    else if (field== 'textarea'){
      formData.comment = event.target.value;
    }
    setForm(formData);
  };


  const onSubmit = () => {
        dispatch(createComment(form))
        .then((res) => {
          valSwalSuccess('Success','Comment Updated Successfully!')
          if(form.parent) cancelReply();
          if(!form.parent) setForm(basicForm);

        })
        .catch((err) => {
          valSwalFailure('Error','Something went wrong please try again')
        });
  };

  return (
    <FormWrapper id="review">
      <Form>
        <Row>
          <Col lg="12">
            {id !== 0 ? (
              <h4 style={{ fontSize: "1.4rem" }}>
                Reply to {name}{" "}
                <span
                  style={{ fontSize: ".8rem", fontWeight: "500", cursor: "pointer" }}
                  className="text-underline"
                  onClick={cancelReply}
                >
                  Cancel Reply
                </span>
              </h4>
            ) : (
              <h4 style={{ fontSize: "1.4rem" }}>Leave a Comment</h4>
            )}
           
            { !id && <FormGroup>
              <Label className="d-block">Your Overall Rating</Label>
              <Rating initialValue={form.rating} size="20" onClick={(e) => formChange('rating',e)}/>
            </FormGroup>}
            <FormGroup>
              <Label>
              {id === 0 ? ('Comment'): ('Reply')}
                 
                
                <span className="text-danger">*</span>
              </Label>
              <Input type="textarea" rows="5"  value={form.comment} onChange={(e) => formChange('textarea',e)}/>
            </FormGroup>
            
            <div className="text-right">
              <Button disabled={!form?.comment?.length} color="primary" size="sm" onClick={() => onSubmit()}>
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  );
};

export default ReviewForm;

export const FormWrapper = styled.section``;
