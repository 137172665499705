import React , {useState, useEffect, useRef }from "react";
import { Media, Container, Row, Col, Label, Card, CardBody, Input, InputGroup,  InputGroupAddon,
  InputGroupText, Button, FormGroup } from "reactstrap";
import Loading from "../../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { AvForm, AvInput, AvGroup, AvField } from "availity-reactstrap-validation-safe";
import { useHistory } from "react-router-dom";
import { valSwalSuccess,valSwalConfirm,valSwalFailure,swalalertWithMsg, currentRole } from '../../../dev-config/global'
import InputMask from "react-input-mask";
import {currentUser, updateUserProfile, updateUserPassword } from "../../../views/user/store/action/index.js"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

export const ProfileComponent = () => {
  //const { user } = useAuth0();
  const passwordForm = useRef();

//const domain = "dev-qgf3-cce.us.auth0.com";
const domain = "login.mycoa.io";
const { user, getAccessTokenSilently } = useAuth0();
const [userMetadata, setUserMetadata] = useState(null);
const userProfile = useSelector((state)=> state.users.userProfile)
const history = useHistory();

const [authID, setAuthID] = useState("");
// console.log(userProfile,'userProfile')
const dispatch = useDispatch()
useEffect(()=>{
  if(user && user.sub){
    dispatch(currentUser(user?.sub.replace("auth0|", "")))
  }
  return (()=>{
    dispatch({
      type: 'CURRENT_USER',
      data: null
    })
  })
},[user])

useEffect(() => {
  const getUserMetadata = async () => {
    try {
      const accessToken1 = await getAccessTokenSilently({
        audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
        scope: "read:current_user",
        //audience: `https://${domain}/api/v2/users`,
        //scope: "read:current_user_metadata",   
      });
      // console.info("accessToken", accessToken1)
      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      // console.info("userDetailsByIdUrl", userDetailsByIdUrl)
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      // console.info("metadataResponse", metadataResponse)
      const { user_metadata } = await metadataResponse.json();
      console.info("user_metadata", user_metadata)
      setUserMetadata(user_metadata);
    } catch (e) {
      console.log(e.message);
    }
  };
  getUserMetadata();
}, [getAccessTokenSilently, user?.sub]);
console.log(userMetadata,'userMetadata')

const updateUser = async (formData) => {
  try {
    // console.info(formData.userId);
    const accessToken = await getAccessTokenSilently({
      audience: `https://dev-qgf3-cce.us.auth0.com/api/v2/`,
    });
    const response = await axios.post( `https://mycoa.io/user/${formData.userId}`,
      { formData },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    // console.info("response", response.data);
  } catch (error) {
    console.info("error", error);
  }
};

const onSubmit = (event, errors, values) => {
  console.log('values', values)
var validation = {
  first_name:"First Name",
  last_name:"Last Name",
  phone:"Phone",
  zipCode:"Zip Code",
  organization_name:"Organization Name",
  currentrole:"Role",
  degrees:"Degrees / Credentials",
  job_title:"Job Title",
  job_type:"Job Type",
  work_phone:"Practice Phone",
  practice_address:"Practice Address"
}

  if (errors.length > 0) {
    valSwalFailure("Error!", "Please enter " + validation[errors[0]]);
    return false;
  }
  swalalertWithMsg(true,'please wait!')
  var resultData = updateUser(values);
  resultData.then(()=>{
    dispatch(updateUserProfile(values)).then(()=>{
      valSwalSuccess('Success','Profile Updated Successfully!')
    }).catch(()=>{
      valSwalFailure('Error','Something went wrong please try again')
    })
  }).catch(()=>{
    valSwalFailure('Error','Something went wrong please try again')
  })
};


const handleUpdatePassword = async (e, errors, values) => {
  if (errors.length > 0) {
    valSwalFailure("Error", "Please enter " + errors[0]);
    return false;
  }
  if (values.password != values.confirm_password) {
    valSwalFailure(
      "Error",
      "New password and Confirm password should be same"
    );
    return false;
  }
  swalalertWithMsg(true, "Please wait...");
  dispatch(updateUserPassword(values))
    .then((res) => {
      // passwordForm.current.reset()
      document.getElementById("password").value = "";
      document.getElementById("confirm_password").value = "";
      valSwalSuccess("Success", "Password updated succesfully!");
    })
    .catch((err) => {
      if( err?.response?.data?.message == 'The user does not exist.'){
        document.getElementById("password").value = "";
      document.getElementById("confirm_password").value = "";
      valSwalSuccess("Success", "Password updated succesfully!");
      }
      else{
      valSwalFailure(
        "Error",
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong, please try again!"
      );
      }
    });
};

  return (
    <Container className="my-6">
        <Card className="mb-0 mt-2" style={{borderBlock:'none',border:'none'}}>
        <CardBody className="pb-2">
          <div>
            <Media className="mb-3 align-items-center">
              <img
                className="user-avatar rounded mr-2 my-25 cursor-pointer"
                src={user?.picture}
                alt="user profile avatar"
                height="90"
                width="90"
              />
              <Media className="mt-50" body>
                <h4 className="mt-0">
                  {userMetadata?.first_name} {userMetadata?.last_name}
                </h4>
                <div className="d-flex flex-wrap mt-1 px-0">
                  <Link
                    to="#"
                    onClick={() => {
                      history.goBack();
                    }}
                    className="btn btn-outline-primary mr-75 mb-0"
                  >
                    <span className='d-none d-sm-block'>Back</span>
                  </Link>
                </div>
              </Media>
            </Media>
          </div>
          <Row>
            {/* <Highlight>{JSON.stringify(userMetadata, null, 2)}</Highlight> */}
            <Col sm="12">
              <AvForm onSubmit={onSubmit}>
                <Row>
                  <Col md="6" sm="12">
                    <AvGroup>
                      <Label className="form-label" for="name">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <AvInput type="hidden" name="userId" id="userId" value={'auth0|'+userProfile?._id}/>
                      <AvInput type="hidden" name="_id" id="_id" value={userProfile?._id}/>
                      {/* <AvInput type="hidden" name="status" id="status" value={userProfile?.status}/> */}
                      <AvInput type="hidden" name="initiative" id="initiative" value={userMetadata?.initiative}/>
                      <AvInput
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="Name"
                        value={userMetadata?.first_name}
                        required
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="12">
                    <AvGroup>
                      <Label className="form-label" for="last_name">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        value={userMetadata?.last_name}
                        required
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="12">
                    <AvGroup>
                      <Label className="form-label" for="email">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={user?.email}
                        required
                        readOnly
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6" sm="12">
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <InputGroup className="input-group-merge">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>US (+1)</InputGroupText>
                      </InputGroupAddon>
                      <AvInput
                        id={`phone`}
                        name={`phone`}
                        type="tel"
                        mask="999 999 9999"
                        maskChar=" "
                        tag={[Input, InputMask]}
                        style={{ paddingLeft: ".5rem" }}
                        value={userProfile?.phone}
                        placeholder="ex: 123 456 7890"
                      />
                  </InputGroup>
                </FormGroup>
              </Col>
                  <Col md="6" sm="12">
                    <AvGroup>
                      <Label className="form-label" for="zipCode">
                        Zip Code<span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="number                                                                                                                                                                                                                                                                                                                                                                         "
                        id="zipCode"
                        name="zipCode"
                        maxLength={5}
                        placeholder=""
                        value={userProfile?.zipCode}
                        required
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6" sm="12">
                    <AvGroup>
                      <Label for="organization_name">
                        Organization Name <span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="text"
                        id="organization_name"
                        name="organization_name"
                        placeholder="Enter Organization Name"
                        value={userProfile?.organization_name}
                        required
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6" sm="12">
                <FormGroup>
                  <Label for="currentrole">Role</Label>
                  <AvField type="select" name='currentrole' value={userProfile?.currentrole}>
                    <option value=''>Select Role</option>
                    {
                      currentRole().map((currentRolOpt,index)=>{
                        return (
                          <option value={currentRolOpt}>{currentRolOpt}</option>
                        )
                      })
                    }
                  </AvField>
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="degrees">Degrees / Credentials</Label>
                  <AvInput
                    name="degrees"
                    id="degrees"
                    placeholder="ex: Dr"
                    value={userProfile?.degrees}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="job_title">Job Title</Label>
                  <AvInput
                    name="job_title"
                    id="job_title"
                    placeholder="ex: CEO"
                    value={userProfile?.job_title}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="job_type">Job Type</Label>
                  <AvInput
                    name="job_type"
                    id="job_type"
                    placeholder=" "
                    value={userProfile?.job_type}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="practice_phone">Practice Phone</Label>
                  <InputGroup className="input-group-merge">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>US (+1)</InputGroupText>
                      </InputGroupAddon>
                      <AvInput
                        id={`work_phone`}
                        name={`work_phone`}
                        type="tel"
                        mask="999 999 9999"
                        maskChar=" "
                        tag={[Input, InputMask]}
                        style={{ paddingLeft: ".5rem" }}
                        value={userProfile?.work_phone}
                        placeholder="ex: 123 456 7890"
                      />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="practice_address">Practice Address</Label>
                  <AvInput
                    name="practice_address"
                    id="practice_address"
                    placeholder=" "
                    value={userProfile?.practice_address}
                  />
                </FormGroup>
              </Col>

                  {/* <Col className="d-flex flex-sm-row flex-column mt-2" sm="12">
              <Button
                disabled
                className="mb-1 mb-sm-0 mr-0 mr-sm-1"
                type="submit"
                color="primary"
              >
                Save Changes
              </Button>
            </Col> */}
                </Row>
                <Button color="btn btn-primary" style={{ width: "max-content" }}>
            Submit
          </Button>
              </AvForm>
            </Col>
          </Row>
        </CardBody>
      </Card>


      <Card className="mt-2" style={{ borderBlock:'none',border:'none' }}>
        <CardBody>

              {/* change password */}

              <AvForm
            onSubmit={handleUpdatePassword}
            ref={passwordForm}
            // className="pt-1 pb-1 px-2"
            // style={{ backgroundColor: "#edf2ff" }}
          >
            <h4 className="mt-1">Change Password</h4>
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="password">New Password</Label>
                  <AvInput
                    name="user_id"
                    id="user_id"
                    value={userProfile?._id}
                    hidden
                  />
                  <AvInput
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter New Password"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="confirm_password">Confirm New Password</Label>
                  <AvInput
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm New Password"
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm="12" className="mb-1">
                <Button
                  // style={{ float: "right" }}
                  type="update_password"
                  name="update_password"
                  color="primary"
                >
                  Update Password
                </Button>
              </Col>
            </Row>
          </AvForm>
              {/* change password */}
        </CardBody>
      </Card>

      {/* <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row> */}
      {/* <Row>
        <Highlight>{JSON.stringify(userMetadata, null, 2)}</Highlight>
      </Row> */}
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
