export const menuItems = [
  {
    title: "Education & Publications",
    link: "https://mycoa.coacan.org/education-publications/",
    target: "_self",
    submenu: [
      {
        title: "How To Guides & Training",
        link: "/education-publications/how-to-guides-training",
        target: "_self",
      },
      {
        title: "Payment Reform",
        link: "https://coacan.org/payment-reform-model/",
        target: "_self",
      },
      {
        title: "E&M, Coding and Billing",
        link: "/education-publications/em-coding-billing",
        target: "_self",
      },
      {
        title: "All COA studies & publications",
        link: "https://mycoa.communityoncology.org/education-publications",
        target: "_self",
      },
    ],
  },
  {
    title: "Resources",
    link: "https://coacan.org/resources/",
    target: "_self",
    submenu: [
      {
        title: "Practice Operations",
        link: "https://coacan.org/resources/#_practice-operations",
        target: "_self",
      },
      {
        title: "Enhancing Oncology Model (EOM)",
        link: "https://coacan.org/enhancing-oncology-model-eom/",
        target: "_self",
      },
      {
        title: "Medicare Impact Models",
        link: "/resources/medicare-impact-models/",
        target: "_self",
      },
      {
        title: "OCM Support Network Archive",
        link: "https://mycoa.coacan.org/resources/ocm/",
        target: "_self",
      },
      {
        title: "Oncology Medical Home (OMH)",
        link: "https://www.MedicalHomeOncology.org",
        target: "_self",
      },
      {
        title: "COA TV",
        link: "https://coacan.org/resources/#coatv",
        target: "_self",
      },
      {
        title: "State Resources & Information",
        link: "https://coacan.org/resources/#_state-resources",
        target: "_self",
      },
      {
        title: "Addressing PBM & Insurer Abuses",
        link: "/resources/pbm-tools/resources-for-pbm-and-insurer-abuses",
        target: "_self",
      },
    ],
  },
  {
    title: "Meetings",
    link: "/events/member-meetings/",
    target: "_self",
    submenu: [
      {
        title: "Monthly Membership Calls & Recordings",
        link: "/events/member-meetings/",
        target: "_self",
      },
    ],
  },

  {
    title: "Members",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "Join CAN",
        link: "https://mycoa.io/",
        target: "_self",
      },
      {
        title: "Subscribe To Updates",
        link: "https://coacan.org/subscribe-to-updates/",
        target: "_self",
      },
    ],
  },
  {
    title: "About",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "About CAN",
        link: "https://coacan.org/about/",
        target: "_self",
      },
      {
        title: "Leadership & Advisory Board",
        link: "https://coacan.org/about/#our-leadership",
        target: "_self",
      },
      {
        title: "What is Community Oncology?",
        link: "/about/what-is-community-oncology",
        target: "_self",
      },
      {
        title: "Our Members & Community",
        link: "#",
        target: "_self",
      },
      {
        title: "Contact Us",
        link: "https://coacan.org/contact/",
        target: "_self",
      },
    ],
  },
];
