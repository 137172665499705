import axios from "axios";
export const createJobTemplate = (data) => {
  return async (dispatch) => {
    await axios.post(`/job-portal/postData`, data).then((response) => {
      dispatch({
        type: "GET_ALL_JOBS",
        data: response.data.data,
      });
    });
  };
};

export const updateJobTemplate = (data) => {
  return async (dispatch) => {
    await axios.post(`/job-portal/updateData`, data).then((response) => {
      dispatch({
        type: "EDIT_JOB",
        data: response.data.data,
      });
    });
  };
};

export const listJobTemplate = () => {
  return async (dispatch) => {
    await axios.get(`/job-portal/list`).then((response) => {
      dispatch({
        type: "GET_ALL_JOBS",
        data: response.data.data,
      });
    });
  };
};

export const listMyJobTemplate = (userID) => {
  return async (dispatch) => {
    await axios.get(`/job-portal/mylist/${userID}`).then((response) => {
      dispatch({
        type: "GET_ALL_JOBS",
        data: response.data.data,
      });
    });
  };
};

export const specializationsList = () => {
  return async (dispatch) => {
    await axios.get(`/specializations/list`).then((response) => {
      dispatch({
        type: "GET_ALL_SPECIALIZATIONS",
        data: response.data.specialization,
      });
    });
  };
};

export const createSpecialization = (data) => {
  return async (dispatch) => {
    // var parameters = data.split('/');
    await axios.post(`/specializations/create`, data).then((response) => {
      dispatch({
        type: "GET_ALL_SPECIALIZATIONS",
        data: response.data,
      });
    });
  };
};

export const showJobDetails = (id) => {
  return async (dispatch) => {
    await axios.get(`/job-portal/getJobDetails/${id}`).then((response) => {
      dispatch({
        type: "GET_JOB_DETAILS",
        data: response.data && response.data.data ? response.data.data : [],
      });
    });
  };
};

export const applyJob = (data) => {
  return async (dispatch) => {
    await axios.post(`/job-portal/applyJob`, data).then((response) => {
      dispatch({
        type: "APPLY_JOB",
        data: response.data.data,
      });
    });
  };
};
// export const createComment = (data) => {
//   return async (dispatch) => {
//     await axios.post(`/job-portal/postcomment`, data).then((response) => {
//       // console.log('red',response.data);
//       dispatch({
//         type: "GET_JOB_DETAILS",
//         data: response.data ? response.data : [],
//       });
//       dispatch({
//         type: "UPDATE_JOBS",
//         data: response.data.data,
//       });
//     });
//   };
// };

/*export const showJobDetails = (data) =>{
    return async dispatch => {
        await axios.get(`/job-portal/getdetails/`+data)
            .then(response =>  {
                dispatch({
                    type: 'GET_JOB_DETAILS',
                    data: response.data
                })
            });
        
    }
}*/
