import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { SubHeaderWrapper } from "../components/GlobalStyles";
import featuredImage from "../assets/images/rating-banner.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Rating } from "react-simple-star-rating";
// image
import rating1 from "../assets/images/rating1.svg";
import rating2 from "../assets/images/rating2.svg";
import rating3 from "../assets/images/rating3.svg";
import rating4 from "../assets/images/rating4.svg";

const RatingPage = () => {
  const [rating, setRating] = useState(3);

  return (
    <div className="page-wrapper">
      <SubHeaderWrapper className="my-6">
        <Container>
          <Row className="mb-6 align-items-center">
            <Col lg="6">
              <img src={featuredImage} alt="featured image" width="100%" />
            </Col>
            <Col lg="6" className="mt-4 mt-lg-0">
              <h2>
                Ratings and Reviews of Hospitals, Products and Pharma Companies
              </h2>
              <p>
                COMMUNITY ONCOLOGY ALLIANCE Innovating and Advocating for
                Community Cancer Care On behalf of the Board of Directors of the
                Community Oncology Alliance (“COA”), we are submitting this
                response to the House of Representatives MACRA RFI.
              </p>
              <Button color="secondary mt-2">READ MORE</Button>
            </Col>
          </Row>
        </Container>
      </SubHeaderWrapper>
      <section
        className="center-text"
        style={{ maxWidth: "800px", margin: "0px auto" }}
      >
        <Container>
          <p className="text-center">
            On behalf of the Community Oncology Alliance (“COA”), we are
            submitting our concerns regarding the Enhancing Oncology Model
            (“EOM”) to the leadership at the Centers for Medicare & Medicaid
            Services (“CMS”) and Center for Medicare and Medicaid Innovation
            (“CMMI”).
          </p>
        </Container>
      </section>

      <section>
        <Container>
          <hr className="separator my-5" />
        </Container>
      </section>

      <RatingPosts className="mb-6">
        <Container>
          <div className="filter">
            <select class="form-control" id="exampleFormControlSelect2">
              <option></option>
              <option></option>
              <option></option>
              <option></option>
              <option></option>
            </select>
            <select class="form-control" id="exampleFormControlSelect2">
              <option></option>
              <option></option>
              <option></option>
              <option></option>
              <option></option>
            </select>
          </div>
          <Row className="post-wrapper mt-5 ml-0 mr-0" style={{ gap: "15px" }}>
            <Col lg="6" md="6" sm="12" className="post-item ">
              <div className="post-image">
                <img src={rating1} alt="image" />
              </div>
              <div className="post-meta">
                <Link to="/rating-single" className="title">
                  COA CAN Insights
                </Link>
                <div className="ratings d-flex align-items-center mb-2">
                  <Rating
                    initialValue={rating}
                    readonly="true"
                    size="22"
                    className="mr-2"
                  />{" "}
                  <span style={{ color: "#999" }}>(662)</span>
                </div>
                <div className="desc">
                  COA is a recognized leader and staunch supporter of
                  value-based care initiatives, which we believe can costs.
                </div>
                <div className="category">Omaha Oncology Specialists</div>
              </div>
            </Col>
            {/* item end */}

            <Col lg="6" md="6" sm="12" className="post-item">
              <div className="post-image">
                <img src={rating2} alt="image" />
              </div>
              <div className="post-meta">
                <Link to="/rating-single" className="title">
                  Crestor Pharma
                </Link>
                <div className="ratings d-flex align-items-center mb-2">
                  <Rating
                    initialValue={rating}
                    readonly="true"
                    size="22"
                    className="mr-2"
                  />{" "}
                  <span style={{ color: "#999" }}>(662)</span>
                </div>
                <div className="desc">
                  COA is a recognized leader and staunch supporter of
                  value-based care initiatives, which we believe can lower
                  costs.
                </div>
                <div className="category">Chicago Oncology Specialists</div>
              </div>
            </Col>
            {/* item end */}

            <Col lg="6" md="6" sm="12" className="post-item">
              <div className="post-image">
                <img src={rating3} alt="image" />
              </div>
              <div className="post-meta">
                <Link to="/rating-single" className="title">
                  COA CAN Insights
                </Link>
                <div className="ratings d-flex align-items-center mb-2">
                  <Rating
                    initialValue={rating}
                    readonly="true"
                    size="22"
                    className="mr-2"
                  />{" "}
                  <span style={{ color: "#999" }}>(662)</span>
                </div>
                <div className="desc">
                  COA is a recognized leader and staunch supporter of
                  value-based care initiatives, which we believe can lower
                  costs.
                </div>
                <div className="category">Maryland Oncology Specialists</div>
              </div>
            </Col>
            {/* item end */}

            <Col lg="6" md="6" sm="12" className="post-item">
              <div className="post-image">
                <img src={rating4} alt="image" />
              </div>
              <div className="post-meta">
                <Link to="/rating-single" className="title">
                  Crestor Pharma
                </Link>
                <div className="ratings d-flex align-items-center mb-2">
                  <Rating
                    initialValue={rating}
                    readonly="true"
                    size="22"
                    className="mr-2"
                  />{" "}
                  <span style={{ color: "#999" }}>(662)</span>
                </div>
                <div className="desc">
                  COA is a recognized leader and staunch supporter of
                  value-based care initiatives, which we believe can lower
                  costs.
                </div>
                <div className="category">Nebraska Oncology Specialists</div>
              </div>
            </Col>
            {/* item end */}
          </Row>
        </Container>
      </RatingPosts>
    </div>
  );
};

export default RatingPage;

export const RatingPosts = styled.section`
  .filter {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    max-width: 500px;
    margin-left: auto;
  }

  .post-item {
    display: flex;
    gap: 20px;
    background-color: #f6f6f6;
    padding: 15px;
    flex-basis: 48%;
    align-items: flex-start;

    .post-image {
      flex: 0 1 150px;
      border-radius: 13px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
      }
    }

    .post-meta {
      flex: 1 1;

      .title {
        display: block;
        font-size: 22px;
        font-weight: 600;
        font-family: "Montserrat";
      }

      .category {
        color: #999;
        margin-top: 7px;
      }
    }

    @media (max-width: 769px) {
      flex: 1 1 100% !important;
      max-width: 100%;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      text-align: center;
      align-items: center;

      padding: 25px 15px;

      .post-image {
        flex-basis: 100%;
        text-align: center;
        width: 100%;
      }

      .ratings {
        justify-content: center;
      }
    }
  }
`;
