import styled from "styled-components";

export const SubHeaderWrapper = styled.section`
  h2 {
    font-size: 45px;
    font-weight: 800;
    position: relative;
    z-index: 9;
  }

  @media (max-width: 576px) {
    h2 {
      font-size: 28px;
    }
  }
`;
