import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Tooltip,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import { AvForm, AvInput, AvGroup, AvField } from "availity-reactstrap-validation-safe";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Trash2, ChevronDown, Plus } from "react-feather";
import Select from "react-select";
import {
  createProductTemplate,
  listProductTemplate,
  createComment,
} from "../../Store/Action/ProductAction.js";
// import { EditorState, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  valSwalSuccess,
  valSwalConfirm,
  valSwalFailure,
  swalalertWithMsg,
} from "../../dev-config/global";
import AddProductCategory from "./AddProductCategory.js";
import { listProductCategory } from "../../Store/Action/ProductAction.js";
import UploadFiles from "../../components/upload-files-component/upload-files-component.js";
import axios from "axios";
import InputMask from "react-input-mask";
import { Editor } from "@tinymce/tinymce-react";
import { Rating } from "react-simple-star-rating";

const AddProduct = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [addNewCategory, setAddNewCategory] = useState(false);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [body, SetBody] = useState(null);
  // const [categoryList, setCategoryList] = useState({});

  // const imageData = {0:null}
  const [imageUpload, setImageUpload] = useState([null]);
  const [productImagePreview, setProductImagePreview] = useState([]);
  const shouldRedirect = true;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // const newImage = {}
  const history = useHistory();
  useEffect(() => {
    dispatch(listProductCategory());
  }, []);
  const mainCategory = useSelector((state) => state.products.category);
  const [category, setCategory] = useState();

  // ** React Select Theme Colors
  const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "#04629C", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed", // for input hover border-color
    },
  });

  const handlerequestAccess = (e) => {
    let ids = e.map(function (i) {
      return i._id;
    });
    setCategoryInfo(ids);
    // console.log(e);
  };

  let listingData;

  function categoryListing() {
    // let abc;
    listingData = [];
    mainCategory
      .filter((c) => c.parent_id)
      .forEach((c) => {
        const parent = mainCategory.find((p) => p._id === c.parent_id);
        // console.log('asa',parent);
        parent.subCategories = parent.subCategories || [];
        if (parent.subCategories.filter((e) => e._id === c._id).length == 0) {
          parent.subCategories.push(c);
        }
      });

    listingData = mainCategory.filter((c) => !c.parent_id);
  }

  /* *** File upload function start *** */
  /* Feature image */
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);

  /* Product gallery */
  const [file, setFile] = useState([]);
  const [myArray, updateMyArray] = useState([]);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [categoryinfo, setCategoryInfo] = useState([]);
  const productGallery = [];

  const dropRef = useRef();
  const dropImageRef = useRef();
  //Handling image
  const onDropImage = (files) => {
    const [uploadedFile] = files;
    setFeaturedImage(uploadedFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setIsPreviewAvailable(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
  };
  const onDropProductImage = (files, i) => {
    const [uploadedFile] = files;
    const img_upload = [...imageUpload];
    img_upload[i] = files;
    setImageUpload(img_upload);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const img = [...productImagePreview];
      img[i] = fileReader.result;
      setProductImagePreview(img);
    };
    fileReader.readAsDataURL(uploadedFile);
  };

  const newProductImage = () => {
    const img_upload = [...imageUpload];
    img_upload[img_upload.length] = null;
    setImageUpload(img_upload);
  };

  const removeProductImage = (e, i) => {
    // setFeaturedImage(null);
    const img_upload = [...imageUpload];
    img_upload[i] = null;
    setImageUpload(img_upload);
    const img = [...productImagePreview];
    img[i] = "";
    setProductImagePreview(img);
    e.preventDefault();
  };
  const removeProductImageBlock = (e, i) => {
    // setFeaturedImage(null);
    const img_upload = [...imageUpload];
    img_upload.splice(i, 1);
    setImageUpload(img_upload);
    const img = [...productImagePreview];
    img.splice(i, 1);
    setProductImagePreview(img);
    e.preventDefault();
  };

  //Handling image

  const updateBorder = (dragState, reference) => {
    if (dragState === "over") {
      reference.current.style.border = "1px solid #000";
    } else if (dragState === "leave") {
      reference.current.style.border = "1px dashed #046f9c";
    }
  };
  const removeImage = (e) => {
    setFeaturedImage(null);
  };
  const removeProductGallery = (e) => {
    setFile(null);
  };
  /* *** File upload function end *** */

  const saveNewCategory = () => {
    // Write your codes to save the category
    setAddNewCategory(false);
  };

  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   SetBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  // };
  const routeListChange = () => {
    // Write your codes to save the category
    history.push("my-product-list");
  };
  const dispatch = useDispatch();

  const getUploadedFiles = (recordSet) => {
    setFile(recordSet);
  };
  // ** Function to handle form submit
  const onSubmit = async (e, errors, values) => {
    // console.log('asas',imageUpload.indexOf(null));
    // return false;
    var validation = {
      agreedToTerms: "Please agree to the terms and conditions.",
      title: "Product Title",
      short_description: "Short Description",
      description: "Description",
      website_url: "Website URL",
      phone_number: "Phone Number",
      manufacturer: "Product Manufacturer",
    };
    if (errors.length > 0) {
      if(errors[0] == 'agreedToTerms'){
        valSwalFailure("Error!", validation[errors[0]]);
      }else{
        valSwalFailure("Error!", "Please enter " + validation[errors[0]]);
      }
      return false;
    } else if (categoryinfo.length == 0) {
      valSwalFailure("Error!", "Please Select Product Category");
      return false;
    } 
    // else if (featuredImage == null) {
    //   valSwalFailure("Error!", "Please Choose the Featured Image");
    //   return false;
    // } else if (imageUpload.indexOf(null) > -1) {
    //   valSwalFailure("Error!", "Please upload the Product Gallery");
    //   return false;
    // }
    else if (ratings == 0) {
      valSwalFailure("Error!", "Product rating is required");
      return false;
    } else if (reviews == "") {
      valSwalFailure("Error!", "Product review is required");
      return false;
    } else {
      // alert('ok'); return false;
      swalalertWithMsg(true, "please wait!");
      let payload = values;
      payload.body = body;
      payload.parent_id = categoryinfo;
      var formDataAppend = new FormData();
      for (var key in payload) {
        formDataAppend.append(key, payload[key] ? payload[key] : "");
      }
      formDataAppend.append("ratings", ratings);
      formDataAppend.append("comment", reviews);

      formDataAppend.append("feature_image", featuredImage);
      console.log("gall", imageUpload);
      for (let i = 0; i < imageUpload.length; i++) {
        console.log("sinle", imageUpload[i]);
        if(imageUpload[i]){
          formDataAppend.append("product_gallery", imageUpload[i][0]);
        }
      }
      formDataAppend.append("user_id", user.sub.replace("auth0|", ""));
      swalalertWithMsg(true, "Please Wait!");
      dispatch(createProductTemplate(formDataAppend))
        .then((res) => {
          valSwalSuccess("Success", "Product Updated Successfully!");
          history.push("my-product-list");
        })
        .catch((err) => {
          valSwalFailure("Error", "Something went wrong please try again");
        });
    }
  };

  const categoryToggle = (value) => {
    setAddNewCategory(value);
  };
  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCategoryInfo((categoryinfo) => [...categoryinfo, e.target.id]);
    } else {
      let tempCategory = [...categoryinfo];
      const index = tempCategory.indexOf(e.target.id);
      if (index > -1) {
        // only splice array when item is found
        tempCategory.splice(index, 1); // 2nd parameter means remove one item only
      }
      setCategoryInfo(tempCategory);
    }
  };

  function categoryListTree() {
    categoryListing();
    return listingData.map((category, i) => {
      let subcategoryList = listSubCategory(
        category.subCategories != undefined ? category.subCategories : []
      );
      return (
        <li>
          <AvInput onChange={handleChange} name="parent_id" type="checkbox" id={category._id} />
          <Label for="1" className="mb-0">
            {category.category_name}
          </Label>
          {subcategoryList}
        </li>
      );
    });
  }

  function listSubCategory(child) {
    if (child != []) {
      return child.map((category, i) => {
        let subcategoryList = listSubCategory(
          category.subCategories != undefined ? category.subCategories : []
        );
        return (
          <ul className="pl-4">
            <li>
              <AvInput
                name="subcategory_id"
                onChange={handleChange}
                type="checkbox"
                id={category._id}
              />
              <Label for="1" className="mb-0">
                {category.category_name}
              </Label>
              {subcategoryList}
            </li>
          </ul>
        );
      });
    }
  }

  const [ratings, setRatings] = useState(0);
  const [reviews, setReviews] = useState("");

  const onRatingsAndReviewsChange = (field, event) => {
    if (field == "rating") {
      setRatings(event);
    } else if (field == "textarea") {
      setReviews(event.target.value);
    }
  };

  // const onRatingsAndReviewsSubmit = () => {
  //   dispatch(createComment(form))
  //   .then((res) => {
  //     valSwalSuccess('Success','Comment Updated Successfully!')
  //     if(!form.parent) setForm(basicForm);

  //   })
  //   .catch((err) => {
  //     valSwalFailure('Error','Something went wrong please try again')
  //   });
  // };

  return (
    <PageWrapper id="page-wrapper" className="pb-6">
      <TitleWrapper className="pt-6 mb-6">
        <Container>
          <h3 className="text-white">Add product</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="my-product-list">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add Product</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </TitleWrapper>

      <Container>
        <FormWrapper>
          <AvForm onSubmit={onSubmit}>
            {/* <Row>
              <Col lg="8" xl="9">
              <Card style={{ position: "sticky" , top: "100px" }}>
                <CardBody className="pb-1"> */}
            {/* <Row>
                    <Col lg="12">
                    <FormGroup>
                      <Label for="title" className="d-flex align-items-center">
                        Product Title <span className="text-danger ml-1">*</span>
                      </Label>
                      <AvInput name="title" id="title" placeholder="Product Title" required />
                    </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                    <FormGroup>
                      <Label for="description" className="d-flex align-items-center">
                        Short Description <span className="text-danger ml-1">*</span>
                      </Label>
                      <AvInput name="short_description" id="short_description" placeholder="Short Description" required />
                    </FormGroup>
                    </Col>
                  </Row> */}

            {/* <Row>
                    <Col lg="12">
                    <FormGroup>
                      <Label for="description" className="d-flex align-items-center">
                        Description
                      </Label> */}
            {/* <WysiwygEditor>
                        <Editor name="description" editorState={editorState} wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor" onEditorStateChange={onEditorStateChange} // toolbar={{ // options:
                          ["bold", "italic" , "underline" , "list" , "textAlign" ], // }} />
                        <textarea hidden disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                      </WysiwygEditor> */}

            {/* <EditorWrapper>
                        <Editor apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3" theme="modern"
                          readOnly={origin=="declined" } init={{ height: 250, // width: 1100, branding: false, menubar: false, //
                          statusbar: false, plugins: [ "advlist autolink lists link image" , "charmap print preview anchor help"
                          , "searchreplace visualblocks code" , "insertdatetime media table paste wordcount" , ], // toolbar:
                          // "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview"
                          , toolbar: "bold italic | alignleft aligncenter alignright | bullist numlist" ,
                          images_upload_url: "/api/page-template/aws" , images_file_types: "jpg,jpeg,png,svg,webp" ,
                          paste_data_images: true, setup: (editor)=> {
                          editor.on('init', () => {
                          const maxWordCount = 300;
                          const wordCountWarningThreshold = maxWordCount - 20;

                          const checkWordCount = () => {
                          const content = editor.getContent({ format: 'text' });
                          const words = content.split(/\s+/).filter(Boolean);
                          if (words.length > maxWordCount) {
                          // editor.setMode('readonly');
                          const truncatedContent = words.slice(0, maxWordCount).join(' ');
                          editor.setContent(truncatedContent);
                          alert('You have reached the maximum word count limit (300 words).');
                          }
                          // else if (words.length > wordCountWarningThreshold) {
                          // alert('You are approaching the maximum word count limit (300 words).');
                          // }
                          else {
                          editor.setMode('design');
                          }
                          };
                          editor.on('input', checkWordCount);
                          editor.on('paste', () => {
                          setTimeout(checkWordCount, 0);
                          });

                          });
                          },

                          }}
                          // value={details?.description}
                          onEditorChange={(content) => {
                          SetBody(content);
                          }}
                          />
                      </EditorWrapper>
                    </FormGroup>
                    </Col>
                  </Row> */}

            {/* <Row>
                    <Col lg="4">
                    <FormGroup>
                      <Label for="website-url" className="d-flex align-items-center">
                        Website URL
                      </Label>
                      <AvInput type="url" name="website_url" id="website_url" placeholder="http://test.com" required />
                    </FormGroup>
                    </Col>

                    <Col lg="4">
                    <FormGroup>
                      <Label for="website-url" className="d-flex align-items-center">
                        Phone Number
                      </Label>
                      <AvInput name="phone_number" type="tel" mask="999 999 9999" maskChar=" " id="phone_number" style={{
                        paddingLeft: ".5rem" }} tag={[Input, InputMask]} placeholder="Phone Number" required />
                    </FormGroup>
                    </Col>

                    <Col lg="4">
                    <FormGroup>
                      <Label for="manufacturer" className="d-flex align-items-center">
                        Product Manufacturer
                      </Label>
                      <AvInput name="manufacturer" id="manufacturer" placeholder="Product Manufacturer" required />
                    </FormGroup>
                    </Col>
                  </Row>
                </CardBody> */}
            {/* </Card>
              </Col>
              <Col lg="4" xl="3" className="mt-4 mt-lg-0">
              <ButtonGroup className="w-100">
                <PublishButton color="success" size="sm" className="mb-3">
                  Submit for Approval
                </PublishButton>
                <CancelButton color="danger" size="sm" className="mb-3" onClick={()=> routeListChange()}
                  >
                  Discard
                </CancelButton>
              </ButtonGroup>

              <Card className="mb-3">
                <CardHeader className="bg-white">
                  <h6 className="mb-0" style={{ fontWeight: "600" }}>
                    Product Category
                  </h6>
                </CardHeader>
                <CardBody>
                  <CategoryTree className="pl-4 mb-0">{categoryListTree()}</CategoryTree>
                </CardBody>
                <CardFooter className="bg-white">
                  <AddNewCategory className="">
                    <AddCategoryBtn block outline color="primary" size="sm" className="title" onClick={()=> setAddNewCategory(true)}
                      >
                      Add New Category
                    </AddCategoryBtn>
                  </AddNewCategory>
                  {addNewCategory &&
                  <AddProductCategory categoryToggle={categoryToggle} />}
                </CardFooter>
              </Card>

              <Card className="mb-3">
                <CardHeader className="bg-white">
                  <h6 className="mb-0" style={{ fontWeight: "600" }}>
                    Ratings & Reviews
                  </h6>
                </CardHeader>

                <CardBody>
                  <FormGroup>
                    <Label className="d-block">Your Overall Rating<span className="text-danger"> *</span></Label>
                    <Rating initialValue={ratings} size="20" onClick={(e)=> onRatingsAndReviewsChange('rating',e)}/>
                  </FormGroup>
                  <FormGroup>
                    <Label>Comment<span className="text-danger"> *</span></Label>
                    <Input type="textarea" rows="5" required value={reviews} onChange={(e)=>
                    onRatingsAndReviewsChange('textarea',e)}/>
                  </FormGroup>
                </CardBody> */}
            {/* <CardFooter className="bg-white">
                  <div className="text-right">
                    <Button disabled={!form?.comment?.length} color="primary" size="sm" onClick={()=> onRatingsAndReviewsSubmit()}>
                      Submit
                    </Button>
                  </div>
                </CardFooter> */}
            {/* </Card>

              <Card className="mb-3">
                <CardHeader className="bg-white">
                  <h6 className="mb-0" style={{ fontWeight: "600" }}>
                    Featured Image
                  </h6>
                </CardHeader>
                <CardBody>
                  <DropzoneWrapper>
                    <Dropzone onDrop={onDropImage} onDragEnter={()=> updateBorder("over", dropImageRef)}
                      onDragLeave={() => updateBorder("leave", dropImageRef)}
                      >
                      {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "drop-zone " , })} ref={dropImageRef} style={{ height: "unset" }}>
                        <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                        {!featuredImage ? <div>Drag or Click to Upload</div> : ""}
                        {featuredImage ? (
                        <div className="selected-item-wrapper w-100">
                          <div className="selected-item">
                            <Button className="remove-selected">
                              <Trash2 size="14" onClick={removeImage} />
                            </Button>

                            <img src={isPreviewAvailable} width="100%" height="" alt="" /> */}

            {/* {file && (
                            <div className="file-name mt-1">
                              <strong>Selected: </strong> {featuredImage.name}
                            </div>
                            )} */}
            {/* </div>
                        </div>
                        ) : (
                        ""
                        )}
                      </div>
                      )}
                    </Dropzone>
                  </DropzoneWrapper>
                </CardBody>
              </Card> */}

            {/* <Card>
                <CardHeader className="bg-white">
                  <h6 className="mb-0" style={{ fontWeight: "600" }}>
                    Product Gallery
                  </h6>
                </CardHeader>
                <CardBody>
                  <UploadFiles getUploadedFiles={getUploadedFiles} />
                </CardBody>
              </Card> */}

            {/* <Card className="mb-3">
                <CardHeader className="bg-white d-flex align-items-center justify-content-between">
                  <h6 className="mb-0" style={{ fontWeight: "600" }}>
                    Product Gallery
                  </h6>
                  <Tooltip isOpen={tooltipOpen} target="add_gallery" toggle={toggle}>
                    Add new image
                  </Tooltip>
                  <AddGalleryButton id="add_gallery" className="p-1" outline color="primary" onClick={()=> newProductImage()}
                    >
                    <Plus size="14" />
                  </AddGalleryButton>
                </CardHeader>
                <CardBody>
                  {imageUpload.map((item, i) => {
                  return (
                  <DropzoneWrapper>
                    {imageUpload.length > 1 && (
                    <Button onClick={(e)=> removeProductImageBlock(e, i)}
                      className="remove-selected"
                      >
                      <Trash2 size="14" onClick={(e)=> removeProductImage(e, i)} />
                    </Button>
                    )}

                    <Dropzone onDrop={(e)=> onDropProductImage(e, i)}
                      onDragEnter={() => updateBorder("over", dropImageRef)}
                      onDragLeave={() => updateBorder("leave", dropImageRef)}
                      >
                      {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "drop-zone " , })} ref={dropImageRef} style={{ height: "unset" }}>
                        <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                        {!imageUpload[i] ? <div>Drag or Click to Upload</div> : ""}
                        {imageUpload[i] ? (
                        <div className="selected-item-wrapper w-100">
                          <div className="selected-item">
                            <img src={productImagePreview[i]} width="100%" height="" alt="" /> */}

            {/* {file && (
                            <div className="file-name mt-1">
                              <strong>Selected: </strong> {featuredImage.name}
                            </div>
                            )} */}
            {/* </div>
                        </div>
                        ) : (
                        ""
                        )}
                      </div>
                      )}
                    </Dropzone>
                  </DropzoneWrapper>
                  );
                  })}
                </CardBody>
              </Card>
              </Col>
            </Row> */}

            <Row>
              <Col lg="12" xl="12">
                <Card style={{ position: "sticky", top: "100px" }}>
                  <CardBody className="pb-1">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="title" className="d-flex align-items-center">
                            Product Title <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput name="title" id="title" placeholder="Product Title" required />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="organization_name">Category Name</Label>
                          <Select
                            isMulti="true"
                            theme={selectThemeColors}
                            className="react-select"
                            classNamePrefix="select"
                            options={mainCategory}
                            getOptionLabel={(option) => option.category_name}
                            getOptionValue={(option) => option._id}
                            isClearable={false}
                            // defaultValue={details.category}
                            id={"initiatives"}
                            menuPlacement="top"
                            placeholder="Select all that apply"
                            onChange={handlerequestAccess}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="description" className="d-flex align-items-center">
                            Description
                          </Label>
                          <EditorWrapper>
                            <Editor
                              apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3"
                              theme="modern"
                              readOnly={origin == "declined"}
                              init={{
                                height: 250,
                                branding: false,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                ],
                                // toolbar:
                                //   "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview",
                                toolbar:
                                  "bold italic | alignleft aligncenter alignright | bullist numlist",
                                images_upload_url: "/api/page-template/aws",
                                images_file_types: "jpg,jpeg,png,svg,webp",
                                paste_data_images: true,

                                setup: (editor) => {
                                  editor.on("init", () => {
                                    const maxWordCount = 300;
                                    const wordCountWarningThreshold = maxWordCount - 20;

                                    const checkWordCount = () => {
                                      const content = editor.getContent({
                                        format: "text",
                                      });
                                      const words = content.split(/\s+/).filter(Boolean);
                                      if (words.length > maxWordCount) {
                                        // editor.setMode('readonly');
                                        const truncatedContent = words
                                          .slice(0, maxWordCount)
                                          .join(" ");
                                        editor.setContent(truncatedContent);
                                        alert(
                                          "You have reached the maximum word count limit (300 words)."
                                        );
                                      }
                                      // else if (words.length > wordCountWarningThreshold) {
                                      //   alert('You are approaching the maximum word count limit (300 words).');
                                      // }
                                      else {
                                        editor.setMode("design");
                                      }
                                    };
                                    editor.on("input", checkWordCount);
                                    editor.on("paste", () => {
                                      setTimeout(checkWordCount, 0);
                                    });
                                  });
                                },
                              }}
                              // value={details?.description}
                              onEditorChange={(content) => {
                                SetBody(content);
                              }}
                            />
                          </EditorWrapper>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="website-url" className="d-flex align-items-center">
                            Website URL <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            type="url"
                            name="website_url"
                            id="website_url"
                            placeholder="http://test.com"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label for="manufacturer" className="d-flex align-items-center">
                            Vendor <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            name="manufacturer"
                            id="manufacturer"
                            placeholder="Name of Product Vendor"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card className="mb-3">
                          <CardHeader className="bg-white">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Featured Image
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <DropzoneWrapper className="featured-image">
                              <Dropzone
                                onDrop={onDropImage}
                                onDragEnter={() => updateBorder("over", dropImageRef)}
                                onDragLeave={() => updateBorder("leave", dropImageRef)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps({
                                      className: "drop-zone ",
                                    })}
                                    ref={dropImageRef}
                                    style={{ height: "unset" }}
                                  >
                                    <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                                    {!featuredImage ? <div>Drag or Click to Upload</div> : ""}
                                    {featuredImage ? (
                                      <div className="selected-item-wrapper w-100">
                                        <div className="selected-item">
                                          <Button className="remove-selected">
                                            <Trash2 size="14" onClick={removeImage} />
                                          </Button>

                                          <img
                                            src={isPreviewAvailable}
                                            width="100%"
                                            height=""
                                            alt=""
                                          />

                                          {/* {file && (
                                          <div className="file-name mt-1">
                                            <strong>Selected: </strong> {featuredImage.name}
                                          </div>
                                        )} */}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </DropzoneWrapper>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card className="mb-3">
                          <CardHeader className="bg-white d-flex align-items-center justify-content-between">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Product Gallery
                            </h6>
                            <Tooltip isOpen={tooltipOpen} target="add_gallery" toggle={toggle}>
                              Add new image
                            </Tooltip>
                            <AddGalleryButton
                              id="add_gallery"
                              className="p-1"
                              outline
                              color="primary"
                              onClick={() => newProductImage()}
                            >
                              <Plus size="14" />
                            </AddGalleryButton>
                          </CardHeader>
                          <CardBody>
                            <GalleryWrapper>
                              {imageUpload.map((item, i) => {
                                return (
                                  <DropzoneWrapper
                                    className={`position-relative ${
                                      imageUpload[i] ? "image-selected mb-0" : ""
                                    } `}
                                  >
                                    {imageUpload.length > 1 && (
                                      <Button
                                        onClick={(e) => removeProductImageBlock(e, i)}
                                        className="remove-selected"
                                      >
                                        <Trash2
                                          size="14"
                                          onClick={(e) => removeProductImage(e, i)}
                                        />
                                      </Button>
                                    )}

                                    <Dropzone
                                      onDrop={(e) => onDropProductImage(e, i)}
                                      onDragEnter={() => updateBorder("over", dropImageRef)}
                                      onDragLeave={() => updateBorder("leave", dropImageRef)}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps({
                                            className: "drop-zone ",
                                          })}
                                          ref={dropImageRef}
                                          style={{ height: "unset" }}
                                        >
                                          <input
                                            {...getInputProps()}
                                            accept=".jpg,.jpeg,.png,.svg"
                                          />
                                          {!imageUpload[i] ? (
                                            <div>Drag or Click to Upload</div>
                                          ) : (
                                            ""
                                          )}
                                          {imageUpload[i] ? (
                                            <div className="selected-item-wrapper w-100">
                                              <div className="selected-item">
                                                <img
                                                  src={productImagePreview[i]}
                                                  width="100%"
                                                  height=""
                                                  alt=""
                                                />

                                                {/* {file && (
                                          <div className="file-name mt-1">
                                            <strong>Selected: </strong> {featuredImage.name}
                                          </div>
                                        )} */}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                    </Dropzone>
                                  </DropzoneWrapper>
                                );
                              })}
                            </GalleryWrapper>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card>
                          <CardHeader className="bg-white">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Rate this Product
                            </h6>
                          </CardHeader>

                          <CardBody>
                            <FormGroup>
                              <Label className="d-block">
                                Your Overall Rating
                                <span className="text-danger"> *</span>
                              </Label>
                              <Rating
                                initialValue={ratings}
                                size="20"
                                onClick={(e) => onRatingsAndReviewsChange("rating", e)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                Comment<span className="text-danger"> *</span>
                              </Label>
                              <Input
                                type="textarea"
                                rows="5"
                                required
                                value={reviews}
                                onChange={(e) => onRatingsAndReviewsChange("textarea", e)}
                              />
                            </FormGroup>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mt-3 align-items-center">
                      <Col lg="6">
                        <AvGroup check className="mb-3">
                          <AvInput
                            type="checkbox"
                            name="agreedToTerms"
                            // onChange={this.handleAgreeChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "You must agree to the terms and conditions",
                              },
                            }}
                          />
                          I agree to the terms & conditions
                        </AvGroup>
                      </Col>
                      <Col lg="6">
                        <div className="text-right">
                          <PublishButton color="success" size="sm" className="mb-3">
                            Submit for Approval
                          </PublishButton>
                          <CancelButton
                            color="danger"
                            size="sm"
                            className="mb-3 ml-2"
                            onClick={() => routeListChange()}
                          >
                            Discard
                          </CancelButton>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </AvForm>
        </FormWrapper>
      </Container>
    </PageWrapper>
  );
};
export default AddProduct;

export const FormWrapper = styled.section``;

export const DropzoneWrapper = styled.div`
  /* only for featured image  */
  &.featured-image {
    img {
      max-height: 325px;
      object-fit: contain;
    }
  }
  /* /featured image */

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .remove-selected {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  .drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-width: 1px;
    border-radius: 2px;

    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:not(:hover) {
      border-color: #ced4da !important;
    }

    &:hover {
      border: 1px dashed var(--primary) !important;
    }

    & > div:not(.selected-item-wrapper) {
      padding: 20px;
      width: 100%;
      text-align: center;
    }
  }

  div {
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected-item {
    position: relative;

    .remove-selected {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      padding: 2px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 calc(50% - 8px);
  }

  div:not(.image-selected) {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .selected-item {
    position: static;

    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
`;

export const CategoryTree = styled.ul`
  list-style: none;

  li {
    text-transform: capitalize;
  }
  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;

export const PublishButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const CancelButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const AddNewCategory = styled.div``;

export const AddCategoryBtn = styled(Button)`
  border-width: 1px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f3f6ff;
  font-size: 14px;
`;

export const AddNewCategoryWrapper = styled.div`
  font-size: 0.857rem;

  input {
    font-size: 0.857rem;
  }
`;

export const WysiwygEditor = styled.div`
  .demo-editor.rdw-editor-main,
  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .demo-editor.rdw-editor-main {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0px 1rem;
    min-height: 300px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5rem 0px;
  }

  .rdw-editor-wrapper:focus-within {
    border-color: #3365d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 51, 114, 0.25);
    border-radius: 0.25rem;

    .demo-editor.rdw-editor-main,
    .rdw-editor-toolbar {
      border-color: #3365d6;
    }

    .demo-editor.rdw-editor-main {
      border-top-color: #ced4da;
    }
  }
`;

export const AddGalleryButton = styled(Button)`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  border-width: 1.5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditorWrapper = styled.div`
  .tox-tinymce {
    border-radius: 0.25rem;
    border-color: #d8d6de;

    * {
      border-color: #d8d6de !important;
    }
  }

  .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 0 !important;
  }
`;
