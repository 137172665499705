import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Tooltip,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import { AvForm, AvInput, AvGroup, AvField } from "availity-reactstrap-validation-safe";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Trash2, ChevronDown, Plus } from "react-feather";
import Select from "react-select";
import {
  showProductDetails,
  updateProductTemplate,
  createComment,
} from "../../Store/Action/ProductAction.js";
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components.js";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  valSwalSuccess,
  valSwalConfirm,
  valSwalFailure,
  swalalertWithMsg,
} from "../../dev-config/global.js";
import AddProductCategory from "./AddProductCategory.js";
import { listProductCategory } from "../../Store/Action/ProductAction.js";
import UploadFiles from "../../components/upload-files-component/upload-files-component.js";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Rating } from "react-simple-star-rating";

const EditProduct = () => {
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [categoryList, setCategoryList] = useState({});
  const [_id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [short_description, setShortDescription] = useState(null);
  // const [body, SetBody] = useState(EditorState.createEmpty());
  const [body, SetBody] = useState("");
  const [Websiteurl, setWebsiteurl] = useState(null);
  const [phone_number, setPhoneNumber] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  /* *** File upload function start *** */
  /* Feature image */
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
  /* Product gallery */
  const [file, setFile] = useState([]);
  const [newFile, setNewFile] = useState([]);
  const [oldFile, setOldFile] = useState([]);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [categoryinfo, setCategoryInfo] = useState([]);
  const [proCategory, setProCategory] = useState([]);
  const productGallery = [];
  const [imageUpload, setImageUpload] = useState([null]);

  const [productImagePreview, setProductImagePreview] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);

  const dropImageRef = useRef();

  //   const aaa = [
  //     {
  //         "_id": "64de30c47f1b0b001408e597",
  //         "category_name": "EMR",
  //         "parent_id": null,
  //         "slug": "emr",
  //         "__v": 0
  //     }
  // ];

  useEffect(() => {
    dispatch(listProductCategory());
  }, []);

  let listingData;

  useEffect(() => {
    if (id) {
      dispatch(showProductDetails(id));
    }
  }, [dispatch]);

  const store = useSelector((state) => state.products);

  const mainCategory = useSelector((state) => state.products.category);
  useEffect(() => {
    let data = store.details ? store.details : [];

    setId(data?._id);
    setTitle(data?.title);
    setManufacturer(data?.manufacturer);
    setShortDescription(data?.short_description);
    if (data.description != null) {
      const contentDataState = ContentState.createFromBlockArray(convertFromHTML(data.description));
      const editorDataState = EditorState.createWithContent(contentDataState);
      setEditorState(editorDataState);
      SetBody(draftToHtml(convertToRaw(contentDataState)));
    }
    setWebsiteurl(data?.website_url);
    setPhoneNumber(data?.phone_number);
    setProCategory(data?.product_category ? data?.product_category : []);
    setCategoryInfo(data?.product_category ? data?.product_category : []);
    setFeaturedImage(data?.feature_image);
    setIsPreviewAvailable(data?.feature_image);
    setFile(data?.product_gallery);
    setPreviewSrc(data?.product_gallery);
    setProductImagePreview(data.product_gallery);
    setImageUpload(data.product_gallery);

    setRatings(data?.comment?.[0]?.rating);
    setReviews(data?.comment?.[0]?.comment);
    setRatingsId(data?.comment?.[0]?._id);
    console.log(data.category, "CAtegg");
    setDefaultCategory(data.category);
  }, [store]);
  // const details = useSelector((state) => state?.details);
  // const [category, setCategory] = useState(details?.product_category);

  const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "#04629C", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed", // for input hover border-color
    },
  });

  const handlerequestAccess = (e) => {
    // console.log({e})
    let ids = e.map(function (i) {
      return i._id;
    });
    setCategoryInfo(ids);
    // console.log({ids})
    setDefaultCategory(e);
  };

  function categoryListing() {
    listingData = [];
    mainCategory
      .filter((c) => c.parent_id)
      .forEach((c) => {
        const parent = mainCategory.find((p) => p._id === c.parent_id);
        // console.log('asa',parent);
        parent.subCategories = parent.subCategories || [];
        if (parent.subCategories.filter((e) => e._id === c._id).length == 0) {
          parent.subCategories.push(c);
        }
      });

    listingData = mainCategory.filter((c) => !c.parent_id);
  }
  //Handling image
  const onDropImage = (files) => {
    const [uploadedFile] = files;
    setFeaturedImage(uploadedFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setIsPreviewAvailable(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
  };

  const updateBorder = (dragState, reference) => {
    if (dragState === "over") {
      reference.current.style.border = "1px solid #000";
    } else if (dragState === "leave") {
      reference.current.style.border = "1px dashed #046f9c";
    }
  };
  const removeImage = (e) => {
    setFeaturedImage(null);
  };
  const removeProductGallery = (i) => {
    let remove = [...file];
    remove.splice(i, 1);
    console.log("remove", remove);
    setFile(remove);
  };

  const saveNewCategory = () => {
    // Write your codes to save the category
    setAddNewCategory(false);
  };
  const routeListChange = () => {
    // Write your codes to save the category
    history.push("my-product-list");
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    SetBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const getUploadedFiles = (recordSet) => {
    setNewFile(recordSet);
  };
  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCategoryInfo((categoryinfo) => [...categoryinfo, e.target.id]);
    } else {
      let tempCategory = [...categoryinfo];
      const index = tempCategory.indexOf(e.target.id);
      if (index > -1) {
        // only splice array when item is found
        tempCategory.splice(index, 1); // 2nd parameter means remove one item only
      }
      setCategoryInfo(tempCategory);
    }
  };
  const categoryToggle = (value) => {
    setAddNewCategory(value);
  };

  const [ratingsId, setRatingsId] = useState(0);
  const [ratings, setRatings] = useState(0);
  const [reviews, setReviews] = useState("");

  const onRatingsAndReviewsChange = (field, event) => {
    if (field == "rating") {
      setRatings(event);
    } else if (field == "textarea") {
      setReviews(event.target.value);
    }
  };

  // ** Function to handle form submit
  const onSubmit = async (e, errors, values) => {
    // console.log('aaa',imageUpload,productImagePreview);
    // return false;
    var validation = {
      title: "Product Title",
      short_description: "Short Description",
      description: "Description",
      website_url: "Website URL",
      phone_number: "Phone Number",
      manufacturer: "Product Manufacturer",
    };
    if (errors.length > 0) {
      valSwalFailure("Error!", "Please enter " + validation[errors[0]]);
      return false;
    } else if (categoryinfo.length == 0) {
      valSwalFailure("Error!", "Please Select the Product Category");
      return false;
    } else if (featuredImage == null) {
      valSwalFailure("Error!", "Please Choose the Featured Image");
      return false;
    } else if (imageUpload.indexOf(null) > -1) {
      valSwalFailure("Error!", "Please Choose the Product Gallery");
      return false;
    } else if (ratings == 0) {
      valSwalFailure("Error!", "Product rating is required");
      return false;
    } else if (reviews == "") {
      valSwalFailure("Error!", "Product review is required");
      return false;
    } else {
      //swalalertWithMsg(true,'please wait!')
      let payload = values;
      payload._id = values._id;
      payload.body = body;
      payload.parent_id = categoryinfo;
      payload.user_id = user.sub.replace("auth0|", "");
      var formDataValue = new FormData();
      for (var key in payload) {
        formDataValue.append(key, payload[key] ? payload[key] : "");
      }
      formDataValue.append("feature_image", featuredImage);

      formDataValue.append("ratings", ratings);
      formDataValue.append("comment", reviews);
      formDataValue.append("ratingsId", ratingsId);

      let old_gallery = [];
      for (let i = 0; i < imageUpload.length; i++) {
        // console.log('aaaaaa',typeof imageUpload[i] )
        if (typeof imageUpload[i] == "object") {
          formDataValue.append("product_gallery", imageUpload[i][0]);
        } else {
          old_gallery.push(imageUpload[i]);
        }
        // formDataValue.append("product_gallery", newFile[i]);
      }

      // for (let i = 0; i < newFile.length; i++) {
      //   formDataValue.append("product_gallery", newFile[i]);
      // }
      formDataValue.append("old_product_gallery", old_gallery);
      swalalertWithMsg(true, "Please Wait!");
      dispatch(updateProductTemplate(formDataValue))
        .then((res) => {
          history.push("my-product-list");
          valSwalSuccess("Success", "Product Updated Successfully!");
        })
        .catch((err) => {
          valSwalFailure("Error", "Something went wrong please try again");
        });
    }
  };

  const productImg = () => {
    if (file) {
      return file.map((value, i) => {
        return (
          <div>
            <Trash2
              className="remove-selected"
              color="red"
              size="18"
              onClick={() => removeProductGallery(i)}
            />
            <img src={value} width="100%" height="100%" />
          </div>
        );
      });
    }
  };

  function categoryListTree() {
    categoryListing();
    return listingData.map((category, i) => {
      let subcategoryList = listSubCategory(
        category.subCategories != undefined ? category.subCategories : []
      );
      return (
        <li>
          <AvInput
            onChange={handleChange}
            name="parent_id"
            type="checkbox"
            checked={proCategory.indexOf(category._id) > -1}
            id={category._id}
          />
          <Label for="1" className="mb-0">
            {category.category_name}
          </Label>
          {subcategoryList}
        </li>
      );
    });
  }

  const onDropProductImage = (files, i) => {
    const [uploadedFile] = files;
    const img_upload = [...imageUpload];
    img_upload[i] = files;
    setImageUpload(img_upload);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const img = [...productImagePreview];
      img[i] = fileReader.result;
      setProductImagePreview(img);
    };
    fileReader.readAsDataURL(uploadedFile);
  };

  const newProductImage = () => {
    const img_upload = [...imageUpload];
    img_upload[img_upload.length] = null;
    setImageUpload(img_upload);
  };

  const removeProductImage = (e, i) => {
    // setFeaturedImage(null);
    const img_upload = [...imageUpload];
    img_upload[i] = null;
    setImageUpload(img_upload);
    const img = [...productImagePreview];
    img[i] = "";
    setProductImagePreview(img);
    e.preventDefault();
  };
  const removeProductImageBlock = (e, i) => {
    // setFeaturedImage(null);
    const img_upload = [...imageUpload];
    img_upload.splice(i, 1);
    setImageUpload(img_upload);
    const img = [...productImagePreview];
    img.splice(i, 1);
    setProductImagePreview(img);
    e.preventDefault();
  };

  function listSubCategory(child) {
    if (child != []) {
      return child.map((category, i) => {
        let subcategoryList = listSubCategory(
          category.subCategories != undefined ? category.subCategories : []
        );
        return (
          <ul className="pl-4">
            <li>
              <AvInput
                name="subcategory_id"
                onChange={handleChange}
                type="checkbox"
                checked={proCategory.indexOf(category._id) > -1}
                id={category._id}
              />
              <Label for="1" className="mb-0">
                {category.category_name}
              </Label>
              {subcategoryList}
            </li>
          </ul>
        );
      });
    }
  }

  return (
    <PageWrapper id="page-wrapper" className="pb-6">
      <TitleWrapper className="pt-6 mb-6">
        <Container>
          <h3 className="text-white">Edit product</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="my-product-list">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Edit Product</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </TitleWrapper>

      <Container>
        <FormWrapper>
          <AvForm onSubmit={onSubmit}>
            <Row>
              <Col lg="12" xl="12">
                <Card style={{ position: "sticky", top: "100px" }}>
                  <CardBody className="pb-1">
                    <Row>
                      <AvInput type="hidden" id="_id" name="_id" placeholder="Name" value={_id} />
                      <Col lg="12">
                        <FormGroup>
                          <Label for="title" className="d-flex align-items-center">
                            Product Title <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            name="title"
                            id="title"
                            value={title}
                            placeholder="Product Title"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="organization_name">Category Name </Label>
                          {/* {JSON.stringify(defaultCategory)+" ---------------- " +
                          JSON.stringify(mainCategory)
                          } */}
                          <Select
                            isMulti="true"
                            theme={selectThemeColors}
                            className="react-select"
                            classNamePrefix="select"
                            options={mainCategory}
                            getOptionLabel={(option) => option.category_name}
                            getOptionValue={(option) => option._id}
                            isClearable={false}
                            value={defaultCategory}
                            // defaultValue={data?.category?.map(categoryId => mainCategory.find(option => option._id === categoryId))}

                            id={"initiatives"}
                            menuPlacement="top"
                            placeholder="Select all that apply"
                            onChange={handlerequestAccess}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="description" className="d-flex align-items-center">
                            Description
                          </Label>
                          <EditorWrapper>
                            <Editor
                              apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3"
                              theme="modern"
                              readOnly={origin == "declined"}
                              init={{
                                height: 250,
                                // width: 1100,
                                branding: false,
                                menubar: false,
                                // statusbar: false,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                ],
                                // toolbar:
                                //   "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview",
                                toolbar:
                                  "bold italic | alignleft aligncenter alignright | bullist numlist",
                                images_upload_url: "/api/page-template/aws",
                                images_file_types: "jpg,jpeg,png,svg,webp",
                                paste_data_images: true,

                                setup: (editor) => {
                                  editor.on("init", () => {
                                    const maxWordCount = 300;
                                    const wordCountWarningThreshold = maxWordCount - 20;

                                    const checkWordCount = () => {
                                      const content = editor.getContent({
                                        format: "text",
                                      });
                                      const words = content.split(/\s+/).filter(Boolean);
                                      if (words.length > maxWordCount) {
                                        // editor.setMode('readonly');
                                        const truncatedContent = words
                                          .slice(0, maxWordCount)
                                          .join(" ");
                                        editor.setContent(truncatedContent);
                                        alert(
                                          "You have reached the maximum word count limit (300 words)."
                                        );
                                      }
                                      // else if (words.length > wordCountWarningThreshold) {
                                      //   alert('You are approaching the maximum word count limit (300 words).');
                                      // }
                                      else {
                                        editor.setMode("design");
                                      }
                                    };
                                    editor.on("input", checkWordCount);
                                    editor.on("paste", () => {
                                      setTimeout(checkWordCount, 0);
                                    });
                                  });
                                },
                              }}
                              value={body}
                              onEditorChange={(content) => {
                                SetBody(content);
                              }}
                            />
                          </EditorWrapper>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="website-url" className="d-flex align-items-center">
                            Website URL
                          </Label>
                          <AvInput
                            name="website_url"
                            id="website_url"
                            value={Websiteurl}
                            placeholder="Website URL"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label for="manufacturer" className="d-flex align-items-center">
                            Vendor
                          </Label>
                          <AvInput
                            name="manufacturer"
                            id="manufacturer"
                            value={manufacturer}
                            placeholder="Name of Product Vendor"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card className="mb-3">
                          <CardHeader className="bg-white">
                            <AvInput
                              type="hidden"
                              id="old_feature_image"
                              name="old_feature_image"
                              value={featuredImage}
                            />
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Featured Image
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <DropzoneWrapper className="featured-image">
                              <Dropzone
                                onDrop={onDropImage}
                                onDragEnter={() => updateBorder("over", dropImageRef)}
                                onDragLeave={() => updateBorder("leave", dropImageRef)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps({
                                      className: "drop-zone ",
                                    })}
                                    ref={dropImageRef}
                                    style={{ height: "unset" }}
                                  >
                                    <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                                    {!featuredImage ? <div>Drag or Click to Upload</div> : ""}
                                    {featuredImage ? (
                                      <div className="selected-item-wrapper w-100">
                                        <div className="selected-item">
                                          <Button className="remove-selected">
                                            <Trash2 size="18" onClick={removeImage} />
                                          </Button>
                                          <img src={isPreviewAvailable} width="100%" alt="" />{" "}
                                          {/* {file && (
                                      <div className="file-name mt-1">
                                        <strong>Selected: </strong> {featuredImage.name}
                                      </div>
                                    )} */}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </DropzoneWrapper>
                            {/*<StyledDropzone />*/}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card className="mb-3">
                          <CardHeader className="bg-white d-flex align-items-center justify-content-between">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Product Gallery
                            </h6>
                            <Tooltip isOpen={tooltipOpen} target="add_gallery" toggle={toggle}>
                              Add new image
                            </Tooltip>
                            <AddGalleryButton
                              id="add_gallery"
                              className="p-1"
                              outline
                              color="primary"
                              onClick={() => newProductImage()}
                            >
                              <Plus size="14" />
                            </AddGalleryButton>
                          </CardHeader>
                          <CardBody>
                            <GalleryWrapper>
                              {imageUpload != undefined &&
                                imageUpload.map((item, i) => {
                                  return (
                                    <DropzoneWrapper
                                      className={`position-relative ${
                                        imageUpload[i] ? "image-selected mb-0" : ""
                                      } `}
                                    >
                                      {imageUpload.length > 1 && (
                                        <Button
                                          onClick={(e) => removeProductImageBlock(e, i)}
                                          className="remove-selected"
                                        >
                                          <Trash2
                                            size="14"
                                            onClick={(e) => removeProductImage(e, i)}
                                          />
                                        </Button>
                                      )}
                                      <Dropzone
                                        onDrop={(e) => onDropProductImage(e, i)}
                                        onDragEnter={() => updateBorder("over", dropImageRef)}
                                        onDragLeave={() => updateBorder("leave", dropImageRef)}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div
                                            {...getRootProps({
                                              className: "drop-zone ",
                                            })}
                                            ref={dropImageRef}
                                            style={{ height: "unset" }}
                                          >
                                            <input
                                              {...getInputProps()}
                                              accept=".jpg,.jpeg,.png,.svg"
                                            />
                                            {!imageUpload[i] ? (
                                              <div>Drag or Click to Upload</div>
                                            ) : (
                                              ""
                                            )}
                                            {imageUpload[i] ? (
                                              <div className="selected-item-wrapper w-100">
                                                <div className="selected-item">
                                                  <img
                                                    src={productImagePreview[i]}
                                                    width="100%"
                                                    height=""
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </Dropzone>
                                    </DropzoneWrapper>
                                  );
                                })}
                            </GalleryWrapper>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card>
                          <CardHeader className="bg-white">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Rate this Product
                            </h6>
                          </CardHeader>

                          <CardBody>
                            <FormGroup>
                              <Label className="d-block">
                                Your Overall Rating
                                <span className="text-danger"> *</span>
                              </Label>
                              <Rating
                                initialValue={ratings}
                                size="20"
                                onClick={(e) => onRatingsAndReviewsChange("rating", e)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                Comment<span className="text-danger"> *</span>
                              </Label>
                              <Input
                                type="textarea"
                                rows="5"
                                required
                                value={reviews}
                                onChange={(e) => onRatingsAndReviewsChange("textarea", e)}
                              />
                            </FormGroup>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mt-3 align-items-center">
                      <Col md="6">
                        <AvGroup check className="mb-3">
                          <AvInput
                            type="checkbox"
                            name="agreedToTerms"
                            // onChange={this.handleAgreeChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "You must agree to the terms and conditions",
                              },
                            }}
                          />
                          I agree to the terms & conditions
                        </AvGroup>
                      </Col>
                      <Col md="6">
                        <div className="text-right">
                          <PublishButton color="success" size="sm" className="mb-3">
                            Update
                          </PublishButton>
                          <CancelButton
                            color="danger"
                            size="sm"
                            className="mb-3 ml-2"
                            onClick={() => routeListChange()}
                          >
                            Discard
                          </CancelButton>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </AvForm>
        </FormWrapper>
      </Container>
    </PageWrapper>
  );
};
export default EditProduct;

export const FormWrapper = styled.section`
  .react-select {
    .select__multi-value__label {
      color: #fff;
    }
  }
`;

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#ced4da";
};

export const DropzoneWrapper = styled.div`
  display: block;

  /* only for featured image  */
  &.featured-image {
    img {
      max-height: 325px;
      object-fit: contain;
    }
  }
  /* /featured image */

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .remove-selected {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  .drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-width: 1px;
    border-radius: 2px;

    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:not(:hover) {
      border-color: #ced4da !important;
    }

    &:hover {
      border: 1px dashed var(--primary) !important;
    }

    & > div:not(.selected-item-wrapper) {
      padding: 20px;
      width: 100%;
      text-align: center;
    }
  }

  div {
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected-item {
    position: relative;

    .remove-selected {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      padding: 2px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 calc(50% - 8px);
  }

  div:not(.image-selected) {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .selected-item {
    position: static;

    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
`;

export const CategoryTree = styled.ul`
  list-style: none;
  li {
    text-transform: capitalize;
  }

  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;

export const PublishButton = styled(Button)`
  font-size: 1rem;
`;

export const CancelButton = styled(Button)`
  font-size: 1rem;
`;

export const AddNewCategory = styled.div``;

export const AddCategoryBtn = styled(Button)`
  border-width: 1px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f3f6ff;
  font-size: 14px;
`;

export const AddNewCategoryWrapper = styled.div`
  font-size: 0.857rem;

  input {
    font-size: 0.857rem;
  }
`;

export const WysiwygEditor = styled.div`
  .demo-editor.rdw-editor-main,
  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .demo-editor.rdw-editor-main {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0px 1rem;
    min-height: 300px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5rem 0px;
  }

  .rdw-editor-wrapper:focus-within {
    border-color: #3365d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 51, 114, 0.25);
    border-radius: 0.25rem;

    .demo-editor.rdw-editor-main,
    .rdw-editor-toolbar {
      border-color: #3365d6;
    }

    .demo-editor.rdw-editor-main {
      border-top-color: #ced4da;
    }
  }
`;

export const AddGalleryButton = styled(Button)`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  border-width: 1.5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditorWrapper = styled.div`
  .tox-tinymce {
    border-radius: 0.25rem;
    border-color: #d8d6de;

    * {
      border-color: #d8d6de !important;
    }
  }

  .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 0 !important;
  }
`;
