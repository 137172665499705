import React from "react";
import { Download, ExternalLink } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import parse from "html-react-parser";
// images
import resourceIcon from "../../../../assets/images/resource-icon.svg";
import placeholderImage from "../../../../assets/images/resource-placeholder.jpg";

const RecommendedResources = (props) => {
  const { post, isAuthenticated, relatedPosts, publicView } = props;
  console.log(relatedPosts);
  return (
    <PageTemplate id="page-wrapper" className="py-3 py-lg-6">
      <Container>
        <div className="mb-5 content-wrapper">
          {post?.video_url ? (
            <div className="video-wrapper mb-5">
              <video
                poster={post?.video_thumbnail_url}
                src={post?.video_url}
                className="rounded overflow-hidden"
                controls
                muted
                width={"100%"}
              />
            </div>
          ) : (
            ""
          )}
          <Row className="title-container">
            <Col md="12" lg="8" xl="9" className="title-warpper">
              <h1 className="mb-0">{post.title}</h1>
              <hr />
              <div class="meta d-flex align-items-center flex-wrap">
                {post?.date ? (
                  <div className="date mr-3">
                    {new Date(post.date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </div>
                ) : (
                  ""
                )}
                <div className="category">
                  <Link to={"/" + post?.categories_slugs?.[0]?.slug} className="sub-category">
                    {post?.categories_slugs?.[0]?.name}
                  </Link>
                  {" | "}
                  <Link
                    to={
                      "/" +
                      post?.categories_slugs?.[0]?.slug +
                      "/" +
                      post?.categories_slugs?.[1]?.slug
                    }
                    className="sub-category"
                  >
                    {" "}
                    {post?.categories_slugs?.[1]?.name}{" "}
                  </Link>
                  {""}
                </div>
              </div>
              <hr />
            </Col>
            {post?.file_downloadable ? (
              <Col sm="6" md="5" lg="4" xl="3" className="mt-3 mt-lg-0">
                <a href={post.file_downloadable} download target={"_blank"}>
                  <Button
                    color="secondary"
                    size=""
                    block
                    tag="a"
                    download
                    outline
                    className="mb-40 d-flex align-items-center justify-content-center"
                  >
                    DOWNLOAD <Download size="13" className="ml-1 stroke-width-3px" />
                  </Button>
                </a>
              </Col>
            ) : (
              ""
            )}
          </Row>
          {/* <div className="content mt-5">{parse(post.short_description)}</div> */}
          {/* <hr /> */}
          <div className="content mt-5">{parse(post.body)}</div>
        </div>
        <SidebarWrapper>
          <div className="recommended-resources rounded">
            <h3 className="mb-3">Related</h3>
            {relatedPosts?.map((related_post, index) => {
              if (index > 2) {
                return;
              }
              const postUrl =
                "/" +
                related_post?.categories_slugs?.[0]?.slug +
                "/" +
                related_post?.categories_slugs?.[1]?.slug +
                "/" +
                related_post.slug;
              const categoryUrl = "/" + related_post?.categories_slugs?.[0]?.slug;
              const subCategoryUrl =
                "/" +
                related_post?.categories_slugs?.[0]?.slug +
                "/" +
                related_post?.categories_slugs?.[1]?.slug;
              return (
                <div className="resource-item d-flex align-items-center flex-wrap">
                  <img src={resourceIcon} alt="icon" className="icon mr-4" />

                  <div className="resource-details mr-4">
                    <h5>
                      <Link to={postUrl} className="text-secondary">
                        {related_post.title}
                      </Link>
                    </h5>
                    <p className="excerpt">{parse(related_post.short_description)}</p>
                    <div class="meta d-flex align-items-center flex-wrap" style={{ gap: "20px" }}>
                      <Link to={categoryUrl} className="category">
                        {related_post?.categories_slugs?.[0]?.name}
                      </Link>{" "}
                      <Link to={subCategoryUrl} className="sub-category">
                        {" "}
                        {related_post?.categories_slugs?.[1]?.name}{" "}
                      </Link>
                      {""}
                      <div className="date">
                        {related_post?.date
                          ? new Date(related_post.date).toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                          : ""}
                      </div>
                      <Link to={postUrl} class="meta d-flex align-items-center">
                        <ExternalLink size={"14"} className="link-icons" />
                      </Link>
                      <a
                        href={related_post?.file_downloadable}
                        target={"_blank"}
                        class="meta d-flex align-items-center"
                        download
                      >
                        <Download size={"14"} className="link-icons" />
                      </a>
                    </div>
                  </div>

                  <div className="featured-image">
                    <img
                      src={
                        related_post?.feature_image
                          ? related_post?.feature_image
                          : "https://assets.mycoa.io/1712147060813_Group%20656.png"
                      }
                      // onError={(e) =>
                      //   (e.target.onerror = null)(
                      //     (e.target.src =
                      //       "https://www.fastcat.com.ph/wp-content/uploads/2016/04/dummy-post-horisontal-300x171.jpg")
                      //   )
                      // }
                      alt="featured image"
                      className="w-100 rounded"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </SidebarWrapper>
      </Container>
    </PageTemplate>
  );
};

export default RecommendedResources;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);

  hr {
    color: #d1d3d4;
  }

  .content {
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }
`;

export const SidebarWrapper = styled.aside`
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px 40px 40px 40px;

    svg {
      stroke-width: 2.6px;
    }

    .resource-item {
      &:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #d1d3d4;
      }
      .icon {
        flex: 0 0 100px;
      }

      .resource-details {
        flex: 2;

        .category,
        .sub-category,
        .date {
          color: #4180be;
          font-family: var(--primaryFont);
          font-size: 12px;
          line-height: 18px;
          font-weight: 800;
          letter-spacing: 0.75px;
        }

        .excerpt {
          color: #101c3b;
        }
        .link-icons {
          color: var(--secondary);
        }

        .meta {
          .category,
          .sub-category {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
              width: 2px;
              height: 12px;
              background-color: var(--link);
            }
          }
        }
      }

      .featured-image {
        flex: 0 0 200px;
      }

      @media (max-width: 860px) {
        flex-direction: column-reverse;
        .icon {
          display: none;
        }

        .resource-details {
          margin-right: 0 !important;
        }
        .featured-image {
          flex: 1 1 100% !important;
          width: 100%;
          margin-bottom: 20px;
          /* margin-top: 15px; */
        }
      }
    }
  }

  h6 {
    margin-bottom: 30px;
  }
`;
