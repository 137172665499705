import React, { useEffect } from "react";

// images

import rightImage from "../../assets/images/patient-first.jpg";
import leftImage from "../../assets/images/cutting-edge.jpg";
import featuredImage from "../../assets/images/about-image.jpg";
import pattern1 from "../../assets/images/pattern1.svg";
import pattern2 from "../../assets/images/pattern2.svg";
import affordable from "../../assets/images/affordable.svg";
import highquality from "../../assets/images/highquality.svg";
import cnovenient from "../../assets/images/convenient.svg";
import personal from "../../assets/images/personal.svg";
import { CardSection, HeroSection, TwoColSection, VideoSection } from "./what-is-coa-styles";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import emblem from "../../assets/images/coa.svg";
import { ExternalLink } from "react-feather";
// import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <>
      {/* <Helmet>
        <meta property="og:title" content="Your OG Title" />
        <meta property="og:description" content="Your OG Description" />
      </Helmet> */}
      <div className="page-wrapper ">
        <HeroSection className="mt-6 mb-6">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <img src={featuredImage} alt="featured image" width={"100%"} />
              </Col>

              <Col lg="6">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center">
                  <img
                    src={emblem}
                    alt="emblem"
                    className="d-inline-block mr-3 mb-2 mb-sm-3"
                    width={"100px"}
                  />
                  <h3>
                    Where you are <span className="text-secondary">treated</span> makes a
                    difference!{" "}
                  </h3>
                </div>

                <p>
                  People with cancer have many options when it comes to their treatment, but at
                  Community Oncology, we put patients first.
                </p>
                <p>
                  Community Oncology practices are where the majority of people with cancer receive
                  their care. There are more than{" "}
                  <strong>5,000 community oncologists spanning 950 practices</strong> across the
                  United States who are dedicated to giving the best care to their patients.
                  Community Oncology is distinguished from cancer care delivered in other settings,
                  like academic medical centers and for-profit treatment centers, because of four
                  key factors: personable, convenience, excellence and affordability.{" "}
                </p>
              </Col>
            </Row>
          </Container>
        </HeroSection>

        <CardSection className="pt-5" bgImage={pattern1}>
          <Container>
            <div className="card-warp mb-6" style={{ gap: "30px" }}>
              <Card>
                <img src={personal} alt="personal" />
                <CardBody>
                  <h4 className="text-center">Personal</h4>
                  <hr />
                  <p className="mb-0">
                    Community oncologists and members of the care management team take the time to
                    get to know their patients and their unique needs, which positively impacts
                    treatment.{" "}
                  </p>
                </CardBody>
              </Card>

              <Card>
                <img src={cnovenient} alt="cnovenient" />
                <CardBody>
                  <h4 className="text-center">Convenient</h4>
                  <hr />
                  <p className="mb-0">
                    These clinics are located in the communities where patients live and work,
                    making it easier to balance cancer treatment with personal and professional
                    obligations.
                  </p>
                </CardBody>
              </Card>

              <Card>
                <img src={highquality} alt="highquality" />
                <CardBody>
                  <h4 className="text-center">High quality & cutting edge</h4>
                  <hr />
                  <p className="mb-0">
                    In recent years, the majority of new cancer drugs approved for use in the United
                    States were studied in clinical trials in community oncology practices.
                  </p>
                </CardBody>
              </Card>

              <Card>
                <img src={affordable} alt="affordable" />
                <CardBody>
                  <h4 className="text-center">Affordable</h4>
                  <hr />
                  <p className="mb-0">
                    Cancer treatment performed in a hospital setting can cost up to 50 percent more
                    than an identical treatment in a community oncology setting, according to
                    research conducted by the Moran Company.
                  </p>
                </CardBody>
              </Card>
            </div>

            <div className="video-wrapper">
              <video
                src="https://mediacdn.cincopa.com/v2/845732/235!FSsDAAAAAAATDD/0/4-CommunityOncologyisPersonal-CLEAN.mp4"
                className="rounded overflow-hidden"
                controls
                muted
                width={"100%"}
              />
            </div>
          </Container>
        </CardSection>

        <TwoColSection className="mb-6 mt-6">
          <Container>
            <Row className="align-items-center">
              <Col lg="6" className="mb-4 mb-lg-0">
                <h3>We Put Patients First</h3>
                <p>
                  Because community oncology practices are independently owned by the doctors
                  providing treatment, the mission of patient-centered care drives every aspect of
                  practice operations. The doctors, nurses, and administrative staff of independent
                  community oncology clinics take time to get to know each patient and understand
                  their unique needs and diagnosis so treatments can be customized. In community
                  oncology, a patient never becomes a “number.”
                </p>
                <p className="mb-0">
                  Additionally, many community oncology clinics maintain on-site pharmacies, making
                  it easier and more convenient to start treatment promptly and under appropriate
                  medical supervision. Cancer takes a significant emotional toll on patients and
                  families, so every minute it takes to travel to appointments is less time with
                  family or at work. Having the option of receiving treatment close to home can ease
                  the burden.
                </p>
              </Col>
              <Col lg="6">
                <img src={rightImage} alt="image" width={"100%"} />
              </Col>
            </Row>
          </Container>
        </TwoColSection>

        <TwoColSection className="mb-6">
          <Container>
            <Row className="align-items-center flex-column-reverse flex-lg-row ">
              <Col lg="6">
                <img src={leftImage} alt="image" width={"100%"} />
              </Col>

              <Col lg="6" className="mb-2 mb-lg-0">
                <h3 className="mr-lg-6">We Offer Affordable & Cutting Edge Care</h3>
                <p>
                  Community oncology clinics deliver high quality care often at a fraction of the
                  cost of care delivered in other settings. In fact, treatment at a hospital chain
                  or academic medical center can cost up to 50 percent more than care provided in a
                  community oncology clinic. A 2014 study from the IMS Institute for Healthcare
                  Informatics found that the average cost for 10 common oncology drugs was $134
                  higher per dose when administered in a hospital.
                </p>
              </Col>
            </Row>
          </Container>
        </TwoColSection>

        <VideoSection bgImage={pattern2}>
          <Container>
            <div className="video-wrapper pb-6">
              <video
                src="https://mediacdn.cincopa.com/v2/845732/236!FSsDAAAAAAw6BD/0/21-Animated-WhatisCommunityOncology-AnimatedVideo-Clean.mov"
                className="rounded overflow-hidden"
                controls
                muted
                width={"100%"}
              />
            </div>
          </Container>
        </VideoSection>
      </div>
    </>
  );
};

export default About;
