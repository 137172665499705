import React from "react";
import { CornerDownLeft } from "react-feather";
import { Button } from "reactstrap";
import styled from "styled-components";
import { Rating } from "react-simple-star-rating";

const ReviewItem = ({ id, rating, img, date, name,comment, handleReviewReply }) => {
  return (
    <ReviewItemWrapper className="d-flex align-items-start">
      <div className="avatar ">
        <img src={img} alt="" />
      </div>
      <div className="meta">
        <div className="name d-flex align-items-start justify-content-between flex-wrap">
          <div>
            <h4>{name}</h4>
            <small>{date}</small>
          </div>
          <Rating initialValue={rating} size="20" className="mr-2" readonly />
        </div>
        <div className="">
          <p className="review mb-1">
           {comment}
          </p>

          <div className="text-right">
            <ReplyButton
              tag="a"
              href="#review"
              color="primary"
              size="sm"
              onClick={() => handleReviewReply(id, name)}
            >
              Reply <CornerDownLeft size="15" />
            </ReplyButton>
          </div>
        </div>
      </div>
    </ReviewItemWrapper>
  );
};

export default ReviewItem;

export const ReviewItemWrapper = styled.div`
  gap: 20px;
  .avatar {
    width: 70px;
    height: 70px;
    min-width: 70px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 100%;
      border: 1px solid #e3e3e3;
      padding: 5px;
    }
  }

  .meta {
    .name {
      margin-bottom: 0.5rem;
      h4 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .review {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ReplyButton = styled(Button)`
  padding: 3px 10px;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 12px;
`;
