import { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronRight } from "react-feather";

import Dropdown from "./Dropdown";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 1200 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 1200 && setDropdown(false);
  };

  const handleMobileDropdown = (e) => {
    let parentMenuItem = e.target.closest(".menu-items");
    parentMenuItem.childNodes[1].classList.toggle("show");
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <div className="d-flex  justify-content-between">
            <a
              href={items.link}
              // aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              target={items.target}
            >
              {items.title}{" "}
            </a>
            <span
              //   onClick={() => setDropdown((prev) => !prev)}
              onClick={(e) => handleMobileDropdown(e)}
              className="mobile-arrow align-self-center"
              aria-expanded={dropdown ? "true" : "false"}
            >
              {depthLevel > 0 ? (
                <ChevronRight size="14" />
              ) : (
                <ChevronDown size="16" />
              )}
            </span>
          </div>

          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <a href={items.link} target={items.target}>
          {items.title}
        </a>
      )}
    </li>
  );
};

export default MenuItems;
