import React, { Component } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.state = {
      selectedFiles: undefined,
      progressInfos: [],
      message: [],
      fileInfos: [],
    };
  }

  componentDidMount() {}

  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];
  }

  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;
    this.props.getUploadedFiles(selectedFiles);
    return false;
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
      const selectedFiles = this.state.selectedFiles;
      this.props.getUploadedFiles(selectedFiles);
    }
  }

  updateBorder = (dragState, reference) => {
    if (dragState === "over") {
      reference.current.style.border = "1px solid #000";
    } else if (dragState === "leave") {
      reference.current.style.border = "1px dashed #046f9c";
    }
  };

  render() {
    const { selectedFiles, progressInfos, message, fileInfos } = this.state;

    return (
      <div>
        {progressInfos &&
          progressInfos.map((progressInfo, index) => (
            <div className="mb-2" key={index}>
              <span>{progressInfo.fileName}</span>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progressInfo.percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progressInfo.percentage + "%" }}
                >
                  {progressInfo.percentage}%
                </div>
              </div>
            </div>
          ))}

        <DropzoneWrapper>
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: "drop-zone ",
                })}
              >
                <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                {selectedFiles && Array.isArray(selectedFiles) && selectedFiles.length ? (
                  <div className="selected-file">
                    {selectedFiles.length > 3
                      ? `${selectedFiles.length} files`
                      : selectedFiles.map((file) => file.name).join(", ")}
                  </div>
                ) : (
                  `Drag and drop files here`
                )}
              </div>
            )}
          </Dropzone>
        </DropzoneWrapper>

        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}

        {fileInfos.length > 0 && (
          <div className="card">
            <div className="card-header">List of Files</div>
            <ul className="list-group list-group-flush">
              {fileInfos &&
                fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <a href={file.url}>{file.name}</a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export const DropzoneWrapper = styled.div`
  .drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 1px;
    border-radius: 2px;

    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:not(:hover) {
      border-color: #ced4da !important;
    }
  }

  div {
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected-item {
    position: relative;

    .remove-selected {
      position: absolute;
      right: 0;
      cursor: pointer;
      padding: 3px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
