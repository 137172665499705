import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Tooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { AvForm, AvInput, AvGroup, AvField } from "availity-reactstrap-validation-safe";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Trash2 } from "react-feather";
import Select from "react-select";
import {
  createJobTemplate,
  specializationsList,
  showJobDetails,
  updateJobTemplate,
} from "../../Store/Action/JobAction.js";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components.js";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  valSwalSuccess,
  valSwalConfirm,
  valSwalFailure,
  swalalertWithMsg,
  getAllStateByCountry,
  getAllCityByCountryState,
} from "../../dev-config/global.js";
// import AddProductCategory from "./AddProductCategory.js";
import { Editor } from "@tinymce/tinymce-react";
import { Rating } from "react-simple-star-rating";
import InputMask from "react-input-mask";
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Location from "./location-autocomplete";
const AddJob = () => {
  const { id } = useParams();
  var action = id ? "Update" : "Submit";

  const { user, getAccessTokenSilently } = useAuth0();
  const [validationMessages, setValidationMessages] = useState(null);
  // const [addNewCategory, setAddNewCategory] = useState(false);
  const [cityOption, setCityOption] = useState([]);
  const [stateOption, setStateOption] = useState(getAllStateByCountry("manipulate"));
  const [buyerData, setBuyerData] = useState({});
  const [billingFormData, setBillingFormData] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showAddress, setShowAddress] = useState(0);
  const [body, SetBody] = useState(null);
  const [companyDescription, SetCompanyDescription] = useState(null);
  const [minExperience, setMinExperience] = useState(null);
  const [maxExperience, setMaxExperience] = useState(null);
  const [imageUpload, setImageUpload] = useState([null]);
  const [jobImagePreview, setJobImagePreview] = useState([]);
  const shouldRedirect = true;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // const newImage = {}
  const history = useHistory();
  useEffect(() => {
    dispatch(specializationsList());
    if (id) {
      dispatch(showJobDetails(id));
    }
  }, []);
  const specializations = useSelector((state) => state.jobs?.specializations);
  const jobDetails = useSelector((state) => state.jobs?.jobDetails);
  const [specializationInfo, setSpecializationInfo] = useState([]);
  const [defaultSpecialization, setDefaultSpecialization] = useState([]);

  // const [category, setCategory] = useState();

  useEffect(() => {
    if (jobDetails) {
      SetBody(jobDetails?.description ? jobDetails?.description : "");
      SetCompanyDescription(jobDetails?.company_description ? jobDetails?.company_description : "");
      setSpecializationInfo(jobDetails?.specializations ? jobDetails?.specializations : []);
      setMinExperience(jobDetails?.min_experience);
      setMaxExperience(jobDetails?.max_experience);
      setFeaturedImage(jobDetails?.company_logo);
      setIsPreviewAvailable(jobDetails?.company_logo);
      setDefaultSpecialization(jobDetails?.job_specializations);
      setShowAddress(true);
    }
  }, [jobDetails]);

  // ** React Select Theme Colors
  const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "#04629C", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed", // for input hover border-color
    },
  });

  const setAddress = (AddressObj) => {
    var data = buyerData;
    data["address1"] = AddressObj.route
      ? AddressObj.route
      : "" + " " + AddressObj.sublocality_level_1
      ? AddressObj.sublocality_level_1
      : "" + " " + AddressObj.sublocality_level_2
      ? AddressObj.sublocality_level_2
      : "";

    data["country"] = AddressObj.country;
    data["city"] = AddressObj.locality;
    data["state"] = AddressObj.administrative_area_level_1;
    data["zip_code"] = AddressObj.postal_code;
    data["lat"] = AddressObj.lat;
    data["lng"] = AddressObj.lng;

    setBuyerData((prevState) => {
      return { ...jobDetails, ...data };
    });
    //console.log('buyerData',buyerData);return false;
    setShowAddress(true);
  };

  const clearsearch = () => {
    var data = buyerData;
    data["address1"] = "";
    data["country"] = "";
    data["city"] = "";
    data["state"] = "";
    data["zip_code"] = "";
    data["lat"] = "";
    data["lng"] = "";

    setBuyerData((prevState) => {
      return { ...prevState, ...data };
    });
    setShowAddress(false);
  };

  const numbers = [];
  for (let i = 0; i <= 10; i++) {
    numbers.push(i);
  }

  const jobtitle = [
    "Medical Oncologist/Hematologist",
    "BC/BE Hematology/Oncology Physicians",
    "Board Certified/Board Eligible Hematologist/Oncologist",
    "Medical Oncologist",
    "Physicians - Medical Oncologist/Hematologist",
  ];
  const jobtitleVar = [];
  for (let i = 0; i <= jobtitle.length; i++) {
    jobtitleVar.push(jobtitle[i]);
  }

  const handlerequestAccess = (e) => {
    let ids = e.map(function (i) {
      return i._id;
    });
    setSpecializationInfo(ids);
    setDefaultSpecialization(e);

    // console.log(e);
  };

  // let listingData;

  // function categoryListing() {
  //   // let abc;
  //   listingData = [];
  //   mainCategory
  //     .filter((c) => c.parent_id)
  //     .forEach((c) => {
  //       const parent = mainCategory.find((p) => p._id === c.parent_id);
  //       // console.log('asa',parent);
  //       parent.subCategories = parent.subCategories || [];
  //       if (parent.subCategories.filter((e) => e._id === c._id).length == 0) {
  //         parent.subCategories.push(c);
  //       }
  //     });

  //   listingData = mainCategory.filter((c) => !c.parent_id);
  // }
  /* *** File upload function start *** */
  /* Feature image */
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);

  /* Job gallery */
  const [file, setFile] = useState([]);
  const [myArray, updateMyArray] = useState([]);
  const [previewSrc, setPreviewSrc] = useState(null);
  const jobGallery = [];

  const dropRef = useRef();
  const dropImageRef = useRef();
  //Handling image
  const onDropImage = (files) => {
    const [uploadedFile] = files;
    setFeaturedImage(uploadedFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setIsPreviewAvailable(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
  };
  // const onDropProductImage = (files, i) => {
  //   const [uploadedFile] = files;
  //   const img_upload = [...imageUpload];
  //   img_upload[i] = files;
  //   setImageUpload(img_upload);
  //   const fileReader = new FileReader();
  //   fileReader.onload = () => {
  //     const img = [...jobImagePreview];
  //     img[i] = fileReader.result;
  //     setJobImagePreview(img);
  //   };
  //   fileReader.readAsDataURL(uploadedFile);
  // };

  // const newProductImage = () => {
  //   const img_upload = [...imageUpload];
  //   img_upload[img_upload.length] = null;
  //   setImageUpload(img_upload);
  // };

  // const removeProductImage = (e, i) => {
  //   // setFeaturedImage(null);
  //   const img_upload = [...imageUpload];
  //   img_upload[i] = null;
  //   setImageUpload(img_upload);
  //   const img = [...jobImagePreview];
  //   img[i] = "";
  //   setJobImagePreview(img);
  //   e.preventDefault();
  // };
  // const removeProductImageBlock = (e, i) => {
  //   // setFeaturedImage(null);
  //   const img_upload = [...imageUpload];
  //   img_upload.splice(i, 1);
  //   setImageUpload(img_upload);
  //   const img = [...jobImagePreview];
  //   img.splice(i, 1);
  //   setJobImagePreview(img);
  //   e.preventDefault();
  // };

  //Handling image

  const updateBorder = (dragState, reference) => {
    if (dragState === "over") {
      reference.current.style.border = "1px solid #000";
    } else if (dragState === "leave") {
      reference.current.style.border = "1px dashed #046f9c";
    }
  };
  const removeImage = (e) => {
    setFeaturedImage(null);
  };
  // const removeProductGallery = (e) => {
  //   setFile(null);
  // };
  /* *** File upload function end *** */

  // const saveNewCategory = () => {
  //   // Write your codes to save the category
  //   setAddNewCategory(false);
  // };

  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   SetBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  // };
  const routeListChange = () => {
    // Write your codes to save the category
    history.push("/my-jobs-list");
  };
  const dispatch = useDispatch();

  // const getUploadedFiles = (recordSet) => {
  //   setFile(recordSet);
  // };
  // ** Function to handle form submit
  const onSubmit = async (e, errors, values) => {
    // console.log('asas',imageUpload.indexOf(null));
    // return false;
    var validation = {
      title: "Job Title",
      description: "Job Description",
      //min_experience: "Min Experience",
      //max_experience: "Max Experience",
      website_url: "Website URL",
      phone_number: "Phone Number",
      employment_type: "Employment Type",
      salary_range: "Salary Range",
      company_name: "Practice Name",
      contact_person_name: "Contact Person Name",
      email: "Email",
      address1: "Address 1",
      // address2: "Address 2",
      state: "State",
      city: "City",
      zip_code: "Zip Code",
    };
    if (errors.length > 0) {
      if (errors[0]?.toLowerCase() == "agreedtoterms") {
        valSwalFailure("Error!", "Please agree the Terms & Conditions");
        return false;
      }
      valSwalFailure("Error!", "Please enter " + validation[errors[0]]);
      return false;
    } else if (body == null) {
      /*else if (specializationInfo.length == 0) {
      valSwalFailure("Error!", "Please Select Job Specialization");
      return false;
    }*/
      /*else if(values?.min_experience > values?.max_experience){
      valSwalFailure("Error!", "Minimum experience should be less than max experience");
      return false;
      }*/
      valSwalFailure("Error!", "Please enter Job description");
      return false;
    } else if (companyDescription == null) {
      valSwalFailure("Error!", "Please enter About company");
      return false;
    }
    /*else if (featuredImage == null) {
      valSwalFailure("Error!", "Please Choose the Featured Image");
      return false;
    }*/
    // else if (imageUpload.indexOf(null) > -1) {
    //   valSwalFailure("Error!", "Please upload the Product Gallery");
    //   return false;
    // }
    else {
      swalalertWithMsg(true, "please wait!");
      let payload = values;
      payload.description = body;
      payload.company_description = companyDescription;
      payload.specialization_id = specializationInfo;
      var formDataAppend = new FormData();
      for (var key in payload) {
        formDataAppend.append(key, payload[key] ? payload[key] : "");
      }
      // formDataAppend.append("ratings", ratings);
      // formDataAppend.append("comment", reviews);

      formDataAppend.append("company_logo", featuredImage);

      // for (let i = 0; i < imageUpload.length; i++) {
      //   console.log("sinle", imageUpload[i]);
      //   formDataAppend.append("product_gallery", imageUpload[i][0]);
      // }
      formDataAppend.append("user_id", user.sub.replace("auth0|", ""));
      swalalertWithMsg(true, "Please Wait!");
      dispatch(id ? updateJobTemplate(formDataAppend) : createJobTemplate(formDataAppend))
        .then((res) => {
          valSwalSuccess("Success", "Job Updated Successfully!");
          history.push("/my-jobs-list");
        })
        .catch((err) => {
          valSwalFailure("Error", "Something went wrong please try again");
        });
    }
  };

  // const categoryToggle = (value) => {
  //   setAddNewCategory(value);
  // };
  // const handleChange = (e) => {
  //   const { value, checked } = e.target;
  //   if (checked) {
  //     setCategoryInfo((categoryinfo) => [...categoryinfo, e.target.id]);
  //   } else {
  //     let tempCategory = [...categoryinfo];
  //     const index = tempCategory.indexOf(e.target.id);
  //     if (index > -1) {
  //       // only splice array when item is found
  //       tempCategory.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //     setCategoryInfo(tempCategory);
  //   }
  // };

  // function categoryListTree() {
  //   categoryListing();
  //   return listingData.map((category, i) => {
  //     let subcategoryList = listSubCategory(
  //       category.subCategories != undefined ? category.subCategories : []
  //     );
  //     return (
  //       <li>
  //         <AvInput
  //           onChange={handleChange}
  //           name="parent_id"
  //           type="checkbox"
  //           id={category._id}
  //         />
  //         <Label for="1" className="mb-0">
  //           {category.category_name}
  //         </Label>
  //         {subcategoryList}
  //       </li>
  //     );
  //   });
  // }

  // function listSubCategory(child) {
  //   if (child != []) {
  //     return child.map((category, i) => {
  //       let subcategoryList = listSubCategory(
  //         category.subCategories != undefined ? category.subCategories : []
  //       );
  //       return (
  //         <ul className="pl-4">
  //           <li>
  //             <AvInput
  //               name="subcategory_id"
  //               onChange={handleChange}
  //               type="checkbox"
  //               id={category._id}
  //             />
  //             <Label for="1" className="mb-0">
  //               {category.category_name}
  //             </Label>
  //             {subcategoryList}
  //           </li>
  //         </ul>
  //       );
  //     });
  //   }
  // }

  // const [ratings, setRatings] = useState(0);
  // const [reviews, setReviews] = useState("");

  // const onRatingsAndReviewsChange = (field, event) => {
  //   if (field == "rating") {
  //     setRatings(event);
  //   } else if (field == "textarea") {
  //     setReviews(event.target.value);
  //   }
  // };

  // const onRatingsAndReviewsSubmit = () => {
  //   dispatch(createComment(form))
  //   .then((res) => {
  //     valSwalSuccess('Success','Comment Updated Successfully!')
  //     if(!form.parent) setForm(basicForm);

  //   })
  //   .catch((err) => {
  //     valSwalFailure('Error','Something went wrong please try again')
  //   });
  // };

  //handle experience changes min max condition
  // const handleExperience = (event) => {

  //   if (maxExperience < minExperience) {
  //     setValidationMessages(
  //       "Minimum experience should be less than max experience"
  //     );
  //   } else {
  //     setValidationMessages(null);
  //   }
  // };

  // ** Get city by handling state
  const handleState = (event) => {
    var selectedOption = event.target.options[event.target.selectedIndex];
    var isoCode = selectedOption.dataset.param1;
    var countryCode = selectedOption.dataset.param2;
    var getCityOption = getAllCityByCountryState("manipulate", countryCode, isoCode);
    setCityOption(getCityOption);
  };

  // ** Get City By created data

  useEffect(() => {
    var stateObj = stateOption.filter((x) => x.value == jobDetails?.state);
    var getCityOption = getAllCityByCountryState("manipulate", "US", stateObj?.[0]?.isoCode);
    setCityOption(getCityOption);
  }, [jobDetails]);

  return (
    <PageWrapper id="page-wrapper" className="pb-6">
      <TitleWrapper className="pt-6 mb-6">
        <Container>
          <h3 className="text-white">{action} a Job</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="my-jobs-list">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{action} a Job</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </TitleWrapper>

      <Container>
        <FormWrapper>
          <AvForm onSubmit={onSubmit}>
            <Card>
              <CardBody className="pb-3">
                <h5 className="mb-0 border-bottom mb-3 pb-3 " style={{ fontWeight: "600" }}>
                  Job Information
                </h5>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label for="title" className="d-flex align-items-center">
                        Job Title <span className="text-danger ml-1">*</span>
                      </Label>
                      <AvInput
                        type="hidden"
                        id="_id"
                        name="_id"
                        placeholder="Name"
                        value={jobDetails?._id}/>
                      {/*<AvInput
                      name="title"
                      id="title"
                      placeholder="Job Title"
                      value={jobDetails?.title ? jobDetails.title : ""}
                      required
                      />*/}

                      <AvField
                        type="select"
                        value={jobDetails?.title ? jobDetails.title : ""}
                        name="title"
                        required
                      >
                        <option value="">Select Job Title</option>
                        {jobtitleVar.map((jobtitleVar) => (
                          <option value={jobtitleVar}>{jobtitleVar}</option>
                        ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                {/*<Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="organization_name">
                              Specialty Name
                              <span className="text-danger ml-1">*</span>
                            </Label>
                            <Select
                              isMulti="true"
                              theme={selectThemeColors}
                              className="react-select"
                              classNamePrefix="select"
                              options={specializations}
                              getOptionLabel={(option) =>
                                option.specialization_name
                              }
                              getOptionValue={(option) => option._id}
                              isClearable={false}
                              value={defaultSpecialization}
                              // defaultValue={details.category}
                              id={"initiatives"}
                              menuPlacement="top"
                              placeholder="Select all that apply"
                              onChange={handlerequestAccess}
                            />
                          </FormGroup>
                        </Col>
                      </Row>*/}

                <Row>
                  {/*<Col lg="6">
                          <FormGroup>
                            <Label
                              for="min_experience"
                              className="d-flex align-items-center">
                              Min Experience{" "}
                              <span className="text-danger ml-1">*</span>
                            </Label>
                            <AvField
                              type="select"
                              name="min_experience"
                              value={minExperience}
                              required>
                              <option value="">Select Min Experience</option>
                              {numbers.map((number) => (
                                <option value={number}>{number}</option>
                              ))}
                            </AvField>
                          </FormGroup>
                        </Col>*/}

                  {/*<Col lg="6">
                          <FormGroup>
                            <Label
                              for="max_experience"
                              className="d-flex align-items-center">
                              Max Experience{" "}
                              <span className="text-danger ml-1">*</span>
                            </Label>
                            <AvField
                              type="select"
                              name="max_experience"
                              value={maxExperience}
                              required>
                              <option value="">Select Max Experience</option>
                              {numbers.map((number) => (
                                <option value={number}>{number}</option>
                              ))}
                            </AvField>
                          </FormGroup>
                        </Col>*/}
                </Row>
                <Row>
                  <Col lg="6">
                    {/* <FormGroup>
                            <Label
                              for="employment_type"
                              className="d-flex align-items-center"
                            >
                              Employment Type{" "}
                              <span className="text-danger ml-1">*</span>
                            </Label>
                            <AvInput
                              name="employment_type"
                              id="employment_type"
                              placeholder="Employment Type"
                              required
                            />
                          </FormGroup> */}
                    <AvGroup>
                      <Label for="employment_type" className="d-flex align-items-center">
                        {" "}
                        Job Type <span className="text-danger"> *</span>
                      </Label>
                      <AvField
                        type="select"
                        name="employment_type"
                        value={jobDetails?.employment_type ? jobDetails.employment_type : ""}
                        required
                      >
                        <option value="">Select Job Type</option>
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        <option value="Temporary">Temporary</option>
                        <option value="Contact">Contact</option>
                      </AvField>
                    </AvGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="salary_range" className="d-flex align-items-center">
                        Salary Range{" "}
                      </Label>
                      <AvInput
                        name="salary_range"
                        id="salary_range"
                        placeholder="$100,000 to $150,000 / year"
                        value={jobDetails?.salary_range ? jobDetails.salary_range : ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label for="description" className="d-flex align-items-center">
                        Job Description
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <EditorWrapper>
                        <Editor
                          apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3"
                          theme="modern"
                          readOnly={origin == "declined"}
                          init={{
                            height: 250,
                            branding: false,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image",
                              "charmap print preview anchor help",
                              "searchreplace visualblocks code",
                              "insertdatetime media table paste wordcount",
                            ],
                            // toolbar:
                            //   "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview",
                            toolbar:
                              "bold italic | alignleft aligncenter alignright | bullist numlist",
                            images_upload_url: "/api/page-template/aws",
                            images_file_types: "jpg,jpeg,png,svg,webp",
                            paste_data_images: true,

                            // setup: (editor) => {
                            //   editor.on("init", () => {
                            //     const maxWordCount = 300;
                            //     const wordCountWarningThreshold =
                            //       maxWordCount - 20;

                            //     const checkWordCount = () => {
                            //       const content = editor.getContent({
                            //         format: "text",
                            //       });
                            //       const words = content
                            //         .split(/\s+/)
                            //         .filter(Boolean);
                            //       if (words.length > maxWordCount) {
                            //         // editor.setMode('readonly');
                            //         const truncatedContent = words
                            //           .slice(0, maxWordCount)
                            //           .join(" ");
                            //         editor.setContent(truncatedContent);
                            //         alert(
                            //           "You have reached the maximum word count limit (300 words)."
                            //         );
                            //       }
                            //       // else if (words.length > wordCountWarningThreshold) {
                            //       //   alert('You are approaching the maximum word count limit (300 words).');
                            //       // }
                            //       else {
                            //         editor.setMode("design");
                            //       }
                            //     };
                            //     editor.on("input", checkWordCount);
                            //     editor.on("paste", () => {
                            //       setTimeout(checkWordCount, 0);
                            //     });
                            //   });
                            // },
                          }}
                          value={body}
                          onEditorChange={(content) => {
                            SetBody(content);
                          }}
                        />
                      </EditorWrapper>
                    </FormGroup>
                  </Col>
                </Row>

                <h5 className="" style={{ fontWeight: "600" }}>
                  Company Information
                </h5>

                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="company_name" className="d-flex align-items-center">
                        Practice Name <span className="text-danger ml-1">*</span>
                      </Label>
                      <AvInput
                        name="company_name"
                        id="company_name"
                        placeholder="Practice Name"
                        value={jobDetails?.company_name ? jobDetails.company_name : ""}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="contact_person_name" className="d-flex align-items-center">
                        Contact Person Name <span className="text-danger ml-1">*</span>
                      </Label>
                      <AvInput
                        name="contact_person_name"
                        id="contact_person_name"
                        placeholder="Contact Person Name"
                        value={
                          jobDetails?.contact_person_name ? jobDetails.contact_person_name : ""
                        }
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <AvGroup>
                      <Label className="form-label" for="address1">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="text"
                        id="email"
                        name="email"
                        value={jobDetails?.email ? jobDetails.email : ""}
                        placeholder="johndoe@gmail.com"
                        required
                      />
                    </AvGroup>
                  </Col>

                  <Col lg="6">
                    <AvGroup>
                      <Label className="form-label" for="phone_number">
                        Phone Number{" "}
                      </Label>
                      <FormGroup className="phone-number-form-group">
                        <InputGroup className="input-group-merge">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>US (+1)</InputGroupText>
                          </InputGroupAddon>
                          <AvInput
                            id={`phone_number`}
                            name={`phone_number`}
                            type="tel"
                            mask="999 999 9999"
                            maskChar=" "
                            tag={[Input, InputMask]}
                            style={{ paddingLeft: ".5rem" }}
                            placeholder="Phone Number"
                            value={jobDetails?.phone_number ? jobDetails.phone_number : ""}
                          />
                        </InputGroup>
                      </FormGroup>
                    </AvGroup>
                  </Col>

                  <Col lg="6">
                    <AvGroup>
                      <Label className="form-label" for="address">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="text"
                        id="address1"
                        name="address1"
                        value={jobDetails?.address1 ? jobDetails.address1 : ""}
                        placeholder="Address"
                        required
                      />
                    </AvGroup>
                  </Col>

                  <Col lg="6">
                    <AvGroup>
                      <Label className="form-label" for="address2">
                        Address 2
                      </Label>
                      <AvInput
                        type="text"
                        id="address2"
                        name="address2"
                        value={jobDetails?.address2 ? jobDetails.address2 : ""}
                        placeholder="Address 2"
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6">
                    <AvGroup>
                      <Label className="form-label" for={"city"}>
                        City
                      </Label>
                      <AvInput
                        type="text"
                        id={"city"}
                        name={"city"}
                        placeholder="City"
                        value={jobDetails?.city ? jobDetails.city : ""}
                        required
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6">
                  <AvGroup>
                    <Label className="form-label" for={"state"}>
                      State{buyerData?.state}
                    </Label>
                    <AvField
                    type="select"
                    name="state"
                    value={jobDetails?.state ? jobDetails?.state : ""}>
                    <option value="">Select State</option>
                    {stateOption?.map((state, index) => {
                    return (
                      <option
                        data-param1={state.isoCode}
                        data-param2={state.countryCode}
                        value={state.value}>
                        {state.label}
                      </option>
                    );
                    })}
                    required
                  </AvField>
                            
                  {/*<AvInput
                    type="text"
                    id={"state"}
                    name={"state"}
                    placeholder="State"
                    value={buyerData?.state ? buyerData.state : jobDetails.state}
                    required/>*/}
                </AvGroup>
                </Col>

                <Col md="6">
                  <AvGroup>
                    <Label className="form-label" for={"zip_code"}>
                      Zip
                    </Label>
                    <AvInput
                      type="number"
                      id={"zip_code"}
                      name={"zip_code"}
                      placeholder="ZIP/ postcode"
                      value={jobDetails?.zip_code ? jobDetails.zip_code : ""}
                      required
                    />
                  </AvGroup>
                </Col>

                <Col lg="6">
                    <FormGroup>
                      <Label for="website-url" className="d-flex align-items-center">
                        Website URL <span className="text-danger">*</span>
                      </Label>
                      <AvInput
                        type="url"
                        name="website_url"
                        id="website_url"
                        placeholder="http://test.com"
                        value={jobDetails?.website_url ? jobDetails.website_url : ""}
                        required
                      />
                    </FormGroup>
                  </Col>


                  {/*<>
                    {!showAddress ? (
                      <Col lg="6">
                        <AvGroup>
                          <Label className="form-label" for={"bill_work_address"}>
                            Address <span className="text-danger ml-1">*</span>
                          </Label>
                          <Location setValues={setAddress} index="0"></Location>
                        </AvGroup>
                      </Col>
                    ) : (
                      <>
                        <Col md="6">
                          <AvGroup>
                            <Label className="form-label" for={"address1"}>
                              Address <span className="red">*</span>
                            </Label>
                            <AvInput
                              type="text"
                              id={"address1"}
                              name={"address1"}
                              placeholder="Street Name"
                              value={buyerData.address1 ? buyerData.address1 : jobDetails.address1}
                            />
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Label className="form-label" for={"city"}>
                              City
                            </Label>
                            <AvInput
                              type="text"
                              id={"city"}
                              name={"city"}
                              placeholder="city"
                              value={buyerData?.city ? buyerData.city : jobDetails.city}
                              required
                            />
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Label className="form-label" for={"state"}>
                              State{buyerData?.state}
                            </Label>
                            <Select
                            className="react-select"
                            classNamePrefix="select"
                            options={stateOption}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            isClearable={false}
                            id={"state"}
                            value={formState}
                            //defaultValue={buyerData?.state ? buyerData.state : jobDetails.state}
                            menuPlacement="top"
                            placeholder="State"/>
                            
                            
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Label className="form-label" for={"zip_code"}>
                              Zip
                            </Label>
                            <AvInput
                              type="number"
                              id={"zip_code"}
                              name={"zip_code"}
                              placeholder="ZIP/ postcode"
                              value={buyerData?.zip_code ? buyerData.zip_code : jobDetails.zip_code}
                              required
                            />
                            <AvInput
                              type="hidden"
                              id="lng"
                              name="lng"
                              placeholder="Name"
                              value={buyerData?.lng ? buyerData.lng : jobDetails.lng}
                            />
                            <AvInput
                              type="hidden"
                              id="lat"
                              name="lat"
                              placeholder="Name"
                              value={buyerData?.lat ? buyerData.lat : jobDetails.lat}
                            />
                            <AvInput
                              type="hidden"
                              id={"country"}
                              name={"country"}
                              placeholder="Country"
                              value={buyerData?.country ? buyerData.country : jobDetails.country}
                            />
                          </AvGroup>
                        </Col>

                        <div className="text-right col-12">
                          <AvGroup>
                            <Button
                              size="sm"
                              className="py-1 px-2"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                              color="danger"
                              onClick={() => clearsearch()}
                            >
                              Clear Search Again
                            </Button>
                          </AvGroup>
                        </div>
                      </>
                    )}
                  </>*/}

                  

                  

                  <Col lg="12">
                    <FormGroup>
                      <Label for="description" className="d-flex align-items-center">
                        About Company
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <small className="d-block p-2 mb-3" style={{ background: "#f7f7f7" }}>
                        <p className="mb-1">
                          Please give details about your practice, surrounding areas and attractions. Details on what make your practice stand out and why the potential new hires would want to work there.
                        </p>
                        <p className="mb-0">
                          Give details on the area surrounding your practice, 
                          attractions and how far they are, things to do, is it rural,city,suburia,
                          is it more for families, or is night life popular, 
                          what types of shopping and resturants, what about animal friendly, school districts, 
                          daycare and more.
                        </p>
                      </small>
                      <EditorWrapper>
                        <Editor
                          apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3"
                          theme="modern"
                          readOnly={origin == "declined"}
                          init={{
                            height: 250,
                            branding: false,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image",
                              "charmap print preview anchor help",
                              "searchreplace visualblocks code",
                              "insertdatetime media table paste wordcount",
                            ],
                            // toolbar:
                            //   "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview",
                            toolbar:
                              "bold italic | alignleft aligncenter alignright | bullist numlist",
                            images_upload_url: "/api/page-template/aws",
                            images_file_types: "jpg,jpeg,png,svg,webp",
                            paste_data_images: true,
                          }}
                          value={companyDescription}
                          onEditorChange={(content) => {
                            SetCompanyDescription(content);
                          }}
                        />
                      </EditorWrapper>
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <Label>Company Logo</Label>
                    <DropzoneWrapper className="featured-image">
                      <Dropzone
                        onDrop={onDropImage}
                        onDragEnter={() => updateBorder("over", dropImageRef)}
                        onDragLeave={() => updateBorder("leave", dropImageRef)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({
                              className: "drop-zone ",
                            })}
                            ref={dropImageRef}
                            style={{ height: "unset" }}
                          >
                            <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                            {!featuredImage ? <div>Drag or Click to Upload</div> : ""}
                            {featuredImage ? (
                              <div className="selected-item-wrapper w-100">
                                <div className="selected-item">
                                  <Button className="remove-selected">
                                    <Trash2 size="14" onClick={removeImage} />
                                  </Button>

                                  <img src={isPreviewAvailable} width="100%" height="" alt="" />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </DropzoneWrapper>
                    {/* <Card className="mb-3">
                          <CardHeader className="bg-white">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Company Logo
                              <span className="text-danger ml-1">*</span>
                            </h6>
                            <AvInput
                              type="hidden"
                              id="old_company_logo"
                              name="old_company_logo"
                              value={featuredImage ? featuredImage : null}
                            />
                          </CardHeader>
                          <CardBody>
                            <DropzoneWrapper className="featured-image">
                              <Dropzone
                                onDrop={onDropImage}
                                onDragEnter={() => updateBorder("over", dropImageRef)}
                                onDragLeave={() => updateBorder("leave", dropImageRef)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps({
                                      className: "drop-zone ",
                                    })}
                                    ref={dropImageRef}
                                    style={{ height: "unset" }}
                                  >
                                    <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                                    {!featuredImage ? <div>Drag or Click to Upload</div> : ""}
                                    {featuredImage ? (
                                      <div className="selected-item-wrapper w-100">
                                        <div className="selected-item">
                                          <Button className="remove-selected">
                                            <Trash2 size="14" onClick={removeImage} />
                                          </Button>

                                          <img
                                            src={isPreviewAvailable}
                                            width="100%"
                                            height=""
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </DropzoneWrapper>
                          </CardBody>
                        </Card> */}
                  </Col>
                </Row>

                {/* <Row>
                        <Col lg="12">
                          <Card className="mb-3">
                            <CardHeader className="bg-white d-flex align-items-center justify-content-between">
                              <h6
                                className="mb-0"
                                style={{ fontWeight: "600" }}
                              >
                                Product Gallery
                              </h6>
                              <Tooltip
                                isOpen={tooltipOpen}
                                target="add_gallery"
                                toggle={toggle}
                              >
                                Add new image
                              </Tooltip>
                              <AddGalleryButton
                                id="add_gallery"
                                className="p-1"
                                outline
                                color="primary"
                                onClick={() => newProductImage()}
                              >
                                <Plus size="14" />
                              </AddGalleryButton>
                            </CardHeader>
                            <CardBody>
                              <GalleryWrapper>
                                {imageUpload.map((item, i) => {
                                  return (
                                    <DropzoneWrapper
                                      className={`position-relative ${
                                        imageUpload[i]
                                          ? "image-selected mb-0"
                                          : ""
                                      } `}
                                    >
                                      {imageUpload.length > 1 && (
                                        <Button
                                          onClick={(e) =>
                                            removeProductImageBlock(e, i)
                                          }
                                          className="remove-selected"
                                        >
                                          <Trash2
                                            size="14"
                                            onClick={(e) =>
                                              removeProductImage(e, i)
                                            }
                                          />
                                        </Button>
                                      )}

                                      <Dropzone
                                        onDrop={(e) => onDropProductImage(e, i)}
                                        onDragEnter={() =>
                                          updateBorder("over", dropImageRef)
                                        }
                                        onDragLeave={() =>
                                          updateBorder("leave", dropImageRef)
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div
                                            {...getRootProps({
                                              className: "drop-zone ",
                                            })}
                                            ref={dropImageRef}
                                            style={{ height: "unset" }}
                                          >
                                            <input
                                              {...getInputProps()}
                                              accept=".jpg,.jpeg,.png,.svg"
                                            />
                                            {!imageUpload[i] ? (
                                              <div>Drag or Click to Upload</div>
                                            ) : (
                                              ""
                                            )}
                                            {imageUpload[i] ? (
                                              <div className="selected-item-wrapper w-100">
                                                <div className="selected-item">
                                                  <img
                                                    src={jobImagePreview[i]}
                                                    width="100%"
                                                    height=""
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </Dropzone>
                                    </DropzoneWrapper>
                                  );
                                })}
                              </GalleryWrapper>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row> */}

                {/* <Row>
                        <Col lg="12">
                          <Card>
                            <CardHeader className="bg-white">
                              <h6
                                className="mb-0"
                                style={{ fontWeight: "600" }}
                              >
                                Rate this Product
                              </h6>
                            </CardHeader>

                            <CardBody>
                              <FormGroup>
                                <Label className="d-block">
                                  Your Overall Rating
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Rating
                                  initialValue={ratings}
                                  size="20"
                                  onClick={(e) =>
                                    onRatingsAndReviewsChange("rating", e)
                                  }
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>
                                  Comment<span className="text-danger"> *</span>
                                </Label>
                                <Input
                                  type="textarea"
                                  rows="5"
                                  required
                                  value={reviews}
                                  onChange={(e) =>
                                    onRatingsAndReviewsChange("textarea", e)
                                  }
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row> */}

                <Row className="align-items-center mt-3">
                  <Col lg="6">
                    <AvGroup check className="mb-2 mb-lg-0">
                      <AvInput
                        type="checkbox"
                        name="agreedToTerms"
                        // onChange={this.handleAgreeChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "You must agree to the terms and conditions",
                          },
                        }}
                      />
                      I agree to the terms & conditions <span className="text-danger">*</span>
                    </AvGroup>
                  </Col>
                  <Col lg="6">
                    <div className="text-right">
                      <PublishButton color="success" size="sm" className="mb-2 ">
                        Submit for Approval
                      </PublishButton>
                      <CancelButton
                        color="danger"
                        size="sm"
                        className="mb-2 ml-2"
                        onClick={() => routeListChange()}
                      >
                        Discard
                      </CancelButton>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </AvForm>
        </FormWrapper>
      </Container>
    </PageWrapper>
  );
};
export default AddJob;

export const FormWrapper = styled.section``;

export const DropzoneWrapper = styled.div`
  /* only for featured image  */
  &.featured-image {
    img {
      max-height: 325px;
      object-fit: contain;
    }
  }
  /* /featured image */

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .remove-selected {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  .drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-width: 1px;
    border-radius: 2px;

    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:not(:hover) {
      border-color: #ced4da !important;
    }

    &:hover {
      border: 1px dashed var(--primary) !important;
    }

    & > div:not(.selected-item-wrapper) {
      padding: 20px;
      width: 100%;
      text-align: center;
    }
  }

  div {
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected-item {
    position: relative;

    .remove-selected {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      padding: 2px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 calc(50% - 8px);
  }

  div:not(.image-selected) {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .selected-item {
    position: static;

    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
`;

export const CategoryTree = styled.ul`
  list-style: none;

  li {
    text-transform: capitalize;
  }
  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;

export const PublishButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const CancelButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const AddNewCategory = styled.div``;

export const AddCategoryBtn = styled(Button)`
  border-width: 1px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f3f6ff;
  font-size: 14px;
`;

export const AddNewCategoryWrapper = styled.div`
  font-size: 0.857rem;

  input {
    font-size: 0.857rem;
  }
`;

export const WysiwygEditor = styled.div`
  .demo-editor.rdw-editor-main,
  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .demo-editor.rdw-editor-main {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0px 1rem;
    min-height: 300px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5rem 0px;
  }

  .rdw-editor-wrapper:focus-within {
    border-color: #3365d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 51, 114, 0.25);
    border-radius: 0.25rem;

    .demo-editor.rdw-editor-main,
    .rdw-editor-toolbar {
      border-color: #3365d6;
    }

    .demo-editor.rdw-editor-main {
      border-top-color: #ced4da;
    }
  }
`;

export const AddGalleryButton = styled(Button)`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  border-width: 1.5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditorWrapper = styled.div`
  .tox-tinymce {
    border-radius: 0.25rem;
    border-color: #d8d6de;

    * {
      border-color: #d8d6de !important;
    }
  }

  .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 0 !important;
  }
`;
