import { combineReducers } from 'redux'
import  PostReducer  from "./PostReducer"
import  ProductReducer  from "./ProductReducer"
import  JobReducer  from "./JobReducer"
import  users  from "../../../src/views/user/store/reducer/index.js"

// Combine Reducers
const RootReducer = combineReducers({
    posts: PostReducer,
    products:ProductReducer,
    users: users,
    jobs: JobReducer,
});

export default RootReducer;