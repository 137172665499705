const initialState = {
    allProducts: null,
    Addcategory:null,
    list:[],
    edit:[],
    category:[],
    details:null,
}

const ProductReducer = (state=initialState, action) => {

    switch (action.type) {
        case 'GET_PRODUCTS':
            return { ...state, allProducts: action.data };
        case 'GET_ALL_PRODUCTS':
        return { ...state, list: action.data };
        case 'EDIT_PRODUCT':
        return { ...state, edit:action.data };
        case 'GET_ALL_CATEGORY':
        return { ...state, category: action.data };
        case 'ADD_CATEGORY':
        return { ...state, Addcategory: action.data };
        case 'GET_PRODUCT_DETAILS':
        return { ...state, details: action.data };
        // case 'UPDATE_PRODUCTS':
        //     return { ...state, details: action.data };
        default:
            return state;
    }

}
export default ProductReducer;