import React, { useState, useEffect } from "react";
// ** React Imports
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  //Pagination,
  //PaginationItem,
  //PaginationLink,
  Row,
} from "reactstrap";
import styled from "styled-components";
import Sidebar from "./MyFilterSidebar";
import Select from "react-select";
import { Input } from "reactstrap";
import { CheckCircle, Search, Star, XCircle } from "react-feather";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import { useDispatch, useSelector } from "react-redux";
import { listMyProductTemplate } from "../../Store/Action/ProductAction.js";
import Pagination from "./components/Pagination";
import { PaginatedList } from "react-paginated-list";
import { forEach } from "lodash";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import placeholderImage from "../../assets/images/placeholder-image.jpg";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
  Panel,
  RefinementList,
  Pagination as Pagination1,
  Highlight,
  SortBy,
  Stats,
  // NoResults,
} from "react-instantsearch-dom";

import { listProductCategory } from "../../Store/Action/ProductAction.js";

function Hit(props) {
  const history = useHistory();
  const mainCategory = useSelector((state) => state.products.category);

  const routeChange = (id) => {
    return history.push({
      pathname: "/product-detail",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };
  const routeEditChange = (id) => {
    return history.push({
      pathname: "/product-edit",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };
  const getCategoryname = (row) => {
    // if (row?.category.length > 0) {
      // return row?.category
      //   .map(function (e) {
      //     return e.category_name;
      //   })
      //   .join(", ");
    // }
    if (row.product_category) {
      return row.product_category
        .map(function (e) {
          return mainCategory.find((v) => v._id === e)?.category_name;
        })
        .join(", ");
    }
  };

  return (
    <ProductListCard>
      <Card>
        <CardBody>
          <div className="product-image">
            <img src={props.hit.feature_image} alt="" width={"100%"} />
          </div>
          <div className="description">
            <h3>
              <Highlight attribute="title" hit={props.hit} />
            </h3>
            <div className="verified-status p-0">
              {props.hit.status === "Approved" ? (
                <Alert color="info" className="p-1 px-2 d-flex align-items-center">
                  <CheckCircle size="14" className="mr-1" />
                  {props.hit.status}
                </Alert>
              ) : (
                <Alert color="danger" className="p-1 px-2 d-flex align-items-center">
                  <XCircle size="14" className="mr-1" />
                  {props.hit.status}
                </Alert>
              )}
            </div>

            <p dangerouslySetInnerHTML={{ __html: props.hit.description }}></p>
          </div>
        </CardBody>
        <CardFooter className="bg-white">
          <ProductMeta className="unstyled">
            <li className="category">
              <span className="title">Category: </span>
              {getCategoryname(props.hit)}
            </li>

            <li className="manufacturer">
              <span className="title">Manufacturer: </span>
              {props.hit.manufacturer}
            </li>
            {/* <li className="rating p-0 bg-white">
            <Alert
              pill
              color="warning "
              className=" d-flex  align-items-center mb-0 "
            >
              <span className="mr-1">5</span>
              <Star size="16" />
            </Alert>
          </li> */}

            <li className="ml-auto ">
              <Button
                // tag={Link}
                onClick={() => routeEditChange(props.hit._id)}
                color="success"
                size="sm"
                className="view-product mr-2"
              >
                Edit Product
              </Button>

              <Button
                // tag={Link}
                onClick={() => routeChange(props.hit._id)}
                color="primary"
                size="sm"
                className="view-product"
              >
                View Product
              </Button>
            </li>
          </ProductMeta>
        </CardFooter>
      </Card>
    </ProductListCard>
  );
}

const AlgoliaSearch = ({ userID }) => {
  //const [searchClient, setSearchClient] = useState(null);
  const searchClient = algoliasearch("HGGCT1WR6X", "b3645d6aec8f8047ff6b507d8b08ec0f");
  const mainCategory = useSelector((state) => state.products.category);
  const [FilterCatergoryNames, setFilCatNames] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductCategory());
  }, []);

  useEffect(() => {
    console.log({ mainCategory });

    const filterCate = mainCategory.reduce((acc, curr) => {
      acc[curr._id] = curr.category_name;

      return acc;
    }, {});

    setFilCatNames(filterCate);

    console.log({ filterCate });
  }, [mainCategory]);

  // console.log({userID,searchClient})

  const transformItems = (items) => {
    return items.map((item) => ({
      ...item,
      label: FilterCatergoryNames[item.label],
    }));
  };

  return (
    <>
      {searchClient && userID && (
        <InstantSearch searchClient={searchClient} indexName="CAN_ProductList" searchFilter>
          <Configure hitsPerPage={10} analytics={false} filters={"user_id:" + userID} />
          <h1 class="text-center text-capitalize mb-3">My Product List</h1>
          <Row>
            <Col lg="3" className="mb-4 mb-lg-0">
              <ClearSearchButton>
                {/* <ClearRefinements
                  translations={{
                    reset: "Clear all",
                  }}
                /> */}
                <Button color="primary">Clear all</Button>
              </ClearSearchButton>
              <CategoryWrapper>
                <Panel header="Categories">
                  {mainCategory.length > 0 && (
                    <RefinementList
                      attribute="product_category"
                      // searchable={true}
                      limit={5}
                      showMore={true}
                      transformItems={transformItems}
                    />
                  )}
                </Panel>
              </CategoryWrapper>
            </Col>
            <Col lg="9">
              <div className="search-panel__results">
                <SearchBoxWrapper className="mb-3">
                  <SearchBox
                    className="searchbox"
                    translations={{
                      placeholder: "",
                    }}
                  />
                </SearchBoxWrapper>
                <Hits hitComponent={Hit} />

                <div className="pagination justify-content-center">
                  <Pagination1 />
                </div>
              </div>
            </Col>
          </Row>
        </InstantSearch>
      )}
    </>
  );
};

const ProductListPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userID, setUserID] = useState(null);
  const dispatch = useDispatch();
  // const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  const [searchQuery, setSearchQuery] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [idxLastRec, setIdxLastRec] = useState(0);
  const [idxFirstRec, setIdxFirstRec] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [nPages, setNPages] = useState(0);
  const store = useSelector((state) => state.products.list);
  const history = useHistory();

  useEffect(() => {
    const userID = user.sub.replace("auth0|", "");
    setUserID(userID);
    dispatch(listMyProductTemplate(userID));
  }, [dispatch]);

  useEffect(() => {
    // console.log('searchuseeff',searchQuery);
    getProductList();
  }, [searchQuery, searchFilter]);

  const [productList, setProductList] = useState(store);
  // console.log('store',store);

  // setProductList(store);
  // console.log('productList',productList);
  // const [productAllList, setProductAllList] = useState(store);

  useEffect(() => {
    setProductList(store);
    // setProductCount(store.length);
    if (store != null) {
      setProductCount(store.length);
    }
  }, [store]);

  const getProductList = () => {
    // console.log('searchQuery',searchQuery);
    if (store != null) {
      let allProduct = store;
      if (searchFilter.length) {
        allProduct = allProduct.filter(function (obj) {
          if (obj["product_category"] != undefined && obj["product_category"].length) {
            // console.log("aaaaa", obj["product_category"]);
            let a = obj["product_category"].filter((value) => searchFilter.includes(value));
            return a.length > 0;
          } else {
            return false;
          }
        });
      }
      if (searchQuery) {
        allProduct = allProduct.filter(function (obj) {
          return (
            (obj["title"] ? obj["title"] : "").toLowerCase().includes(searchQuery.toLowerCase()) ||
            (obj["description"] ? obj["description"] : "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        });
      }
      setProductCount(allProduct.length);
      setProductList(allProduct);
    }
  };

  //Search list of objects
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const options = [
    { value: "Avg Rating", label: "Avg Rating" },
    { value: "Price Low to High", label: "Price Low to High" },
    { value: "Price Hight to Low", label: "Price Hight to Low" },
  ];

  const filterOption = (option) => {
    setSearchFilter(option);
  };

  const routeChange = (id) => {
    return history.push({
      pathname: "/product-detail",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };
  const routeEditChange = (id) => {
    return history.push({
      pathname: "/product-edit",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };

  return (
    store != [] && (
      <PageWrapper id="page-wrapper" className="pt-6" style={{ paddingBottom: "56px" }}>
        <ProductListWrapper>
          <Container>
            <InnerWrapper>
              <AlgoliaSearch userID={userID} />
            </InnerWrapper>
          </Container>
        </ProductListWrapper>
      </PageWrapper>
    )
  );
};

export default ProductListPage;

export const InnerWrapper = styled.div`
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 30px;
  padding: 50px 30px 10px;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;

  .ais-Pagination {
    ul {
      margin-bottom: 0;
    }
    li {
      margin-bottom: 0.6rem;
    }
    a.ais-Pagination-link--selected {
      background-color: var(--primary);
      color: #fff;
    }
  }
`;

export const ProductListWrapper = styled.main`
  .pagination {
    li {
      margin-bottom: 0;
      line-height: 1.2;

      transition: background 300ms ease;

      &:not(.active):hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  form {
    display: flex;
    margin-bottom: 10px;

    &:focus-within {
      outline: 3px solid #c5ccdc;
      outline-offset: 0px;
      border-radius: 0.4rem;
    }

    input {
      width: 100%;
      box-shadow: unset;
      outline: unset;
      padding-left: 15px;
      height: 3.5rem;
      border: 2px solid var(--primary);
      flex: 1;
      border-radius: 0.4rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 16px;

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    .ais-SearchBox-reset {
      background-color: var(--danger);
      border-color: var(--danger);
    }

    .ais-SearchBox-input:not([value=""]) + button {
      display: none !important;
    }

    button {
      background-color: var(--primary);
      box-shadow: unset;
      padding: 10px 15px;
      border: 2px solid var(--primary);
      outline: none;
      border-radius: 0.4rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      svg {
        stroke: #fff;
        fill: #fff;
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export const ProductListCard = styled.section`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  .card-body {
    display: grid;
    grid-template-columns: 200px 2fr;
    gap: 20px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;

      .product-image {
        img {
          height: unset;
        }
      }
    }
  }

  .product-image {
    position: relative;

    img {
      max-height: 150px;
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
  }

  .description {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }

    .verified-status {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;

      .alert {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

export const ProductMeta = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;

  li {
    margin-bottom: 0 !important;

    &:not(:last-child) {
      background-color: #f1f1f1;
      padding: 5px 13px;
      border-radius: 100px;
      font-size: 14px;
    }

    &.rating {
      span {
        font-size: 14px;
      }

      .alert {
        padding: 7px 13px;
        border-radius: 100px;
        line-height: 1.2;
        color: #ae840c;
        font-weight: 600;

        svg {
          stroke: var(--warning);
          fill: var(--warning);
        }
      }
    }
  }

  .view-product {
    padding: 7px 20px;
  }

  .title {
    font-weight: 600;
  }
`;

export const CategoryWrapper = styled.div`
  /* &.rating-wrapper {
    input {
      display: none;
    }
  } */

  .ais-Panel-header {
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 18px;
    text-transform: uppercase;
  }

  label {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
  }

  .ais-RefinementList-count {
    margin-left: auto;
  }
  .ais-RefinementList-checkbox {
    margin-right: 5px;
  }

  .ais-RefinementList-item {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .ais-RefinementList-labelText {
    position: relative;
    top: -4px;
  }
  .ais-RefinementList-showMore {
    background-color: #e3f3ff;
    color: #173272;
    border: 1px solid #d3e9fc;
    box-shadow: unset;
    padding: 10px 12px;
    border-radius: 0.245rem;
    font-size: 14px;
    cursor: pointer;
    line-height: 1;
  }
`;

export const ClearSearchButton = styled.div`
  button {
    background-color: var(--danger);
    color: #fff;
    border: 1px solid var(--danger);
    box-shadow: unset;
    display: block;
    width: 100%;
    padding: 12px 10px;
    border-radius: 0.245rem;
    font-size: 16px;
    cursor: pointer;

    &.ais-ClearRefinements-button--disabled {
      background-color: #b9b9b9;
      border-color: #b9b9b9;
    }
  }
`;
