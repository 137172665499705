import React, { useState } from "react";
import { Button, ButtonGroup, FormGroup } from "reactstrap";

import { AvInput } from "availity-reactstrap-validation-safe";
import styled from "styled-components";

import Select from "react-select";
import { createCategoryTemplate } from "../../Store/Action/ProductAction.js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { valSwalSuccess, valSwalFailure, swalalertWithMsg } from "../../dev-config/global.js";

const AddProductCategory = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [addNewCategory, setAddNewCategory] = useState(false);
  const mainCategory = useSelector((state) => state.products.category);
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const saveNewCategory = () => {
    if (value == "") {
      valSwalFailure("Error!", "Please enter Category Name");
      return false;
    } else {
      swalalertWithMsg(true, "please wait!");
      let payload = {};
      payload.category_name = value;
      payload.parent_id = selectedValue ? selectedValue : null;
      dispatch(createCategoryTemplate(payload))
        .then((res) => {
          valSwalSuccess("Success", "Category Created Successfully!");
        })
        .catch((err) => {
          valSwalFailure("Error", "Something went wrong please try again");
        });
    }
  };

  // handle onChange event of the dropdown
  const SetCancelToggle = (value) => {
    props.categoryToggle(value);
  };

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    console.log("id", e._id);
    setSelectedValue(e._id);
  };

  const dispatch = useDispatch();

  return (
    <AddNewCategoryWrapper className="mt-3">
      <FormGroup>
        <AvInput
          name="category_name"
          id="category_name"
          placeholder="Category Name"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          required
        />
      </FormGroup>
      <FormGroup>
        <Select
          placeholder="Parent Category"
          className="mb-2"
          options={mainCategory}
          value={mainCategory._id}
          getOptionLabel={(option) => option.category_name}
          getOptionValue={(option) => option._id}
          onChange={handleChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#3365d6" : "#ced4da",
              borderWidth: "1px",

              boxShadow: state.isFocused ? " 0 0 0 0.2rem rgba(23, 51, 114, 0.25);" : "",

              outline: state.isFocused ? "0" : "",
            }),
          }}
        />
      </FormGroup>
      <ButtonGroup className="w-100">
        <SaveButton color="success" size="sm" className="" onClick={saveNewCategory}>
          Save
        </SaveButton>
        <CancelButton color="danger" size="sm" className="" onClick={() => SetCancelToggle(false)}>
          Cancel
        </CancelButton>
      </ButtonGroup>
    </AddNewCategoryWrapper>
  );
};
export default AddProductCategory;

export const AddNewCategory = styled.div``;

export const AddNewCategoryWrapper = styled.div`
  font-size: 0.857rem;

  input {
    font-size: 0.857rem;
  }
`;

export const SaveButton = styled(Button)`
  border-width: 1.5px;
  background-color: #d3ffcf;
  color: var(--success);
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const CancelButton = styled(Button)`
  border-width: 1.5px;
  background-color: #ffdada;
  color: var(--danger);
  padding-top: 5px;
  padding-bottom: 5px;
`;
