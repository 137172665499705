import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./assets/scss/styles.scss";
import Loading from "./components/Loading";
import Home from "./views/home/Home";
import Profile from "./views/user/edit/index.js";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import CommentLetters from "./views/article/category/CommentLetters";
import Post1 from "./views/article/single/post1";
import PostTemplate from "./views/article/single/PostTemplate";
import Post3 from "./views/article/single/post3";
import Post4 from "./views/article/single/post4";
import PageView from "./views/article/page-view/index.js";

import PrimaryHeader from "./components/header/Header";
import PrimaryFooter from "./components/footer/Footer";
import Register from "./views/auth/Register";

// Rating
import Rating from "./views/Rating";
import RatingSingle from "./views/RatingSingle";

// Page templates
import RightSidebar2 from "./views/article/page-templates/RightSidebar2";
import ResourceAtBottom from "./views/article/page-templates/ResourcesAtBottom";
import VideoWithContent from "./views/article/page-templates/VideoWithContent";
import VideoWithMinimalContent from "./views/article/page-templates/VideoWithMinimalContent";

// Pages
import NotFoundPage from "./views/not-found/NotFoundPage";
import WhatisCoa from "./views/what-is-coa/What-is-community-oncology";

import AddProduct from "./views/products/AddProduct";
import ProductListPage from "./views/products/ProductListPage";
import ProductCategory from "./views/products/ProductCategory.js";
import MyProductListPage from "./views/products/MyProductListPage";
import ProductDetail from "./views/products/ProductDetail";
import EditProduct from "./views/products/EditProduct";

import AddJob from "./views/job-portal/AddJob";
import JobListPage from "./views/job-portal/JobListPage";
import MyJobListPage from "./views/job-portal/MyJobListPage";
import JobDetail from "./views/job-portal/JobDetail";
import EditJob from "./views/job-portal/EditJob";

//import AddProduct1 from "./views/products-bk/AddProduct"; 27-07-2023
//import ProductListPage1 from "./views/products-bk/ProductListPage"; 27-07-2023
// import MyProductListPage1 from "./views/products-bk/MyProductListPage";
//import ProductDetail1 from "./views/products-bk/ProductDetail"; 27-07-2023
// import EditProduct from "./views/products/EditProduct";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Login from "./views/auth/Login";
import Post from "./views/post/post.js";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app">
        <PrimaryHeader />
        <>
          <Switch>
            {/* Page templates */}

            <Route path="/right-sidebar-2" exact component={RightSidebar2} />
            <Route path="/resource-at-bottom" exact component={ResourceAtBottom} />
            <Route path="/video-with-content" exact component={VideoWithContent} />
            <Route path="/video-with-minimal-content" exact component={VideoWithMinimalContent} />

            <Route path="/" exact component={Home} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/profile" component={Profile} />
            {/* <Route path="/resources" component={ExternalApi} /> */}
            <Route path="/cannery" component={CommentLetters} />
            <Route path="/post-1" exact component={Post1} />
            <Route path="/post/:slug" exact component={PostTemplate} />
            <Route path="/post-3" exact component={Post3} />
            <Route path="/post-4" exact component={Post4} />

            <Route path="/rating/" component={Rating} />
            <Route path="/rating-single/" component={RatingSingle} />

            <Route exact path="/add-product" component={AddProduct} />
            <Route exact path="/product-list" component={ProductListPage} />
            <Route exact path="/my-product-list" component={MyProductListPage} />
            <Route path="/product-detail/" component={ProductDetail} />
            <Route path="/product-edit/" component={EditProduct} />
            <Route path="/product-category" component={ProductCategory} />

            <Route exact path="/add-job" component={AddJob} />
            <Route exact path="/jobs-list" component={JobListPage} />
            <Route exact path="/my-jobs-list" component={MyJobListPage} />
            <Route path="/job-detail/" component={JobDetail} />
            <Route path="/edit-job/:id" component={AddJob} />

            {/* <Route exact path="/add-product1" component={AddProduct1} /> // 27-07-2023 */}
            {/* <Route exact path="/product-list1" component={ProductListPage1} /> // 27-07-2023 */}
            {/* <Route exact path="/my-product-list" component={MyProductListPage} /> */}
            {/* <Route path="/product-detail1/" component={ProductDetail1} /> // 27-07-2023 */}
            {/* <Route path="/product-edit/" component={EditProduct} /> */}

            <Route path="/about/what-is-community-oncology" component={WhatisCoa} />
            <Route path="/page-not-found" exact component={NotFoundPage} />

            {/* Changed Routes */}
            <Route path="/:category/:subcategory/:slug" exact component={PageView} />
            <Route path="/:category/:slug" component={Post} />
            <Route path="/:slug" component={Post} />
          </Switch>
        </>
        <PrimaryFooter />
      </div>
    </Router>
  );
};

export default App;
