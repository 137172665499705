// ** Third Party Components
import { useState, useEffect } from "react";
import wNumb from "wnumb";
import classnames from "classnames";
import { Star, X } from "react-feather";
import Nouislider from "nouislider-react";

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  CardHeader,
  CardFooter,
  Alert,
} from "reactstrap";
import { AvForm, AvInput, AvGroup, AvField } from "availity-reactstrap-validation-safe";

// ** Styles
// import "@styles/react/libs/noui-slider/noui-slider.scss";
import "nouislider/distribute/nouislider.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { listProductCategory } from "../../Store/Action/ProductAction.js";

const Sidebar = (props) => {
  // ** Props
  const { sidebarOpen, rateChartList } = props;
  const dispatch = useDispatch();
  const [categories, setRows] = useState([]);
  const [categoryinfo, setCategoryInfo] = useState([]);
  const [selectedRating, setSelectedRating] = useState(0);

  useEffect(() => {
    dispatch(listProductCategory());
  }, []);

  const store = useSelector((state) => state.products);

  useEffect(() => {
    setRows(store?.category);
  }, []);

  useEffect(() => {
    props.filterOption(categoryinfo);
  }, [categoryinfo]);

  const mainCategory = useSelector((state) => state.products.category);
  let listingData;

  function categoryListing() {
console.log({mainCategory})
    listingData = [];
    mainCategory
      .filter((c) => c.parent_id)
      .forEach((c) => {
        const parent = mainCategory.filter((p) => p._id === c.parent_id);
        parent.subCategories = parent.subCategories || [];
        if (parent.subCategories.filter((e) => e._id === c._id).length == 0) {
          parent.subCategories.push(c);
        }
      });

    listingData = mainCategory.filter((c) => !c.parent_id);
  }

  // ** Array of brands
  // const brands = [
  //   {
  //     title: "Insignia™",
  //     total: 746,
  //   },
  //   {
  //     title: "Samsung",
  //     total: 633,
  //     checked: true,
  //   },
  //   {
  //     title: "Metra",
  //     total: 591,
  //   },
  //   {
  //     title: "HP",
  //     total: 530,
  //   },
  //   {
  //     title: "Apple",
  //     total: 422,
  //     checked: true,
  //   },
  //   {
  //     title: "GE",
  //     total: 394,
  //   },
  //   {
  //     title: "Sony",
  //     total: 350,
  //   },
  //   {
  //     title: "Incipio",
  //     total: 320,
  //   },
  //   {
  //     title: "KitchenAid",
  //     total: 318,
  //   },
  //   {
  //     title: "Whirlpool",
  //     total: 298,
  //   },
  // ];

  // ** Array of ratings
  // const ratings = [
  //   {
  //     ratings: 4,
  //   },
  //   {
  //     ratings: 3,
  //   },
  //   {
  //     ratings: 2,
  //   },
  //   {
  //     ratings: 1,
  //   },
  // ];

  const clearFilter = () => {
    setCategoryInfo([]);
    props.ratingFilterOption(0);
    setSelectedRating(0);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCategoryInfo((categoryinfo) => [...categoryinfo, e.target.id]);
    } else {
      let tempCategory = [...categoryinfo];
      const index = tempCategory.indexOf(e.target.id);
      if (index > -1) {
        // only splice array when item is found
        tempCategory.splice(index, 1); // 2nd parameter means remove one item only
      }
      setCategoryInfo(tempCategory);
    }
    //props.filterOption(categoryinfo);
  };
  const starFilter = (e, i) => {
    props.ratingFilterOption(i);
    setSelectedRating(i);
    e.preventDefault();
  };
  // const clearRatingFilter = () => {
  //   props.ratingFilterOption(0);
  //   setSelectedRating(0);
  // };

  function categoryListTree() {


    // console.log({mainCategory});
    categoryListing();
    return listingData.map((category, i) => {
      let subcategoryList = listSubCategory(
        category.subCategories != undefined ? category.subCategories : []
      );
      return (
        <li>
          <div className="form-check">
            <Input
              onChange={handleChange}
              type="checkbox"
              id={category._id}
              name="category-radio"
              checked={categoryinfo.includes(category._id)}
            />
            <Label className="form-check-label" for={category._id}>
              {category.category_name}
            </Label>
            {subcategoryList}
          </div>
        </li>
      );
    });
  }

  function listSubCategory(child) {
    if (child != []) {
      return child.map((category, i) => {
        let subcategoryList = listSubCategory(
          category.subCategories != undefined ? category.subCategories : []
        );
        return (
          <ul className="pl-1">
            <li>
              <div className="form-check">
                <Input
                  type="checkbox"
                  id={category._id}
                  onChange={handleChange}
                  name="category-radio"
                  checked={categoryinfo.includes(category._id)}
                />
                <Label className="form-check-label" for={category._id}>
                  {category.category_name}
                </Label>
                {subcategoryList}
              </div>
            </li>
          </ul>
        );
      });
    }
  }

  return (
    <SidebarWrapper
      className="sidebar-detached sidebar-left"
      style={{ position: "sticky", top: "100px" }}
    >
      <div className="sidebar">
        <div
          className={classnames("sidebar-shop", {
            show: sidebarOpen,
          })}
        >
          <Row>
            <Col sm="12">
              <h6 className="filter-heading ">Filters</h6>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div id="product-categories">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <h6 className="filter-title mb-3">Categories</h6>
                  <Alert
                    color="warning"
                    className="mb-3 cursor-pointer"
                    style={{ padding: "5px 10px", lineHeight: "1", cursor: "pointer" }}
                  >
                    <X size="14" />{" "}
                    <span style={{ fontSize: "12px", fontWeight: "500" }}>Clear</span>
                  </Alert>
                </div>

                <CategoryTree className="list-unstyled categories-list">
                  {/* {categories.map((category) => {
                    return (
                      <li key={category.id}>
                        <div className="form-check">
                          <Input
                            type="radio"
                            id={category.id}
                            name="category-radio"
                            defaultChecked={category.defaultChecked}
                          />
                          <Label className="form-check-label" for={category.id}>
                            {category.title}
                          </Label>
                        </div>
                      </li>
                    );
                  })} */}
                  {categoryListTree()}
                </CategoryTree>
              </div>
              {/* <hr />
              <div className="brands">
                <h6 className="filter-title mb-3">Manufacturer</h6>
                <ul className="list-unstyled brand-list">
                  {brands.map((brand) => {
                    return (
                      <li
                        key={brand.title}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div className="form-check">
                          <Input type="checkbox" id={brand.title} defaultChecked={brand.checked} />
                          <Label className="form-check-label" for={brand.title}>
                            {brand.title}
                          </Label>
                        </div>
                        <span>{brand.total}</span>
                      </li>
                    );
                  })}
                </ul>
              </div> */}
              <hr />
              {/* <div id="ratings">
                <h6 className="filter-title mb-3">Ratings</h6>
                {ratings.map((item) => {
                  return (
                    <div
                      key={item.total}
                      className="ratings-list d-flex justify-content-between align-items-center"
                    >
                      <a href="/" onClick={(e) => e.preventDefault()} className="mr-1">
                        <ul
                          className="unstyled-list list-inline d-flex align-items-center mb-0"
                          style={{ gap: "2px" }}
                        >
                          {new Array(5).fill().map((listItem, index) => {
                            return (
                              <li key={index} className="ratings-list-item me-25 ">
                                <Star
                                  className={classnames({
                                    "filled-star text-warning": index + 1 <= item.ratings,
                                    "unfilled-star text-grey": index + 1 > item.ratings,
                                  })}
                                  size={"20px"}
                                />
                              </li>
                            );
                          })}
                          <li className="mb-2">& up</li>
                        </ul>
                      </a>
                      <div className="stars-received">{item.total}</div>
                    </div>
                  );
                })}
              </div> */}
              {/* <div id="ratings">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <h6 className="filter-title mb-3">Ratings</h6>
                  {selectedRating > 0 && (
                    <Alert
                      onClick={() => clearRatingFilter()}
                      color="warning"
                      className="mb-3 cursor-pointer"
                      style={{ padding: "5px 10px", lineHeight: "1", cursor: "pointer" }}
                    >
                      <X size="14" />{" "}
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>Clear</span>
                    </Alert>
                  )}
                </div>
                {ratings.map((item, i) => {
                  return (
                    <div
                      key={item.ratings}
                      className={
                        "ratings-list d-flex justify-content-between align-items-center " +
                        (selectedRating > 0 && selectedRating != item.ratings
                          ? "hideRating d-none"
                          : "")
                      }
                    >
                      <a href="#" onClick={(e) => starFilter(e, item.ratings)} className="mr-1">
                        <ul
                          className="unstyled-list list-inline d-flex align-items-center mb-0"
                          style={{ gap: "2px" }}
                        >
                          {new Array(5).fill().map((listItem, index) => {
                            return (
                              <>
                                <li key={index} className="ratings-list-item me-25 ">
                                  <Star
                                    className={classnames({
                                      "filled-star text-warning": index + 1 <= item.ratings,
                                      "unfilled-star text-grey": index + 1 > item.ratings,
                                    })}
                                    size={"20px"}
                                  />
                                </li>
                              </>
                            );
                          })}
                          <li className="mb-2">& up</li>
                        </ul>
                      </a>
                      <div className="stars-received">{rateChartList[item.ratings]}</div>
                    </div>
                  );
                })}
              </div> */}
              {/* <hr className="mt-3" /> */}
              <div id="clear-filters" className="mt-3">
                <Button color="primary" block size="sm" onClick={() => clearFilter()}>
                  Clear All Filters
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;

export const SidebarWrapper = styled.section`
  .hideRating.d-none {
    display: none !important;
  }

  .text-grey {
    color: silver;
  }

  label {
    font-size: 1rem;
  }
  hr {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }

  .filter-title {
    font-weight: 600;
    font-size: 16px;
  }

  .ratings-list {
    &:hover {
      svg {
        color: silver;
      }
    }
  }
`;

export const CategoryTree = styled.ul`
  li {
    text-transform: capitalize;
  }
  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;
