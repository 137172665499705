// ** Initial State
const initialState = {
  allData: [],
  allUsers: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  userProfile: null,
  userRegistration: null,
  userProfileAdminView:null,
  adminViewUser: null,
  password: null
  
}

const users = (state = initialState, action) => {
  switch (action.type) {
    
    case "UPDATE_USER_PASSWORD":
  return { ...state, password: action.data };

    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }

    case 'GET_ALL_DATA_ADMIN':
      return { ...state, 
        allUsers: action.data }

    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        allData: action.data
      }
   
    case 'ADD_DATA':
      return {
        ...state,
        allData: [...state.allData, action.data]
      }
      
    case 'UPDATE_DATA':
      return {
        ...state,
        userProfile: action.data
      }
      case 'UPDATE_DATA_ADMIN':
        return {
          ...state,
          userProfileAdminView: action.data
        }
    case 'DELETE_DATA':
      return { 
        ...state,
        allData: [...state.allData, action.data]
        // allData: state.map(allData => {
        //   if (allData.id === action.payload) {
        //     return { ...allData, complete: false };
        //   } 
        // })
     }
    case 'GET_USER':
      return { ...state, 
        selectedUser: action.selectedUser
       }
       case 'GET_USER_FROM_DB':
      return { ...state, 
        selectedUser: action.selectedUser
       }    

       case 'GET_DATA_ADMIN':
      return { ...state, 
        userProfileAdminView: action.data
       }

       
       case 'CURRENT_USER':
      return { ...state, 
        userProfile: action.data
       }
       case 'SET_CURRENT_USER':
      return { ...state, 
        userProfile: action.data
       }
       case 'CURRENT_USER_EMPTY':
      return { ...state, 
        userProfile: null
       }

       case 'CURRENT_REG_USER':
        return { ...state, 
          userRegistration: action.data
         }
         case 'SET_CURRENT_REG_USER':
        return { ...state, 
          userRegistration: action.data
         }
         case 'UPDATE_REG_USER':
        return { ...state, 
          userRegistration: action.data
         }
         
    default:
      return { ...state }
  }
}
export default users
