import React, { useState, useRef, useEffect } from "react";
import {
  PageWrapper,
  TitleWrapper,
} from "../../assets/styled-components/components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Input,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { Search, ChevronLeft, ChevronRight } from "react-feather";
import { listProductCategory } from "../../Store/Action/ProductAction.js";
import { useDispatch, useSelector } from "react-redux";
import placeholderImage from "../../assets/images/placeholder-image.jpg"

const ProductCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(listProductCategory());
  }, []);

  const pageSize = 6;
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);
  const mainCategory = useSelector((state) => state.products.category);

  useEffect(() => {
    setPageState(Math.ceil(mainCategory.length / pageSize));
  }, [mainCategory]);

  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setCurrentState(currentPage);
  };

  const routeExplore = (id) => {
    return history.push({
      pathname: "/product-list",
      search: "?categoryId=" + id,
      state: { fromPopup: true },
    });
  };

  return (
    <PageWrapper>
      <TitleWrapper className="pt-6 mb-6">
        <Container>
          <h3 className="text-white">All Categories</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Categories</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </TitleWrapper>
      <CategoryListWrapper className="pb-6">
        <Container>
          <h2 className="text-center mb-3">Product Categories</h2>
          {/* <SearchWrapper>
            <Input
              type="text"
              className="search"
              id="search"
              placeholder="Search"
              size={"lg"}
            />
            <Search size={"18px"} />
          </SearchWrapper> */}
        </Container>
        <Container>
          <Row>
            {mainCategory
              .slice(currentState * pageSize, (currentState + 1) * pageSize)
              .map((row, id) => (
                <Col lg="4" md="6">
                  <StyledCard className="mb-4">
                  <CardImg src={row.category_image ? row.category_image : placeholderImage} />
                    <CardBody>
                      <h4 style={{ fontWeight: "600" }}>
                        {row?.category_name} ({row?.product_count ? row?.product_count : 0})
                      </h4>
                      <CardText>
                       {row?.category_description}
                      </CardText>
                      <Button
                        onClick={() => routeExplore(row._id)}
                        color="primary" 
                        size="sm"
                        disabled={row?.product_count === 0}
                      >
                        Explore
                      </Button>
                    </CardBody>
                  </StyledCard>
                </Col>
              ))}
          </Row>

          {/* pagination */}
          <Pagination className="d-flex mt-3" size="md">
            <PaginationItem disabled={currentState <= 0}>
              <PaginationLink
                onClick={(e) => handlePagination(e, currentState - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            {[...Array(pageState)].map((page, i) => (
              <PaginationItem active={i === currentState} key={i}>
                <PaginationLink
                  onClick={(e) => handlePagination(e, i)}
                  href="#"
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentState >= pageState - 1}>
              <PaginationLink
                onClick={(e) => handlePagination(e, currentState + 1)}
                next
                href="#"
              />
            </PaginationItem>
          </Pagination>
        </Container>
      </CategoryListWrapper>
    </PageWrapper>
  );
};

export default ProductCategory;

export const CategoryListWrapper = styled.main`
  .pagination {
    margin-inline: auto;
    margin-bottom: 0;
    /* li {
      margin-bottom: 0;
      line-height: 1.2;
      transition: background 300ms ease;

      &:not(.active):hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    } */
  }
`;

export const StyledCard = styled(Card)`
  img {
    aspect-ratio: 16/9;
    object-fit: cover;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  max-width: 700px;
  margin-inline: auto;

  input {
    padding-right: 40px;
  }

  svg {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
