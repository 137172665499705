import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import HorizontalArticle from "../../../components/horizontal-article/HorizontalArticle";

// images
import articlePlaceholder from "../../../assets/images/comment-letters-placeholder.jpg";
import featuredImage from "../../../assets/images/comment-letters-featured.jpg";
import axios from "axios";
import parse from 'html-react-parser';

const ArticleCategory = () => {

  const baseURL = "https://mycoa.io/api";
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/page-template/list/can/`).then((response) => {
      setPosts(response.data.data);
    });
  }, []);

  return (
    <section className="article-category py-6 pb-7">
      <Container>
        <Row className="mb-6 align-items-center">
          <Col lg="6">
            <img src={featuredImage} alt="featured image" width="100%" />
          </Col>
          <Col lg="6" className="mt-4 mt-lg-0">
            <h1>Hospitals’ exploitation of safety programs must stop</h1>
            <p>
              COMMUNITY ONCOLOGY ALLIANCE Innovating and Advocating for
              Community Cancer Care On behalf of the Board of Directors of the
              Community Oncology Alliance (“COA”), we are submitting this
              response to the House of Representatives MACRA RFI seeking input
              on stabilizing the Medicare payment system.
            </p>
            <Button color="secondary">READ MORE</Button>
          </Col>
        </Row>
        <h2 className="text-center">Cannery</h2>
        <p
          className="text-center mb-6"
          style={{ maxWidth: "750px", marginLeft: "auto", marginRight: "auto" }}
        >
          On behalf of the Community Oncology Alliance (“COA”), we are
          submitting our concerns regarding the Enhancing Oncology Model (“EOM”)
          to the leadership at the Centers for Medicare & Medicaid Services
          (“CMS”) and Center for Medicare and Medicaid Innovation (“CMMI”).
        </p>
        <hr style={{ borderColor: "#173372" }} />
        <div className="articles mt-6">
        {posts && posts.map((post, index) => {
                return <HorizontalArticle
                target={`/post/${post.slug}`}
                image={articlePlaceholder}
                title={post.title}
                date={post.created_at}
                excerpt={parse(`${post.short_description}`)}
              />
            })}
          {/* <HorizontalArticle
            target="/post-1"
            image={articlePlaceholder}
            title="COA Comments to Congress on MACRA RFI"
            date="November 2022"
            excerpt="COMMUNITY ONCOLOGY ALLIANCE Innovating and Advocating for Community Cancer Care 1225 New York Ave. NW, Suite 600 Washington, DC 20005 (202) 729-8147 | communityoncology.org October 31, 2022 Submitted via email to: macra.rfi@mail.house.gov The Honorable Ami Bera The Honorable Larry Bucshon The Honorable Kim Schrier

"
          />
          <HorizontalArticle
            target="/post-1"
            image={articlePlaceholder}
            title="Letter to Defense Health Agency on TRICARE PBM Concerns"
            date="November 2022"
            excerpt="COMMUNITY ONCOLOGY ALLIANCE Innovating and Advocating for Community Cancer Care 1225 New York Ave. NW, Suite 600 Washington, DC 20005 (202) 729-8147 | communityoncology.org October 31, 2022 Submitted via email to: macra.rfi@mail.house.gov The Honorable Ami Bera The Honorable Larry Bucshon The Honorable Kim Schrier

"
          />
          <HorizontalArticle
            target="/post-1"
            image={articlePlaceholder}
            title="Senate Letter to FTC on Status of PBM Investigation"
            date="November 2022"
            excerpt="COMMUNITY ONCOLOGY ALLIANCE Innovating and Advocating for Community Cancer Care 1225 New York Ave. NW, Suite 600 Washington, DC 20005 (202) 729-8147 | communityoncology.org October 31, 2022 Submitted via email to: macra.rfi@mail.house.gov The Honorable Ami Bera The Honorable Larry Bucshon The Honorable Kim Schrier

"
          />
          <HorizontalArticle
            target="/post-1"
            image={articlePlaceholder}
            title="COA Formal Comments to CMS on Enhancing Oncology Model Concerns"
            date="November 2022"
            excerpt="COMMUNITY ONCOLOGY ALLIANCE Innovating and Advocating for Community Cancer Care 1225 New York Ave. NW, Suite 600 Washington, DC 20005 (202) 729-8147 | communityoncology.org October 31, 2022 Submitted via email to: macra.rfi@mail.house.gov The Honorable Ami Bera The Honorable Larry Bucshon The Honorable Kim Schrier

"
          /> */}
          <div className="text-center">
            <Button color="secondary">LOAD MORE</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ArticleCategory;
