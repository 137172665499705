// ** Third Party Components
import { useState, useEffect } from "react";
import wNumb from "wnumb";
import classnames from "classnames";
import { Star, X } from "react-feather";
import Nouislider from "nouislider-react";

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  CardHeader,
  CardFooter,
  Alert,
} from "reactstrap";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation-safe";

// ** Styles
// import "@styles/react/libs/noui-slider/noui-slider.scss";
import "nouislider/distribute/nouislider.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { specializationsList } from "../../Store/Action/JobAction.js";
import {
  getAllStateByCountry
} from "../../dev-config/global.js";
import Select from "react-select";

const Sidebar = (props) => {
  // ** Props
  const { sidebarOpen, rateChartList } = props;
  const dispatch = useDispatch();
  const [categories, setRows] = useState([]);
  const [categoryinfo, setCategoryInfo] = useState([]);
  const [selectedRating, setSelectedRating] = useState(0);

  const [stateOption, setStateOption] = useState(getAllStateByCountry("manipulate"));

  useEffect(() => {
    dispatch(specializationsList());
  }, []);

  const store = useSelector((state) => state.products);

  useEffect(() => {
    setRows(store?.category);
  }, []);

  useEffect(() => {
    props.filterOption(categoryinfo);
  }, [categoryinfo]);

  const specializations = useSelector((state) => state.jobs?.specializations);

  const clearFilter = () => {
    setCategoryInfo([]);
    props.ratingFilterOption(0);
    setSelectedRating(0);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCategoryInfo((categoryinfo) => [...categoryinfo, e.target.id]);
    } else {
      let tempCategory = [...categoryinfo];
      const index = tempCategory.indexOf(e.target.id);
      if (index > -1) {
        // only splice array when item is found
        tempCategory.splice(index, 1); // 2nd parameter means remove one item only
      }
      setCategoryInfo(tempCategory);
    }
  };

  const handleState = (e) => {
    let ids = e.map(function (i) {
    return i.value;
    });

    setCategoryInfo((categoryinfo) => [...categoryinfo, ids]);
  };

  
  const jobType = ['Full-Time','Part-Time','Internship','Temporary'];

  /*function categoryListTree() {
    return specializations.map((specialization, i) => {
      return (
        <li>
          <div className="form-check">
            <Input
              onChange={handleChange}
              type="checkbox"
              id={specialization._id}
              name="category-radio"
              checked={categoryinfo.includes(specialization._id)}
            />
            <Label className="form-check-label" for={specialization._id}>
              {specialization.specialization_name}
            </Label>
          </div>
        </li>
      );
    });
  }*/

  function categoryListTree() {

    return jobType.map((jobtypename, index)=> {
      return (
          <li>
            <div className="form-check">
              <Input
                onChange={handleChange}
                type="checkbox"
                id={jobtypename}
                name="category-radio"
                checked={categoryinfo.includes(jobtypename)}
              />
              <Label className="form-check-label" for={index}>
                {jobtypename}
              </Label>
            </div>
          </li>

        );
      });

    }

  return (
    <SidebarWrapper
      className="sidebar-detached sidebar-left"
      style={{ position: "sticky", top: "100px" }}
    >
      <div className="sidebar">
        <div
          className={classnames("sidebar-shop", {
            show: sidebarOpen,
          })}
        >
          <Row>
            <Col sm="12">
              <h6 className="filter-heading ">Filters</h6>
            </Col>
          </Row>
          <Card>
            <CardBody>
              
              <div id="product-categories">
              <h6 className="filter-title mb-3">Job Type</h6>
              <CategoryTree className="list-unstyled categories-list">
                {categoryListTree()}
              </CategoryTree>
              </div>

              <div id="product-categories">
              <h6 className="filter-title mb-3">Location</h6>
              <Select
              isMulti="true"
              className="react-select"
              classNamePrefix="select"
              options={stateOption}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isClearable={false}
              id="state"
              menuPlacement="top"
              placeholder="Select State"
              onChange={handleState}/>
              </div>

              <hr />
              <div id="clear-filters" className="mt-3">
                <Button
                  color="primary"
                  block
                  size="sm"
                  onClick={() => clearFilter()}
                >
                  Clear All Filters
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;

export const SidebarWrapper = styled.section`
  .hideRating.d-none {
    display: none !important;
  }

  .text-grey {
    color: silver;
  }

  label {
    font-size: 1rem;
  }
  hr {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }

  .filter-title {
    font-weight: 600;
    font-size: 16px;
  }

  .ratings-list {
    &:hover {
      svg {
        color: silver;
      }
    }
  }
`;

export const CategoryTree = styled.ul`
  li {
    text-transform: capitalize;
  }
  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;
