import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";

// import required modules
import { FreeMode, Navigation, Thumbs, Zoom } from "swiper";
import styled from "styled-components";
import { Minus, Plus } from "react-feather";
import { Button, ButtonGroup } from "reactstrap";
import { useRef } from "react";

export default function ProductSlider({ Images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);
  const swiper = useSwiper();
  return (
    <SwiperWrapper>
      <Swiper
        ref={swiperRef}
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        zoom={{
          maxRatio: 5,
          minRation: 1,
        }}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Zoom]}
        className="mySwiper2"
        zoomedSlideClass="swiper-slide-zoomed"
      >
        {Images.map((img) => {
          return (
            <SwiperSlide>
              <ZoomButtonContainer className="zoom-buttons" size="sm">
                <Button className="zoom-in" onClick={() => swiperRef.current.swiper.zoom.in()}>
                  <Plus size={16} color="#444" />
                </Button>{" "}
                <Button className="zoom-out" onClick={() => swiperRef.current.swiper.zoom.out()}>
                  <Minus size="16" color="#444" />
                </Button>
              </ZoomButtonContainer>
              <div className="swiper-zoom-container">
                <img src={img} alt="" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="product-images-slider-thumbs"
      >
        {Images.map((img) => {
          return (
            <SwiperSlide>
              <img src={img} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperWrapper>
  );
}

export const SwiperWrapper = styled.div`
  .swiper-slide {
    /* padding-top: 100%; */
    overflow: hidden;
    position: relative;
    border-radius: 0.25rem;
    aspect-ratio: 1/0.9;
    padding-top: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-button-prev {
    --swiper-navigation-size: 20px;
    color: rgb(255, 255, 255);
  }

  .swiper-button-next {
    --swiper-navigation-size: 20px;
    color: rgb(255, 255, 255);
  }

  .product-images-slider-thumbs {
    margin-top: 0.7rem;

    .swiper-slide {
      cursor: pointer;
      border: 1px solid #dddbdb;
      max-height: 100px;
      aspect-ratio: 1/1;
      padding-top: 0;

      &.swiper-slide-thumb-active {
        &::after {
          content: "";
          position: absolute;
          inset: 0;
          border: 2px solid #c5ceff;
        }
      }
    }

    &-wrapper {
      width: 100%;
      padding-top: 65%;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
`;

export const ZoomButtonContainer = styled(ButtonGroup)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;

  button {
    background-color: #f6f6f6;
    border-color: #f1f1f1;
    padding: 5px 10px !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #ddd !important;
      border-color: #f1f1f1 !important;
      box-shadow: unset !important;
    }
  }
`;
