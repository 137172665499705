import React, { useState, useEffect , useRef } from "react";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation-safe";
import ProductSlider from "./components/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { showJobDetails } from "../../Store/Action/JobAction.js";
import image from "../../assets/images/placeholder-img.svg";
import { Icon } from "@iconify/react";
import {
  DateConfig,
  valSwalSuccess,
  valSwalFailure,
  swalalertWithMsg,
} from "../../dev-config/global";
import { applyJob } from "../../Store/Action/JobAction.js";
let map;
const JobDetail = () => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const store = useSelector((state) => state.jobs);
  const googlePlace = useRef();

  let data = store.jobDetails ? store.jobDetails : null;

  const getSpecializationName = (row) => {
    if (row?.job_specializations.length > 0) {
      return row?.job_specializations
        .map(function (e) {
          return e.specialization_name;
        })
        .join(", ");
    }
  };
  const mapOptions = {
  zoom: 8,
  center: { lat: parseFloat(data?.lat), lng: parseFloat(data?.lng) },
  };

  useEffect(() => {
    if (id) {
      dispatch(showJobDetails(id));
    }

  }, [dispatch]);


  useEffect(() => {
    setTimeout(() => {
    map = new window.google.maps.Map(googlePlace.current, mapOptions);

    const marker = new window.google.maps.Marker({
    // The below line is equivalent to writing:
    // position: new google.maps.LatLng(-34.397, 150.644)
    position: { lat: parseFloat(data?.lat), lng: parseFloat(data?.lng) },
    map: map,
    });
     
    }, 1000);
  }, [store]);

  //Apply Scenario

  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [file, setFile] = useState(null);

  const toggle = () => setModal(!modal);

  const handleSubmit = async (e, errors, values) => {

    e.preventDefault();
    swalalertWithMsg(true, "please wait!");
    let payload = values;

    var formDataAppend = new FormData();
    for (var key in payload) {
      formDataAppend.append(key, payload[key] ? payload[key] : "");
    }
    formDataAppend.append("job_id", id);
    formDataAppend.append("resume", file);

    dispatch(applyJob(formDataAppend))
      .then((res) => {
        valSwalSuccess("Success", "Job Applied Successfully!");
      })
      .catch((err) => {
        valSwalFailure("Error", "Something went wrong please try again");
      });

    toggle();
  };

  return (
    <PageWrapper id="page-wrapper" className="pb-6">
      <TitleWrapper className="pt-6 mb-6" style={{ paddingBottom: "64px" }}>
        <Container>
          <div className="d-flex align-items-center justify-content-between flex-wrap ">
            <div className="mb-3">
              <div className="mb-2">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="my-jobs-list">Jobs</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Job Information</BreadcrumbItem>
                </Breadcrumb>
                <h3 className="text-white mb-0">
                  {data?.state} - {data?.title} opportunity - {data?.city}
                </h3>
              </div>
              <div className="meta d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                {data?.approved_date ? (
                  <MetaItem className="d-flex align-items-center">
                    <Icon icon="ph:calendar-blank-light" className="mr-1" />{" "}
                    {DateConfig(data?.approved_date)}
                  </MetaItem>
                ) : (
                  ""
                )}
                {/* {data?.experience ? ( */}
                {/* <MetaItem className="d-flex align-items-center">
                  <Icon icon="ph:briefcase-thin" className="mr-1" />{" "}
                  {data?.min_experience}-{data?.max_experience} Years
                </MetaItem> */}
                {/* ) : (
                  ""
                )} */}
                {/*{data?.salary_range ? (
                  <MetaItem className="d-flex align-items-center">
                    <Icon icon="ph:wallet" className="mr-1" />{" "}
                    {data?.salary_range}
                  </MetaItem>
                ) : (
                  ""
                )}*/}
                {data?.state ? (
                  <MetaItem className="d-flex align-items-center">
                    <Icon icon="ph:map-pin-light" className="mr-1" /> {data?.state}
                  </MetaItem>
                ) : (
                  ""
                )}
                {data?.employment_type ? (
                  <MetaItem className="d-flex align-items-center">
                    <Icon icon="ph:tag-simple-thin" className="mr-1" /> {data?.employment_type}
                  </MetaItem>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              {/* {data?.company_logo ? ( */}
              <CompanyLogo className="company-logo rounded-sm">
                <img src={data?.company_logo ? data?.company_logo : image} alt="logo" />
              </CompanyLogo>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
        </Container>
      </TitleWrapper>
      <Container>
        <Row>
          <Col lg="8" className="mb-3 mb-lg-0">
            <Card style={{ position: "sticky", top: "100px" }}>
              <CardBody>
                {data?.description ? (
                  <DescriptionWrapper>
                    <h3>Job Information</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.description,
                      }}
                    ></p>
                  </DescriptionWrapper>
                ) : (
                  ""
                )}
                {data?.company_description ? (
                  <DescriptionWrapper>
                    <h3 style={{ textTransform: "capitalize" }}>About {data?.company_name}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.company_description,
                      }}
                    ></p>
                  </DescriptionWrapper>
                ) : (
                  ""
                )}
                {/* <hr /> */}
                {/* <div
                  className="d-flex align-items-center justify-content-between flex-wrap"
                  style={{ gap: "15px" }}
                >
                  <div>
                    <small>Share:</small>
                    <ButtonGroup size="sm" className="d-block">
                      <Button color="light" className="p-1">
                        <Icon icon="ri:facebook-fill" fontSize={20} />
                      </Button>
                      <Button color="light" className="p-1">
                        <Icon icon="ri:twitter-fill" fontSize={20} />
                      </Button>
                      <Button color="light" className="p-1">
                        <Icon icon="ri:linkedin-fill" fontSize={20} />
                      </Button>
                      <Button color="light" className="p-1">
                        <Icon icon="ph:globe" fontSize={20} />
                      </Button>
                    </ButtonGroup>
                  </div>
                  <Button
                    color="danger"
                    size="sm"
                    style={{ fontSize: "1rem" }}
                    className="py-1 px-4 align-self-end"
                    onClick={toggle}
                  >
                    Apply
                  </Button>
                </div> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="mb-3">
              <CardBody>
                <Button
                  color="danger"
                  size="sm"
                  block
                  style={{ fontSize: "1.2rem" }}
                  onClick={toggle}
                >
                  Apply
                </Button>
              </CardBody>
            </Card>
            <Card className="mb-3">
              <CardBody>
                <h5 className="weight-600">Details</h5>
                <MetaWrapper className="meta-wrapper d-flex flex-wrap" style={{ gap: "10px" }}>
                  {data?.title ? (
                    <div className="meta-item">
                      <strong>Profession</strong> <small>{data?.title}</small>
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.employment_type ? (
                    <div className="meta-item">
                      <strong>Employment Type</strong> <small>{data?.employment_type}</small>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*{data?.job_specializations ? (
                    <div className="meta-item">
                      <strong>Specialty</strong> <small>{getSpecializationName(data)}</small>
                    </div>
                  ) : (
                    ""
                  )}*/}
                  {/* {data?.salary_range ? (
                    <div className="meta-item">
                      <strong>Bonuses Offered</strong> <small>Sign-on</small>
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.salary_range ? (
                    <div className="meta-item">
                      <strong>Loan Repayment</strong>{" "}
                      <small>Not Specified</small>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {data?.salary_range ? (
                    <div className="meta-item">
                      <strong>Salary Range</strong> <small>{data?.salary_range}</small>
                    </div>
                  ) : (
                    ""
                  )}
                </MetaWrapper>
              </CardBody>
            </Card>
            <Card className="mb-3">
              <CardBody>
                <h5 className="weight-600">Address</h5>
                {data?.company_name ? <p className="mb-0">{data?.company_name}</p> : ""}
                {data?.address1 || data?.address2 ? (
                  <p className="mb-0">
                    {data?.address1}, {data?.address2}{" "}
                  </p>
                ) : (
                  ""
                )}
                {data?.state || data?.zip_code || data?.city  ? (
                  <p className="mb-0">
                    {data?.city} {data?.state} {data?.zip_code}
                  </p>
                ) : (
                  ""
                )}

                <div ref={googlePlace}  style={{ height:370 }}>
                </div>

                 {/*<iframe
                  src="https://maps.google.com/maps?q={data?.lat},{data?.lng}&hl=es&z=14&amp;output=embed"
                  //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.0188558161362!2d-112.07974568722814!3d33.448815273279244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b1223fc277d09%3A0x751804c733867934!2s200%20W%20Washington%20St%2C%20Phoenix%2C%20AZ%2085003%2C%20USA!5e0!3m2!1sen!2sin!4v1698407512332!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: 0 }}
                  className="mt-3"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>*/}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="weight-600">Contact Information</h5>
                <p className="mb-0">
                  <strong>{data?.contact_person_name}</strong>
                </p>
                <small className="mb-1 d-inline-block">Recruiter</small>
                <a href="tel:123123123" className="d-flex align-items-center">
                  <Icon icon="ph:phone-outgoing" className="mr-1" />
                  <span> {data?.phone_number}</span>
                </a>
                <a href="mailto:johndeo@gmail.com" className="d-flex align-items-center">
                  <Icon icon="ph:envelope-simple" className="mr-1" />
                  <span>{data?.email}</span>
                </a>
                {/* <SocialMedia className="mt-1">
                  <a href="#" className="pr-1 py-1 px-0" id="facebook">
                    <Icon
                      icon="devicon:facebook"
                      className="mr-1"
                      fontSize={20}
                    />
                  </a>

                  <a href="#" className="p-1" id="twitter">
                    <Icon
                      icon="devicon:twitter"
                      className="mr-1"
                      fontSize={17}
                    />
                  </a>
                  <a href="#" className="p-1" id="linkedin">
                    <Icon
                      icon="devicon:linkedin"
                      className="mr-1"
                      fontSize={20}
                    />
                  </a>
                  <a href="#" className="p-1" id="website">
                    <Icon
                      icon="ph:globe-hemisphere-west-fill"
                      className="mr-1"
                      fontSize={22}
                    />
                  </a>

                  <UncontrolledTooltip placement="bottom" target="facebook">
                    Facebook
                  </UncontrolledTooltip>

                  <UncontrolledTooltip placement="bottom" target="twitter">
                    Twitter
                  </UncontrolledTooltip>

                  <UncontrolledTooltip placement="bottom" target="linkedin">
                    Linkedin
                  </UncontrolledTooltip>

                  <UncontrolledTooltip placement="bottom" target="website">
                    Website
                  </UncontrolledTooltip>
                </SocialMedia> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Submit Your Information</ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <AvInput
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <AvInput
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="contact">Contact Number</Label>
                <AvInput
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  placeholder="Enter your contact number"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="file">Upload Resume</Label>
                <AvInput
                  type="file"
                  id="file"
                  name="file"
                  accept=".pdf"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </FormGroup>
              <Button
                type="submit"
                color="primary"
                size="sm"
                style={{ fontSize: "1rem" }}
                className="py-1 px-4 align-self-end"
              >
                Submit
              </Button>
              <Button
                color="secondary"
                size="sm"
                style={{ fontSize: "1rem" }}
                className="py-1 px-4 align-self-end"
                onClick={toggle}
              >
                Cancel
              </Button>
            </AvForm>
          </ModalBody>
        </Modal>
      </Container>
    </PageWrapper>
  );
};

export default JobDetail;

export const DescriptionWrapper = styled.div`
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: 700;
  }
  h6 {
    font-size: 1rem;
  }
  & div > :last-child {
    margin-bottom: 0;
  }
`;

export const MetaItem = styled.div`
  font-size: 14px;
  padding: 2px 10px;
  background: #ffffff12;
  border-radius: 2px;
`;

export const MetaWrapper = styled.div`
  strong {
    font-weight: 500;
  }
  .meta-item {
    display: flex;
    align-items: center;
    gap: 15px;

    width: 100%;

    strong {
      flex: 0 0 50%;
    }
    span {
      flex: 0 0 50%;
    }
  }
`;

export const SocialMedia = styled.div``;

export const CompanyLogo = styled.div`
  background-color: #fff;

  img {
    aspect-ratio: 16/9;
    max-height: 150px;
    object-fit: cover;
    max-width: 250px;
    overflow: hidden;
    position: relative;
  }
`;
