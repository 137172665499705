import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Card, CardBody, Input, InputGroup, InputGroupAddon } from "reactstrap";
import styled from "styled-components";
import withReactContent from "sweetalert2-react-content";

let map;

const LocationAutoComplete = ({ setValues, indexValue }) => {
  //const LocationAutoComplete = () => {
  const googlePlaceAutoAcompleteRef = useRef();

  // const [hide,setHide] = useState(false)

  const input = document.getElementById("autocomplete");
  let options = {};

  const autoCompleteRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        googlePlaceAutoAcompleteRef.current,
        {
          // fields: ["address_components", "geometry", "icon", "name"]
          fields: ["address_components", "geometry"],
          types: ["address"],
        }
      );

      autoCompleteRef.current.addListener("place_changed", async () => {
        const places = await autoCompleteRef.current.getPlace();
        console.log({ places });
        const desiredData = places.address_components.reduce((a, c) => {
          a[c.types[0]] = c.long_name;

          return a;
        }, {});

        desiredData.lat = places.geometry.location.lat();
        desiredData.lng = places.geometry.location.lng();

        desiredData.index = indexValue;
        // setHide(true)
        setValues(desiredData);
      });
    }, 1000);
  }, [window.google.maps.places]);

  return (
    <>
      <div>
        <input ref={googlePlaceAutoAcompleteRef} className="form-control" />
      </div>
    </>
  );
};

export default LocationAutoComplete;
