import React, { useState } from "react";
import { Col, Container, Progress, Row } from "reactstrap";
import styled from "styled-components";
import { Rating } from "react-simple-star-rating";
import rating1 from "../assets/images/rating1.svg";
const RatingSingle = () => {
  const [rating, setRating] = useState(3);

  return (
    <div className="page-wrapper my-6">
      <Container>
        <Row>
          <Col lg="9">
            <PostItem>
              <div className="post-image">
                <img src={rating1} alt="image" />
              </div>
              <div className="post-meta">
                <div className="title text-primary">COA CAN Insights</div>
                <div className="ratings d-flex align-items-center mb-2">
                  <Rating initialValue={rating} size="22" className="mr-2" />{" "}
                  <span style={{ color: "#999" }}>(662)</span>
                </div>
                <div className="desc">
                  COA is a recognized leader and staunch supporter of
                  value-based care initiatives, which we believe can lower
                  costs.
                </div>
                <div className="category">Nebraska Oncology Specialists</div>
              </div>
            </PostItem>

            <h4 className="text-primary mt-5" style={{ fontWeight: "600" }}>
              Description
            </h4>
            <p>
              So how did the classical Latin become so incoherent? According to
              McClintock, a 15th century typesetter likely scrambled part of
              Cicero's De Finibus in order to provide placeholder text to mockup
              various fonts for a type specimen book.
            </p>
            <p>
              It's difficult to find examples of lorem ipsum in use before
              Letraset made it popular as a dummy text in the 1960s, although
              McClintock says he remembers coming across the lorem ipsum passage
              in a book of old metal type samples. So far he hasn't relocated
              where he once saw the passage, but the popularity of Cicero in the
              15th century supports the theory that the filler text has been
              used for centuries.
            </p>

            <ReviewList>
              <h4 className="text-primary mt-5" style={{ fontWeight: "600" }}>
                Reviews
              </h4>

              <div className="review-container">
                <div className="review-item">
                  <div className="name d-flex align-items-center">
                    <h5 style={{ fontWeight: "500" }} className="mb-1 mr-3">
                      Paul Meredith
                    </h5>
                    <Rating
                      initialValue={rating}
                      readonly="true"
                      size="22"
                      className="mr-2 mb-2"
                    />{" "}
                  </div>
                  <p>
                    So how did the classical Latin become so incoherent?
                    According to McClintock, a 15th century typesetter likely
                    scrambled part of Cicero's De Finibus in order to provide
                    placeholder text to mockup various fonts for a type specimen
                    book. It's difficult to find examples of lorem ipsum in use
                    before Letraset made it popular as a dummy text in the
                    1960s, although McClintock says he remembers coming across
                    the lorem ipsum passage.
                  </p>
                </div>

                <div className="review-item">
                  <div className="name d-flex align-items-center">
                    <h5 style={{ fontWeight: "500" }} className="mb-1 mr-3">
                      Matt Chandly
                    </h5>
                    <Rating
                      initialValue={rating}
                      readonly="true"
                      size="22"
                      className="mr-2 mb-2"
                    />{" "}
                  </div>
                  <p>
                    So how did the classical Latin become so incoherent?
                    According to McClintock, a 15th century typesetter likely
                    scrambled part of Cicero's De Finibus in order to provide
                    placeholder text to mockup various fonts for a type specimen
                    book. It's difficult to find examples of lorem ipsum in use
                    before Letraset made it popular as a dummy text in the
                    1960s, although McClintock says he remembers coming across
                    the lorem ipsum passage.
                  </p>
                </div>

                <div className="review-item">
                  <div className="name d-flex align-items-center">
                    <h5 style={{ fontWeight: "500" }} className="mb-1 mr-3">
                      Jill Rosenberg
                    </h5>
                    <Rating
                      initialValue={rating}
                      readonly="true"
                      size="22"
                      className="mr-2 mb-2"
                    />{" "}
                  </div>
                  <p>
                    So how did the classical Latin become so incoherent?
                    According to McClintock, a 15th century typesetter likely
                    scrambled part of Cicero's De Finibus in order to provide
                    placeholder text to mockup various fonts for a type specimen
                    book. It's difficult to find examples of lorem ipsum in use
                    before Letraset made it popular as a dummy text in the
                    1960s, although McClintock says he remembers coming across
                    the lorem ipsum passage.
                  </p>
                </div>
              </div>
            </ReviewList>
          </Col>
          <Col lg="3" className="mt-4 mt-lg-0">
            <h4 className="text-primary" style={{ fontWeight: "600" }}>
              Ratings
            </h4>
            <div className="ratings d-flex align-items-center mb-2">
              <Rating
                initialValue={rating}
                readonly="true"
                size="22"
                className="mr-2"
              />{" "}
            </div>
            <RatingDetails className="rating-detail">
              <div className="item">
                <span>5 Stars</span>
                <Progress className="progress-bar-warning " value={65} />
                <span className="count">431</span>
              </div>

              <div className="item">
                <span>4 Stars</span>
                <Progress className="progress-bar-warning" value={30} />
                <span className="count">75</span>
              </div>

              <div className="item">
                <span>3 Stars</span>
                <Progress className="progress-bar-warning" value={18} />
                <span className="count">40</span>
              </div>

              <div className="item">
                <span>2 Stars</span>
                <Progress className="progress-bar-warning" value={15} />
                <span className="count">22</span>
              </div>

              <div className="item">
                <span>1 Stars</span>
                <Progress className="progress-bar-warning" value={60} />
                <span className="count">97</span>
              </div>
            </RatingDetails>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RatingSingle;

export const PostItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;

  .post-image {
    flex: 0 1 150px;
    border-radius: 13px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .post-meta {
    flex: 1 1;

    .title {
      display: block;
      font-size: 28px;
      font-weight: 600;
      font-family: "Montserrat";
    }

    .category {
      color: #999;
      margin-top: 7px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    .post-image {
      flex-basis: 100%;
      text-align: center;
      width: 100%;
    }
  }
`;

export const RatingDetails = styled.div`
  .item {
    display: flex;
    align-items: center;
    gap: 15px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .progress {
      flex: 1 1;

      .progress-bar {
        background-color: var(--warning);
      }
    }
  }
`;

export const ReviewList = styled.section`
  .review-container {
    .review-item {
      &:first-child {
        margin-top: 15px;
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      h5 {
        font-size: 19px;
      }
    }
  }
`;
